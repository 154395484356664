/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TableOfContentsPropertiesModel
 */
export interface TableOfContentsPropertiesModel {
    /**
     * 
     * @type {boolean}
     * @memberof TableOfContentsPropertiesModel
     */
    inkluderH1?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TableOfContentsPropertiesModel
     */
    inkluderH2?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TableOfContentsPropertiesModel
     */
    inkluderH3?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TableOfContentsPropertiesModel
     */
    inkluderH4?: boolean | null;
}

export function TableOfContentsPropertiesModelFromJSON(json: any): TableOfContentsPropertiesModel {
    return TableOfContentsPropertiesModelFromJSONTyped(json, false);
}

export function TableOfContentsPropertiesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TableOfContentsPropertiesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inkluderH1': !exists(json, 'inkluderH1') ? undefined : json['inkluderH1'],
        'inkluderH2': !exists(json, 'inkluderH2') ? undefined : json['inkluderH2'],
        'inkluderH3': !exists(json, 'inkluderH3') ? undefined : json['inkluderH3'],
        'inkluderH4': !exists(json, 'inkluderH4') ? undefined : json['inkluderH4'],
    };
}

export function TableOfContentsPropertiesModelToJSON(value?: TableOfContentsPropertiesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inkluderH1': value.inkluderH1,
        'inkluderH2': value.inkluderH2,
        'inkluderH3': value.inkluderH3,
        'inkluderH4': value.inkluderH4,
    };
}

