import { useSearchForm } from '@/shared/hooks/search-form-field-hook';
import { SearchPageAttribute } from '@/shared/lib-api';
import { Colors } from '@/shared/util/colors';
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropDownStyle } from '../default-components/input/input-icons/dropdownIcon';
import { DropDownArrow } from '../default-components/input/input-icons/dropdownSortIcon';
import { sortValues } from '@/shared/store/reducers/SRPSlice';

const selectStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};

const selectInputStyle: React.CSSProperties = {
  fontSize: 15,
  color: Colors.Secondary,
  lineHeight: '22px',
};

function SortSrp() {
  var { field, onChange } = useSearchForm<SearchPageAttribute>('sort');
  var { field: fuelTypes } = useSearchForm<SearchPageAttribute[]>('fuelTypes');
  const shouldShowElOptions = React.useMemo(() => {
    return fuelTypes.some(
      p => p.value === 'el' || p.value === 'benzinel' || p.value === 'dieselel',
    );
  }, [fuelTypes]);
  const { t } = useTranslation();
  const selectItems = React.useMemo(() => {
    return sortValues
      ?.filter((p, i) => shouldShowElOptions || i < sortValues.length - 4)
      .map((opt, index) => {
        return (
          <MenuItem key={index} value={opt.value}>
            {t(opt.label)}
          </MenuItem>
        );
      });
  }, [t, shouldShowElOptions]);

  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onValueChanged = React.useCallback(
    (event: SelectChangeEvent<string>) => {
      onChange(sortValues.find(p => p.value === event.target.value));
    },
    [onChange],
  );

  const iconComponent = useMemo(() => {
    return (
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <IconButton style={DropDownStyle(open)} onClick={handleOpen}>
          {DropDownArrow}
        </IconButton>
      </Box>
    );
  }, [handleOpen, open]);

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography fontWeight={500} color={Colors.Primary}>
        {t('SRP.SortAfter')}
      </Typography>
      <Box>
        <Select
          color="secondary"
          style={selectStyle}
          SelectDisplayProps={{ style: selectInputStyle }}
          MenuProps={{
            sx: {
              maxHeight: {
                sm: 'unset',
              },
            },
          }}
          displayEmpty
          fullWidth
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          IconComponent={() => {
            return iconComponent;
          }}
          renderValue={selectedValue => {
            return sortValues.find(p => p.value === selectedValue)?.label;
          }}
          value={field.value}
          onChange={onValueChanged}>
          {selectItems}
        </Select>
      </Box>
    </Box>
  );
}

export default React.memo(SortSrp);
