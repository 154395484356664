/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IApiMediaWithCropsResponseModel,
    IApiMediaWithCropsResponseModelFromJSON,
    IApiMediaWithCropsResponseModelFromJSONTyped,
    IApiMediaWithCropsResponseModelToJSON,
} from './IApiMediaWithCropsResponseModel';

/**
 * 
 * @export
 * @interface PagedIApiMediaWithCropsResponseModel
 */
export interface PagedIApiMediaWithCropsResponseModel {
    /**
     * 
     * @type {number}
     * @memberof PagedIApiMediaWithCropsResponseModel
     */
    total: number;
    /**
     * 
     * @type {Array<IApiMediaWithCropsResponseModel>}
     * @memberof PagedIApiMediaWithCropsResponseModel
     */
    items: Array<IApiMediaWithCropsResponseModel>;
}

export function PagedIApiMediaWithCropsResponseModelFromJSON(json: any): PagedIApiMediaWithCropsResponseModel {
    return PagedIApiMediaWithCropsResponseModelFromJSONTyped(json, false);
}

export function PagedIApiMediaWithCropsResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedIApiMediaWithCropsResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'items': ((json['items'] as Array<any>).map(IApiMediaWithCropsResponseModelFromJSON)),
    };
}

export function PagedIApiMediaWithCropsResponseModelToJSON(value?: PagedIApiMediaWithCropsResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'items': ((value.items as Array<any>).map(IApiMediaWithCropsResponseModelToJSON)),
    };
}

