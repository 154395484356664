import { Colors } from '@/shared/util/colors';
import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PrimaryClock from '@/shared/icons/clock-primary.svg';
import Image from '@/web/views/components/image/image';
import { useLoginOrCreatedEvent } from '@/shared/hooks/login-hook';
import { useAppDispatch, useAppSelector } from '@/shared/store/hooks';
import { LoadingState } from '@/shared/util/general-helpers';
import { useRequestHelper } from '@/shared/hooks/request-helper';
import { useApi } from '@/shared/util/api';
import { toast } from 'react-toastify';
import { BasicCarCardType } from '@/shared/hooks/basic-car-hook';
import { SetSrpNoResultsSearchData } from '@/shared/store/reducers/SRPSlice';
import { SRPGalleryModeResults } from './srp-gallery-mode-results';
import { SRPListModeResults } from './srp-list-mode-results';
import { PadBox } from '../containers/pad-box';
import { useToggle } from '@/shared/hooks/common/useToggle';
import { useSearchAgent } from '@/shared/hooks/search-agent-hook';
import { useScreenSizeContext } from '@/pages/_app';

export const NoResultsSrp: React.FC<{
  origin: BasicCarCardType;
}> = ({ origin }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { searchApi } = useApi();
  const { handleRequest } = useRequestHelper();
  const { add } = useSearchAgent();
  const { isMobile } = useScreenSizeContext();

  const searchOptions = useAppSelector(state => state.srpReducer.searchOptions);
  const noResultsData = useAppSelector(
    state => state.srpReducer.noResultsSearchData,
  );

  const [setShouldLoad, shouldLoadRef] = useToggle(true);

  const findSimilarCars = useCallback(async () => {
    let request = searchApi.apiSearchSearchsimilarPost({
      searchPageOptions: {
        ...searchOptions,
        pageNumber: 1,
        pageSize: 6,
      },
    });
    let [data, errorAndState] = await handleRequest(request);
    if (errorAndState.state !== LoadingState.Success) {
      return;
    }
    dispatch(SetSrpNoResultsSearchData(data));
  }, [handleRequest, searchApi, searchOptions, dispatch]);

  useEffect(() => {
    if (shouldLoadRef.current) {
      findSimilarCars();
      setShouldLoad(false);
    }
  }, [findSimilarCars, setShouldLoad, shouldLoadRef]);

  const handleSaveSearchAgent = useCallback(
    () =>
      toast.success(
        <Trans
          i18nKey="SRP.SavedSearchNotificationSaved"
          components={{
            u: <u />,
            a: <a style={{ color: '#1066B6', textDecoration: 'underline' }} />,
          }}
        />,
        {
          autoClose: 5000,
        },
      ),
    [],
  );

  const saveSearchAgent = useCallback(async () => {
    add(searchOptions).then(() => {
      handleSaveSearchAgent();
    });
  }, [searchOptions, handleSaveSearchAgent, add]);

  const { loginOrCreate } = useLoginOrCreatedEvent(saveSearchAgent);
  return (
    <>
      <Grid marginBottom={{ xs: '12px', sm: 4 }} item xs={12}>
        <PadBox marginTop={0}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}>
            {!isMobile && (
              <>
                <Box
                  position={'relative'}
                  height={0}
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'flex-end'}
                  width={'100%'}>
                  <Box right={16} top={16} position={'absolute'}>
                    <Image
                      src={PrimaryClock.src}
                      width={73}
                      height={50}
                      alt={t('SRP.NoResults.ButtonText')}
                    />
                  </Box>
                </Box>
                <Typography variant="h5">{t('SRP.NoResults.Title')}</Typography>
              </>
            )}

            {isMobile && (
              <Box display="flex" alignItems={'center'}>
                <Box display="flex">
                  <Typography variant="h5">
                    {t('SRP.NoResults.Title')}
                  </Typography>
                </Box>
                <Box display="flex">
                  <Image
                    src={PrimaryClock.src}
                    width={73}
                    height={70}
                    alt={t('SRP.NoResults.ButtonText')}
                  />
                </Box>
              </Box>
            )}

            <Typography paddingY={2} textAlign={'center'} maxWidth={380}>
              {t('SRP.NoResults.Body')}
            </Typography>
            <Box width={280}>
              <Button
                onClick={loginOrCreate}
                color={'secondary'}
                fullWidth={true}>
                {t('SRP.NoResults.ButtonText')}
              </Button>
            </Box>
          </Box>
        </PadBox>
      </Grid>
      <Grid item xs>
        <Typography
          marginLeft={2.5}
          marginBottom={2}
          color={Colors.DefaultFontColor}
          variant="h5">
          Andre biler der kunne interessere dig
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {origin === BasicCarCardType.SRPGallery && (
          <SRPGalleryModeResults items={noResultsData?.items} />
        )}
        {origin === BasicCarCardType.SRPList && (
          <SRPListModeResults items={noResultsData?.items} />
        )}
      </Grid>
    </>
  );
};
