/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IApiContentStartItemModel,
    IApiContentStartItemModelFromJSON,
    IApiContentStartItemModelFromJSONTyped,
    IApiContentStartItemModelToJSON,
} from './IApiContentStartItemModel';

/**
 * 
 * @export
 * @interface IApiContentRouteModel
 */
export interface IApiContentRouteModel {
    /**
     * 
     * @type {string}
     * @memberof IApiContentRouteModel
     */
    readonly path?: string;
    /**
     * 
     * @type {IApiContentStartItemModel}
     * @memberof IApiContentRouteModel
     */
    startItem?: IApiContentStartItemModel;
}

export function IApiContentRouteModelFromJSON(json: any): IApiContentRouteModel {
    return IApiContentRouteModelFromJSONTyped(json, false);
}

export function IApiContentRouteModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IApiContentRouteModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'path': !exists(json, 'path') ? undefined : json['path'],
        'startItem': !exists(json, 'startItem') ? undefined : IApiContentStartItemModelFromJSON(json['startItem']),
    };
}

export function IApiContentRouteModelToJSON(value?: IApiContentRouteModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startItem': IApiContentStartItemModelToJSON(value.startItem),
    };
}

