import React from 'react';
import { Box, Typography } from '@mui/material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { Trans } from 'react-i18next';
import { cursorPointer } from '@/shared/util/helpers/general-helper';

export const EmailIcon = (props: {
  loading: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}) => {
  return (
    <Box
      display={'flex'}
      alignItems="center"
      onClick={props.onClick}
      flexWrap={'nowrap'}>
      <AlternateEmailIcon
        fontSize="small"
        color={'secondary'}
        sx={cursorPointer}
      />
      <Typography
        paddingLeft={0.2}
        variant={props.loading ? 'body1' : 'clickAble'}
        whiteSpace={'nowrap'}
        textOverflow={'ellipsis'}
        overflow={'hidden'}>
        <Trans i18nKey={'Labels.WriteToSeller'} />
      </Typography>
    </Box>
  );
};
