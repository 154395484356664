/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AiseopagePropertiesModel,
    AiseopagePropertiesModelFromJSON,
    AiseopagePropertiesModelFromJSONTyped,
    AiseopagePropertiesModelToJSON,
} from './AiseopagePropertiesModel';
import {
    IApiContentModel,
    IApiContentModelFromJSON,
    IApiContentModelFromJSONTyped,
    IApiContentModelToJSON,
} from './IApiContentModel';

/**
 * 
 * @export
 * @interface AiseopageContentModel
 */
export interface AiseopageContentModel extends IApiContentModel {
    /**
     * 
     * @type {AiseopagePropertiesModel}
     * @memberof AiseopageContentModel
     */
    properties?: AiseopagePropertiesModel;
}

export function AiseopageContentModelFromJSON(json: any): AiseopageContentModel {
    return AiseopageContentModelFromJSONTyped(json, false);
}

export function AiseopageContentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AiseopageContentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...IApiContentModelFromJSONTyped(json, ignoreDiscriminator),
        'properties': !exists(json, 'properties') ? undefined : AiseopagePropertiesModelFromJSON(json['properties']),
    };
}

export function AiseopageContentModelToJSON(value?: AiseopageContentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...IApiContentModelToJSON(value),
        'properties': AiseopagePropertiesModelToJSON(value.properties),
    };
}

