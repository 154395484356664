import React, { FC, useEffect, useState } from 'react';
import logo from './logo.svg';
import top from './top.svg';
import { Box, Button, Grid, Typography } from '@mui/material';
import { OpenExternalLink } from '@/web/util/helpers/open-external-link';
import Image from '@/web/views/components/image/image';
import { Colors } from '@/shared/util/colors';
import { TrackEvent } from '@/shared/util/gtm';
import { useIntersectionObserver } from '@uidotdev/usehooks';

const sosRed = '#D94445';
const sosYellow = '#FBA204';

const styles = {
  '&.MuiButton-root': {
    backgroundColor: sosRed,
    color: '#FFF',
    fontWeight: 700,
  },
};

const link =
  new Date() > new Date(2024, 5, 17)
    ? 'https://www.dah.dk/vejhjaelp/personbil/plus/?utm_source=bilhandel&utm_medium=integration'
    : 'https://www.dah.dk/vejhjaelp/personbil/super-under-3500kg/?utm_source=bilhandel&utm_medium=integration';

const headline =
  new Date() > new Date(2024, 5, 17)
    ? 'Spar 25% på vejhjælp i hele Europa'
    : 'Spar 40% på vejhjælp i hele Europa med gratis lejebil';

const price = new Date() > new Date(2024, 5, 17) ? 62 : 67;

const bullet =
  new Date() > new Date(2024, 5, 17)
    ? [
        'Gns. fremme på 41 min',
        'Frit værkstedsvalg',
        'Vejhjælp på stedet',
        'Billeje fra 250,- pr døgn',
        'Fri vidererejse',
        'Køretøjer op til 2.500 kg ',
        'SOS Førstehjælpskasse',
        'Europadækning',
      ]
    : [
        'Gns. fremme på 41 min',
        'Frit værkstedsvalg',
        'Vejhjælp på stedet',
        'Gratis lejebil i 3 døgn',
        'Fri vidererejse',
        'Køretøjer op til 3.500 kg ',
        'Bugsering mellem værksteder i DK',
        'Europadækning inkl. bjærgning',
      ];

const VipCard: FC = () => {
  const [ref, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: '0px',
  });
  const [hasBeenInView, setHasBeenInView] = useState<boolean>(false);

  const handleOnClick = () => {
    TrackEvent('dah_klikket');
    OpenExternalLink(link);
  };

  useEffect(() => {
    if (entry?.isIntersecting && hasBeenInView === false) {
      setHasBeenInView(true);
      TrackEvent('dah_vist');
    }
  }, [entry?.isIntersecting, hasBeenInView]);

  return (
    <>
      <Box
        id="sos-vip-card"
        position={'relative'}
        bgcolor={Colors.White}
        borderRadius={'10px'}
        padding={'28px'}
        height={'100%'}>
        <Box position={'absolute'} top={0} right={5}>
          <Image alt="Top" src={top.src} height={24} width={260} />
        </Box>
        <Box
          display="flex"
          flexDirection={'column'}
          height="100%"
          justifyContent={'space-between'}>
          <Box>
            <Typography fontWeight={700} fontSize={20} lineHeight={'28.8px'}>
              {headline}
            </Typography>
          </Box>
          <Box marginTop={'16px'}>
            <Box fontWeight={400} fontSize={12} lineHeight={'26.34px'}>
              <Grid container>
                {bullet.map((b, index) => {
                  return (
                    <Grid item xs={12} sm={6} key={index}>
                      <Box display="flex" alignItems="baseline">
                        <Box
                          color={Colors.White}
                          height={11}
                          width={12}
                          position="relative"
                          display="inline-flex"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius={'50%'}
                          style={{ backgroundColor: sosYellow }}>
                          <Box
                            position="absolute"
                            height={'1px'}
                            width={'5px'}
                            top={'5px'}
                            left={'3px'}
                            bgcolor={Colors.White}
                          />
                          <Box
                            position="absolute"
                            height={'5px'}
                            width={'1px'}
                            top={'3px'}
                            left={'5px'}
                            bgcolor={Colors.White}
                          />
                        </Box>
                        <Box width={'100%'} marginLeft={1}>{`${b}`}</Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Box>
          <Typography
            marginTop={'12px'}
            fontWeight={700}
            fontSize={15}
            lineHeight={'19.65px'}>
            kun <span style={{ fontSize: 27 }}>{price}</span> kr. / md.
          </Typography>

          <Box
            display={'flex'}
            justifyContent={'center'}
            marginTop={'18px'}
            ref={ref}>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              maxWidth={'400px'}
              width={'100%'}>
              <Box>
                <Button sx={styles} onClick={handleOnClick}>
                  Bestil her
                </Button>
              </Box>
              <Box>
                <Image width={156} height={43} src={logo.src} alt={'SOS'} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VipCard;
