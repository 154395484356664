import { BasicCarCardType } from '@/shared/hooks/basic-car-hook';
import { ClassifiedItem } from '@/shared/lib-api';
import { Grid } from '@mui/material';
import { default as React, useDeferredValue } from 'react';
import { BasicCarGalleryCard } from '../cards/basic-car-card/basic-car-gallery-card';

export const SRPGalleryModeResults: React.FC<{
  items: ClassifiedItem[];
}> = ({ items }) => {
  const itemsTouse = useDeferredValue(items);
  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      {itemsTouse !== undefined &&
        itemsTouse.map(item => {
          return (
            <BasicCarGalleryCard
              origin={BasicCarCardType.SRPGallery}
              key={item.id}
              item={item}
              exclude-owner={false}
            />
          );
        })}
    </Grid>
  );
};
