import { useScreenSizeContext } from '@/pages/_app';
import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { PadBox } from '../containers/pad-box';
import { WriteDealerFormModal } from '../default-components/write-dealer-form-modal/write-dealer-form-modal';
import { VipHeadline } from './headline';
import { useVipContext } from '@/pages/[headline]/[[...id]]';

export const CardWriteToDealer = () => {
  const { item } = useVipContext();
  const { isMobile } = useScreenSizeContext();
  const [openModal, setOpenModal] = useState(false);

  const [call, setCall] = useState(false);
  const [testDrive, setTestDrive] = useState(false);
  const [trade, setTrade] = useState(false);

  const handleOpenClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const currentTarget = event.currentTarget;

    setCall(currentTarget.getAttribute('data-call') === 'true');
    setTestDrive(currentTarget.getAttribute('data-testdrive') === 'true');
    setTrade(currentTarget.getAttribute('data-trade') === 'true');

    setOpenModal(true);
  };

  return (
    <>
      <PadBox>
        <Box>
          <VipHeadline headline={'Beregn din byttepris'} />
          <Typography id="write-to-dealer-text" sx={{ marginTop: 1.5 }}>
            Vil du vide hvad du skal give i bytte på din nuværende bil? Udfyld
            formularen og få en uforpligtende byttepris.
          </Typography>
          <Button
            id="write-to-dealer-btn"
            data-call={'false'}
            data-testdrive={'false'}
            data-trade={'true'}
            fullWidth={isMobile}
            sx={{
              fontSize: '15px',
              fontWeight: 700,
              height: '44px',
              whiteSpace: 'nowrap',
              marginTop: 1.5,
            }}
            color="secondary"
            onClick={handleOpenClick}>
            Få en byttepris
          </Button>
        </Box>
      </PadBox>

      <WriteDealerFormModal
        close={() => setOpenModal(false)}
        open={openModal}
        classified-item={item}
        callOptionInitialChecked={call}
        testDriveOptionInitialChecked={testDrive}
        tradeOptionInitialChecked={trade}
      />
    </>
  );
};
