import { useScreenSizeContext } from '@/pages/_app';
import { ClassifiedItem, UserProfileType } from '@/shared/lib-api';
import { Box, Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, {
  createContext,
  forwardRef,
  ReactElement,
  Ref,
  useContext,
} from 'react';
import { VipWriteToDealerFlat } from '../../vip/write-to-dealer';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface WriteDealerFormModalProps {
  'classified-item'?: ClassifiedItem;
  open: boolean;
  close: React.MouseEventHandler<HTMLImageElement>;
  callOptionInitialChecked?: boolean;
  testDriveOptionInitialChecked?: boolean;
  tradeOptionInitialChecked?: boolean;
  leasingOfferInitailChecked?: boolean;
}

const SRPModalMessageContext = createContext<SRPModalMessageContextprops>({});
export const useSRPModalMessageContext = () => {
  const context = useContext(SRPModalMessageContext);
  return context;
};
interface SRPModalMessageContextprops {
  item?: ClassifiedItem;
}

export const WriteDealerFormModal = (props: WriteDealerFormModalProps) => {
  const item = props['classified-item'];
  if (!item) {
    return <></>;
  }

  if (item.disableWriteTo) {
    return <></>;
  }

  return (
    <SRPModalMessageContext.Provider value={{ item }}>
      <WriteDealerFormModalFlat
        open={props.open}
        close={props.close}
        classifiedId={item.id}
        ownerId={item.owner.id}
        carMake={item.make}
        carModel={item.model}
        carVariant={item.alternativeVariant}
        ownerName={item.owner.name}
        ownerAddress={item.owner.address}
        ownerZipCode={item.owner.zipCode}
        ownerCity={item.owner.city}
        ownerProfileType={item.owner.profileType}
        callOptionInitialChecked={props.callOptionInitialChecked}
        testDriveOptionInitialChecked={props.testDriveOptionInitialChecked}
        tradeOptionInitialChecked={props.tradeOptionInitialChecked}
        leasingOfferInitailChecked={props.leasingOfferInitailChecked}
      />
    </SRPModalMessageContext.Provider>
  );
};

interface WriteDealerFormModalFlatProps {
  classifiedId?: number;
  ownerId?: number;
  carMake?: string;
  carModel: string;
  carVariant?: string;

  ownerName?: string;
  ownerAddress?: string;
  ownerZipCode?: number;
  ownerCity?: string;
  ownerProfileType?: UserProfileType;

  open: boolean;
  close: React.MouseEventHandler<HTMLImageElement>;

  callOptionInitialChecked?: boolean;
  testDriveOptionInitialChecked?: boolean;
  tradeOptionInitialChecked?: boolean;
  leasingOfferInitailChecked?: boolean;
}

export const WriteDealerFormModalFlat = (
  props: WriteDealerFormModalFlatProps,
) => {
  const { isMobile } = useScreenSizeContext();
  return (
    <>
      <Dialog
        maxWidth={'md'}
        open={props.open}
        onClose={props.close}
        fullWidth={true}
        scroll={'paper'}
        fullScreen={isMobile}
        TransitionComponent={Transition}
        componentsProps={{
          root: {
            style: {
              WebkitOverflowScrolling: 'touch',
            },
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: '#F2F6F8',
          },
        }}>
        <Box padding={3}>
          <VipWriteToDealerFlat
            classifiedId={props.classifiedId}
            onClose={props.close}
            ownerId={props.ownerId}
            carMake={props.carMake}
            carModel={props.carModel}
            carVariant={props.carVariant}
            ownerName={props.ownerName}
            ownerAddress={props.ownerAddress}
            ownerZipCode={props.ownerZipCode}
            ownerCity={props.ownerCity}
            ownerProfileType={props.ownerProfileType}
            callOptionInitialChecked={props.callOptionInitialChecked}
            testDriveOptionInitialChecked={props.testDriveOptionInitialChecked}
            tradeOptionInitialChecked={props.tradeOptionInitialChecked}
            leasingOfferOptionInitialChecked={props.leasingOfferInitailChecked}
            isModal={true}
            loadAlternatives={props.open}
          />
        </Box>
      </Dialog>
    </>
  );
};
