import { Colors } from '@/shared/util/colors';
import { Box, Breadcrumbs, Typography } from '@mui/material';
import MuiLink from '@mui/material/Link';
import React from 'react';
import { Trans } from 'react-i18next';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Dealer } from '@/shared/lib-api/models/Dealer';
import slugify from 'slugify';
import {
  slugifyOptions,
} from '@/web/util/helpers/url-helpers';

export const DealerBreadcrumb = (props: {dealer?: Dealer; }) => {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{
        color: Colors.Secondary,
        marginTop: { xs: '0.6rem', md: '1.5rem' },
        '& ol': {
          '& li': {
            margin: '0px',
          },
        },
      }}
      separator={<NavigateNextIcon fontSize="small" />}>
      <MuiLink underline="none" color="inherit" href="/find-forhandler">
        <Trans i18nKey={'Dealer.BreadcrumbDealers'} />
      </MuiLink>
      { props.dealer.countryArea &&
        <MuiLink underline="none" color="inherit" href={`/find-forhandler/omraade-${slugify(props.dealer.countryArea.toLowerCase(), slugifyOptions)}`}>
          {props.dealer.countryArea}
        </MuiLink>
      }
      <Typography color={Colors.Secondary}>{props.dealer?.name}</Typography>
    </Breadcrumbs>
  );
};
