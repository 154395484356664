import { useBasicCarNoAuth } from '@/shared/hooks/basic-car-hook';
import { ClassifiedItem } from '@/shared/lib-api';
import { Config } from '@/shared/util/config';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, useCallback, useMemo, MouseEvent } from 'react';

export const VipLink: React.FC<{
  item: ClassifiedItem;
  blank?: boolean;
  children: ReactNode;
}> = ({ item, children, blank }) => {
  const { carRoute } = useBasicCarNoAuth({
    item: item,
  });
  const router = useRouter();

  const noIndex = true;

  const onLinkClick = useCallback(
    (e: MouseEvent<HTMLElement>, url: string) => {
      e.preventDefault();
      if (blank) {
        window.open(url, '_blank');
      } else {
        router.push(url);
      }
    },
    [router, blank],
  );

  if (noIndex) {
    return (
      <div
        style={{ cursor: 'pointer' }}
        onClick={e => onLinkClick(e, carRoute)}>
        {children}
      </div>
    );
  }

  return <Link href={carRoute}>{children}</Link>;
};
