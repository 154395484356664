import { Box, Grid, Link, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PadBox } from '../containers/pad-box';
import { VipCollapse } from './collapse';
import { VipHeadline } from './headline';
import { useVipContext } from '@/pages/[headline]/[[...id]]';
import { useScreenSizeContext } from '@/pages/_app';

export const VipEquipmentCard = () => {
  const { item } = useVipContext();
  const { t } = useTranslation();
  const { isMobile } = useScreenSizeContext();
  const [collapsed, setCollapsed] = useState(isMobile);

  const equipments = useMemo(
    () =>
      item?.equipment &&
      item.equipment.slice(0, collapsed ? 10 : 100000).map((field, index) => {
        return (
          <Grid
            key={index}
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ marginTop: { sm: '2px' } }}>
            <Typography fontSize="15px" lineHeight={'18px'}>
              {field}
            </Typography>
          </Grid>
        );
      }),
    [item, collapsed],
  );

  if (!item || equipments.length < 1) {
    return null;
  }

  return (
    <PadBox>
      <VipHeadline headline={t('Vip.Equipment')} />

      {/* Desktop render */}
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Grid container rowSpacing={2} columnSpacing={2} marginTop={1}>
          {equipments}
        </Grid>
      </Box>

      {/* Mobile render */}
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Grid container rowSpacing={0.5} columnSpacing={2} marginTop={2}>
          {equipments}
        </Grid>
        {item?.equipment.length > 10 && (
          <Typography paddingTop={2} sx={{ cursor: 'pointer' }}>
            <Link
              onClick={() => setCollapsed(!collapsed)}
              underline="none"
              fontSize={'15px'}>
              {collapsed
                ? t('Vip.ShowMoreEquipment')
                : t('Vip.ShowLessEquipment')}
            </Link>
          </Typography>
        )}
      </Box>
    </PadBox>
  );
};
