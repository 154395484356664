/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthorPropertiesModel
 */
export interface AuthorPropertiesModel {
    /**
     * 
     * @type {string}
     * @memberof AuthorPropertiesModel
     */
    authorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthorPropertiesModel
     */
    authorPerson?: string | null;
}

export function AuthorPropertiesModelFromJSON(json: any): AuthorPropertiesModel {
    return AuthorPropertiesModelFromJSONTyped(json, false);
}

export function AuthorPropertiesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorPropertiesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authorName': !exists(json, 'authorName') ? undefined : json['authorName'],
        'authorPerson': !exists(json, 'authorPerson') ? undefined : json['authorPerson'],
    };
}

export function AuthorPropertiesModelToJSON(value?: AuthorPropertiesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authorName': value.authorName,
        'authorPerson': value.authorPerson,
    };
}

