/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FueltypePickerPropertiesModel,
    FueltypePickerPropertiesModelFromJSON,
    FueltypePickerPropertiesModelFromJSONTyped,
    FueltypePickerPropertiesModelToJSON,
} from './FueltypePickerPropertiesModel';
import {
    MakeModelPickerPropertiesModel,
    MakeModelPickerPropertiesModelFromJSON,
    MakeModelPickerPropertiesModelFromJSONTyped,
    MakeModelPickerPropertiesModelToJSON,
} from './MakeModelPickerPropertiesModel';

/**
 * 
 * @export
 * @interface VehicleCarrouselPropertiesModel
 */
export interface VehicleCarrouselPropertiesModel {
    /**
     * 
     * @type {number}
     * @memberof VehicleCarrouselPropertiesModel
     */
    count?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleCarrouselPropertiesModel
     */
    overskrift?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleCarrouselPropertiesModel
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleCarrouselPropertiesModel
     */
    makeModel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleCarrouselPropertiesModel
     */
    fueltype?: string | null;
}

export function VehicleCarrouselPropertiesModelFromJSON(json: any): VehicleCarrouselPropertiesModel {
    return VehicleCarrouselPropertiesModelFromJSONTyped(json, false);
}

export function VehicleCarrouselPropertiesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleCarrouselPropertiesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'overskrift': !exists(json, 'overskrift') ? undefined : json['overskrift'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'makeModel': !exists(json, 'makeModel') ? undefined : json['makeModel'],
        'fueltype': !exists(json, 'fueltype') ? undefined : json['fueltype'],
    };
}

export function VehicleCarrouselPropertiesModelToJSON(value?: VehicleCarrouselPropertiesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'overskrift': value.overskrift,
        'type': value.type,
        'makeModel': value.makeModel,
        'fueltype': value.fueltype,
    };
}

