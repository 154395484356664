import { BasicCarCardType } from '@/shared/hooks/basic-car-hook';
import { ClassifiedItem } from '@/shared/lib-api';
import BasicCarListCard from '@/web/views/components/cards/basic-car-card/basic-car-list-card';
import { default as React, useDeferredValue } from 'react';

export const SRPListModeResults: React.FC<{
  items?: ClassifiedItem[];
}> = ({ items }) => {
  const itemsTouse = useDeferredValue(items);
  return (
    <>
      {itemsTouse !== undefined
        ? itemsTouse.map((item, index) => {
            return (
              <BasicCarListCard
                eager={index === 0}
                origin={BasicCarCardType.SRPList}
                key={item.id}
                item={item}
              />
            );
          })
        : [1, 2, 3].map(p => (
            <BasicCarListCard key={p} origin={BasicCarCardType.SRPList} />
          ))}
    </>
  );
};
