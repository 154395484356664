import {
  ClassifiedDisplayItem,
  ClassifiedItem,
  ClassifiedItemAttachment,
} from '@/shared/lib-api';
import { Box, Grid } from '@mui/material';
import { default as React, useCallback } from 'react';
import { SeoCarGalleryCard } from '../cards/basic-car-card/seo-car-gallery-card';
import { SeoModelGalleryCard } from '../cards/basic-car-card/seo-model-gallery-card';
import { SeoBrandGalleryCard } from '../cards/basic-car-card/seo-brand-gallery-card';
import leftArrow from '@/shared/icons/left-arrow-white.svg';
import rightArrow from '@/shared/icons/right-arrow-white.svg';
import { Colors } from '@/shared/util/colors';
import { ImageGalleryCard } from '../cards/basic-car-card/image-gallery-card';

const GalleryResults: React.FC<{
  items: ClassifiedItem[];
  carrouselDesktop?: boolean;
  carrouselMobile?: boolean;
}> = ({ items, carrouselDesktop, carrouselMobile }) => {
  const boxRef = React.useRef<HTMLDivElement>(null);
  const [scrollLeft, setScrollLeft] = React.useState(0);
  const onScrollLeft = useCallback(() => {
    if (boxRef.current) {
      boxRef.current.scroll({
        behavior: 'smooth',
        left: boxRef.current.scrollLeft - 500,
      });
      setScrollLeft(Math.max(boxRef.current.scrollLeft - 500, 0));
    }
  }, []);
  const onScrollRight = useCallback(() => {
    if (boxRef.current) {
      boxRef.current.scroll({
        behavior: 'smooth',
        left: boxRef.current.scrollLeft + 500,
      });
      setScrollLeft(
        Math.min(
          boxRef.current.scrollLeft + 500,
          boxRef.current?.scrollWidth - boxRef.current?.clientWidth,
        ),
      );
    }
  }, []);

  if (carrouselMobile || carrouselDesktop) {
    return (
      <Box
        ref={boxRef}
        width={'100%'}
        overflow={carrouselDesktop ? 'hidden' : 'auto'}
        position={'relative'}>
        {carrouselDesktop && (
          <>
            <Box
              position={'sticky'}
              zIndex={200}
              top={'50%'}
              left={0}
              height={'1px'}
              width={'1px'}>
              <Box
                position={'absolute'}
                borderRadius={'50%'}
                height={50}
                width={50}
                display={'flex'}
                onClick={onScrollLeft}
                style={{ cursor: 'pointer' }}
                alignItems={'center'}
                justifyContent={'center'}
                bgcolor={scrollLeft <= 0 ? '#a1c5d0' : Colors.Primary}>
                <input type="image" src={leftArrow.src} height={20} />
              </Box>
            </Box>

            <Box
              position={'sticky'}
              zIndex={200}
              top={'50%'}
              left={`calc(100% - 50px)`}
              right={0}
              height={'1px'}
              width={'1px'}>
              <Box
                position={'absolute'}
                borderRadius={'50%'}
                height={50}
                width={50}
                display={'flex'}
                onClick={onScrollRight}
                style={{ cursor: 'pointer' }}
                alignItems={'center'}
                justifyContent={'center'}
                bgcolor={
                  scrollLeft >=
                  boxRef.current?.scrollWidth - boxRef.current?.clientWidth
                    ? '#a1c5d0'
                    : Colors.Primary
                }>
                <input type="image" src={rightArrow.src} height={20} />
              </Box>
            </Box>
          </>
        )}
        <Box display="inline-flex" paddingY={2}>
          {items !== undefined &&
            items.map((item, index) => {
              return (
                <Box
                  minWidth={
                    carrouselMobile
                      ? 'calc(100vw - 50px)'
                      : carrouselDesktop
                      ? 300
                      : 0
                  }
                  marginX={1}
                  key={item.id}>
                  <SeoCarGalleryCard
                    carrousel={carrouselMobile || carrouselDesktop}
                    key={item.id}
                    item={item}
                  />
                </Box>
              );
            })}
        </Box>
      </Box>
    );
  }
  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      {items !== undefined &&
        items.map(item => {
          return <SeoCarGalleryCard key={item.id} item={item} />;
        })}
    </Grid>
  );
};

export default GalleryResults;

export const ImageGalleryResults: React.FC<{
  items: ClassifiedItemAttachment[];
  carrouselDesktop?: boolean;
  carrouselMobile?: boolean;
}> = ({ items, carrouselDesktop, carrouselMobile }) => {
  const boxRef = React.useRef<HTMLDivElement>(null);
  const [scrollLeft, setScrollLeft] = React.useState(0);
  const onScrollLeft = useCallback(() => {
    if (boxRef.current) {
      boxRef.current.scroll({
        behavior: 'smooth',
        left: boxRef.current.scrollLeft - 500,
      });
      setScrollLeft(Math.max(boxRef.current.scrollLeft - 500, 0));
    }
  }, []);
  const onScrollRight = useCallback(() => {
    if (boxRef.current) {
      boxRef.current.scroll({
        behavior: 'smooth',
        left: boxRef.current.scrollLeft + 500,
      });
      setScrollLeft(
        Math.min(
          boxRef.current.scrollLeft + 500,
          boxRef.current?.scrollWidth - boxRef.current?.clientWidth,
        ),
      );
    }
  }, []);

  if (carrouselMobile || carrouselDesktop) {
    return (
      <Box
        ref={boxRef}
        width={'100%'}
        overflow={carrouselDesktop ? 'hidden' : 'auto'}
        position={'relative'}>
        {carrouselDesktop && (
          <>
            <Box
              position={'sticky'}
              zIndex={200}
              top={'40%'}
              left={0}
              height={'1px'}
              width={'1px'}>
              <Box
                position={'absolute'}
                borderRadius={'50%'}
                height={50}
                width={50}
                display={'flex'}
                onClick={onScrollLeft}
                style={{ cursor: 'pointer' }}
                alignItems={'center'}
                justifyContent={'center'}
                bgcolor={scrollLeft <= 0 ? '#a1c5d0' : Colors.Primary}>
                <input type="image" src={leftArrow.src} height={20} />
              </Box>
            </Box>

            <Box
              position={'sticky'}
              zIndex={200}
              top={'40%'}
              left={`calc(100% - 50px)`}
              right={0}
              height={'1px'}
              width={'1px'}>
              <Box
                position={'absolute'}
                borderRadius={'50%'}
                height={50}
                width={50}
                display={'flex'}
                onClick={onScrollRight}
                style={{ cursor: 'pointer' }}
                alignItems={'center'}
                justifyContent={'center'}
                bgcolor={
                  scrollLeft >=
                  boxRef.current?.scrollWidth - boxRef.current?.clientWidth
                    ? '#a1c5d0'
                    : Colors.Primary
                }>
                <input type="image" src={rightArrow.src} height={20} />
              </Box>
            </Box>
          </>
        )}
        <Box display="inline-flex">
          {items !== undefined &&
            items.map((item, index) => {
              return (
                <Box
                  minWidth={
                    carrouselMobile
                      ? 'calc(100vw - 50px)'
                      : carrouselDesktop
                      ? 300
                      : 0
                  }
                  marginX={1}
                  key={item.id}>
                  <ImageGalleryCard
                    carrousel={carrouselMobile || carrouselDesktop}
                    key={item.id}
                    item={item}
                  />
                </Box>
              );
            })}
        </Box>
      </Box>
    );
  }
  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      {items !== undefined &&
        items.map(item => {
          return <ImageGalleryCard key={item.id} item={item} />;
        })}
    </Grid>
  );
};

export const ModelResults: React.FC<{
  items: ClassifiedDisplayItem[];
  otherModels?: boolean;
}> = ({ items, otherModels }) => {
  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      {items !== undefined &&
        items.map((item, index) => {
          return (
            <SeoModelGalleryCard
              otherModels={otherModels}
              big={index < 2}
              key={item.url}
              item={item}
            />
          );
        })}
    </Grid>
  );
};

export const BrandResults: React.FC<{
  items: ClassifiedDisplayItem[];
}> = ({ items }) => {
  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      {items !== undefined &&
        items.map((item, index) => {
          return (
            <SeoBrandGalleryCard big={index < 2} key={item.url} item={item} />
          );
        })}
    </Grid>
  );
};
