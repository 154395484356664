import React, { FC, useEffect, useState } from 'react';
import umbrella from './umbrella.svg';
import logo from './logo.png';
import { Box, Button, Typography } from '@mui/material';
import { OpenExternalLink } from '@/web/util/helpers/open-external-link';
import {
  LoadingState,
  thousandNumberSeperator,
} from '@/shared/util/general-helpers';
import { useRequestHelper } from '@/shared/hooks/request-helper';
import { useApi } from '@/shared/util/api';
import Image from '@/web/views/components/image/image';
import { TrackEvent } from '@/shared/util/gtm';

type PropsFromParent = {
  classifiedId: number;
  make: string;
  model: string;
  variant: string;
  year: string;
  category: string;
  onIsAvailable: (boolean) => void;
};

const VipCard: FC<PropsFromParent> = ({
  classifiedId,
  make,
  model,
  variant,
  year,
  category,
  onIsAvailable: onAvailable,
}) => {
  const { classifiedApi } = useApi();
  const { handleRequest } = useRequestHelper();
  const [monthlyPrice, setMonthlyPrice] = useState<number>();
  const [precision, setPrecision] = useState<string>('');

  const styles = {
    '&.MuiButton-root': {
      backgroundColor: '#1066B6',
      color: '#fff',
      fontWeight: 700,
    },
  };

  const handleOnClick = () => {
    TrackEvent('samlino_klik');

    const licensePlateType = category.includes('Varevogn') ? '' : 'white';

    const linkWithUtm = `https://www.samlino.dk/bilforsikring?licensePlateType=${licensePlateType}&carBrand=${make}&carModel=${model}&carVintage=${year}&carEngineVariant=${variant}&knowLicensePlateNum=false&utm_source=bilhandel&utm_medium=cpc&utm_campaign=ciweek44_23&products=car&single-product-journey=true`;
    OpenExternalLink(linkWithUtm);
  };

  useEffect(() => {
    (async () => {
      if (classifiedId) {
        const request = classifiedApi.insurance({
          classifiedId,
        });
        let [result, errorAndState] = await handleRequest(request);
        if (errorAndState.state === LoadingState.Success) {
          setMonthlyPrice(Math.round(result.lowestMonhtlyPrice));
          setPrecision(result.precision);
          onAvailable(true);
          return;
        }
      }
      onAvailable(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classifiedId, handleRequest, onAvailable]);

  const forLabel = precision === 'make' ? make : `${make} ${model}`;
  return (
    <>
      {monthlyPrice && (
        <Box
          bgcolor={'#fff'}
          textAlign={'center'}
          borderRadius={'10px'}
          padding={'28px'}
          height={'100%'}>
          <div id="samlino_card">
            <Box>
              <Image
                alt="Forsikring"
                src={umbrella.src}
                height={44}
                width={46}
              />
            </Box>

            <Typography fontWeight={700} fontSize={18}>
              Denne {forLabel} kan forsikres fra
            </Typography>
            <Typography fontWeight={700} fontSize={15} marginTop={'10px'}>
              <span style={{ fontSize: '22px' }}>
                {thousandNumberSeperator(monthlyPrice)}
              </span>{' '}
              kr. / md.
            </Typography>

            <Box marginTop={'19px'}>
              <Button sx={styles} onClick={handleOnClick}>
                Sammenlign forsikringer
              </Button>
            </Box>

            <Box marginTop={6}>
              <Image alt="Forsikring" src={logo.src} height={20} width={81} />
            </Box>
          </div>
        </Box>
      )}
    </>
  );
};

export default VipCard;
