/* eslint-disable @next/next/no-img-element */
import { Box, Grid, Link, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { PadBox } from '../containers/pad-box';
import { VipWriteToDealer } from './write-to-dealer';
import DefaultMapWrapper from '../default-components/map/default-map-wrapper';
import { UserProfileType } from '@/shared/lib-api';
import { getDealerVipUrl } from '@/web/util/helpers/url-helpers';
import { styled } from '@mui/system';
import { Colors } from '@/shared/util/colors';
import { VipHeadline } from './headline';
import { Trans } from 'react-i18next';
import ClassificationLimitWrapper from '../containers/classification-limit-wrapper';
import { useVipContext } from '@/pages/[headline]/[[...id]]';
import Codan from '@/shared/icons/partner/codan.png';
import Fragus from '@/shared/icons/partner/gosafe.svg';
import Ok from '@/shared/icons/partner/ok-logo.svg';
import Santander from '@/shared/icons/partner/banco-santander-logo.svg';
import Clever from '@/shared/icons/partner/clever.svg';
import Cargarantie from '@/shared/icons/partner/logo_cargarantie.png';

const MapContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '100%',
  minHeight: '700px',
});

const MapBoxContainer = styled(Box)({
  position: 'absolute',
  width: '90%',
  zIndex: 3,
  backgroundColor: 'white',
  marginLeft: 'auto',
  marginRight: 'auto',
  left: '0',
  right: '0',
  top: '20px',
  padding: '14px 19px 13px 19px',
  fontSize: '18px',
  borderRadius: '10px',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
});

const MapMapContainer = styled(Box)({
  position: 'absolute',
  zIndex: 2,
  width: '100%',
  height: '100%',
});

const GoogleMapsContainter = styled(Box)({
  position: 'absolute',
  zIndex: 3,
  backgroundColor: 'white',
  marginLeft: 'auto',
  marginRight: 'auto',
  right: '20px',
  bottom: '20px',
  fontSize: '16px',
  borderRadius: '3px',
});

const GoogleMapsLink = styled(Link)({
  fontSize: '16px',
  fontWeight: '700',
  cursor: 'pointer',
  padding: '11px 18px 10px 18px',
  display: 'inline-block',
  textDecoration: 'none',
  color: Colors.Black,
});

const Header = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const Badge = styled(Typography)({
  borderRadius: '2px',
  marginLeft: '15px',
  paddingLeft: '8px',
  paddingRight: '8px',
  backgroundColor: '#E1EAED',
  fontSize: '12px',
});

export const VipDealerMapCard = () => {
  const { item } = useVipContext();

  if (!item?.owner) {
    return <></>;
  }

  const phoneDisplay = () => {
    const part1 = item.owner.phone.substring(0, 2);
    const part2 = item.owner.phone.substring(2, 4);
    const part3 = item.owner.phone.substring(4, 6);
    const part4 = item.owner.phone.substring(6, 8);

    return (
      <span style={{ marginLeft: '2px' }}>
        {/* <span style={{ marginRight: '2px' }}>{part1}</span>
        <span style={{ marginRight: '2px' }}>{part2}</span>
        <span style={{ marginRight: '2px' }}>{part3}</span>
        <span style={{ marginRight: '2px' }}>{part4}</span> */}
        {`${part1} ${part2} ${part3} ${part4}`}
      </span>
    );
  };

  return (
    <Box>
      <PadBox>
        <Header>
          <VipHeadline headline={t('Labels.Seller')} />
          <Badge>
            <>
              {item.owner.profileType === UserProfileType.Company
                ? t('Labels.Dealer')
                : t('Labels.Private')}
            </>
          </Badge>
        </Header>
        <Grid container columnSpacing={3} marginTop={2}>
          <Grid item xs={6}>
            <MapContainer>
              <MapBoxContainer>
                <Box display="flex" width="100%" justifyContent="space-between">
                  <Box overflow="hidden" whiteSpace="nowrap">
                    <Box
                      display="flex"
                      flexDirection="column"
                      height="100%"
                      justifyContent="space-between">
                      {item.owner.name && (
                        <Box>
                          <Typography fontWeight={700} fontSize={16}>
                            {item.owner.name}
                          </Typography>
                        </Box>
                      )}
                      <Box marginY={0.5}>
                        {item.owner.address && (
                          <Typography fontSize={16}>
                            {item.owner.address}
                          </Typography>
                        )}
                        <Typography fontSize={16}>
                          {item.owner.zipCode} {item.owner.city}
                        </Typography>
                      </Box>
                      {item.owner.phone && (
                        <Box fontSize={16}>
                          <Typography fontSize={16}>
                            Telefon{' '}
                            <Link
                              href={`tel:${item.owner.phone}`}
                              id={'lnk-vip-map-dealer-call '}
                              className={'dealer-call'}
                              underline="none">
                              {phoneDisplay()}
                            </Link>
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  {item.owner.profileType === UserProfileType.Company && (
                    <Box overflow="hidden" whiteSpace="nowrap">
                      <Box
                        display="flex"
                        flexDirection="column"
                        height="100%"
                        justifyContent="space-between">
                        {item.owner.imageUrl && (
                          <Box textAlign="right">
                            <img
                              alt={item.owner.name}
                              width={200}
                              height={56}
                              loading="lazy"
                              style={{
                                objectFit: 'scale-down',
                                objectPosition: 'right center',
                                maxWidth: '100%',
                              }}
                              src={item.owner.imageUrl}
                            />
                          </Box>
                        )}
                        <Box textAlign="right">
                          <Link
                            lineHeight="22px"
                            fontSize={16}
                            underline="none"
                            href={getDealerVipUrl(
                              item.owner.name,
                              item.owner.city,
                              item.owner.zipCode,
                              item.owner.id,
                            )}
                            id={'lnk-vip-map-show-dealer'}
                            className={'dealer-show'}>
                            <>{t('Vip.ShowAllDealerCars')}</>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </MapBoxContainer>

              {item.owner.profileType === UserProfileType.Company && (
                <GoogleMapsContainter>
                  <GoogleMapsLink
                    href={`https://www.google.com/maps/search/?api=1&query=${item.owner.latitude},${item.owner.longitude}`}
                    target="_blank">
                    <Trans i18nKey="Vip.ShowOnGoogleMaps" />
                  </GoogleMapsLink>
                </GoogleMapsContainter>
              )}

              <MapMapContainer>
                <ClassificationLimitWrapper classification={51}>
                  <DefaultMapWrapper
                    markerPosition={
                      item.owner.latitude && item.owner.longitude
                        ? [item.owner.latitude, item.owner.longitude]
                        : null
                    }
                    markerRadius={
                      item.owner.profileType == UserProfileType.Company
                        ? 0
                        : 100
                    }
                    borderBottomRadius="10px"
                    borderTopRadius="10px"
                  />
                </ClassificationLimitWrapper>
              </MapMapContainer>
            </MapContainer>
          </Grid>
          <Grid item xs={6}>
            {item.owner.writeEnabled && (
              <VipWriteToDealer classified-item={item} />
            )}
          </Grid>
          {item.owner.isDBFUMember &&
            (item.owner.partnerOK ||
              item.owner.partnerFragus ||
              item.owner.partnerCarGarantie ||
              item.owner.partnerClever ||
              item.owner.partnerCodan ||
              item.owner.partnerSantander) && (
              <Grid marginTop={2} item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {item.owner.name} og DBFU samarbejder med:
                    </Typography>
                  </Grid>
                  {item.owner.partnerCodan && (
                    <Grid item xs={3}>
                      <Box
                        paddingY={1}
                        display="flex"
                        flexDirection={'column'}
                        height={150}
                        justifyContent={'space-between'}>
                        <img
                          src={Codan.src}
                          alt="codan"
                          style={{ maxHeight: 40 }}
                        />
                        <Typography minHeight={66}>
                          Vi kan forsikre din bil til markedets bedste vilkår.
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {item.owner.partnerFragus && (
                    <Grid item xs={3}>
                      <Box
                        paddingY={1}
                        display="flex"
                        flexDirection={'column'}
                        height={150}
                        justifyContent={'space-between'}>
                        <img
                          src={Fragus.src}
                          alt="fragus"
                          style={{ height: 40 }}
                        />
                        <Typography minHeight={66}>
                          Brugtbilsgaranti - til dig, der ønsker lidt mere
                          sikkerhed.
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {item.owner.partnerClever && (
                    <Grid item xs={3}>
                      <Box
                        paddingY={1}
                        display="flex"
                        flexDirection={'column'}
                        height={150}
                        justifyContent={'space-between'}>
                        <img
                          src={Clever.src}
                          alt="clever"
                          style={{ height: 40 }}
                        />
                        <Typography minHeight={66}>
                          Mærk friheden - Kør med på Danmarks største
                          ladenetværk
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {item.owner.partnerCarGarantie && (
                    <Grid item xs={3}>
                      <Box
                        paddingY={1}
                        display="flex"
                        flexDirection={'column'}
                        height={150}
                        justifyContent={'space-between'}>
                        <img
                          src={Cargarantie.src}
                          alt="cargarantie"
                          style={{ maxHeight: 40 }}
                        />
                        <Typography minHeight={66}>
                          Holder dig kørende og fri for omkostninger
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {item.owner.partnerOK && (
                    <Grid item xs={3}>
                      <Box
                        paddingY={1}
                        display="flex"
                        flexDirection={'column'}
                        height={150}
                        justifyContent={'space-between'}>
                        <img src={Ok.src} alt="ok" style={{ maxHeight: 40 }} />
                        <Typography minHeight={66}>
                          Vi hjælper dig med at finde den rette ladeløsning hos
                          OK.
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {item.owner.partnerSantander && (
                    <Grid item xs={3}>
                      <Box
                        paddingY={1}
                        display="flex"
                        flexDirection={'column'}
                        height={150}
                        justifyContent={'space-between'}>
                        <img
                          src={Santander.src}
                          alt="santander"
                          style={{ maxHeight: 40 }}
                        />
                        <Typography minHeight={66}>
                          Finansier dit bilkøb med et konkurrencedygtigt billån
                          via Santander.
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
        </Grid>
      </PadBox>
    </Box>
  );
};
