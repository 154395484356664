import React from 'react';
import { Typography } from '@mui/material';

interface VipHeadlineProps {
  headline: string;
}

export const VipHeadline = (props: VipHeadlineProps) => {
  return (
    <Typography
      fontSize="24px"
      fontWeight="700"
      lineHeight="125%"
      component={'h3'}
      className={'vip-headline'}>
      {props.headline}
    </Typography>
  );
};
