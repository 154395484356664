import { useAppSelector } from '@/shared/store/hooks';
import { Link, Typography } from '@mui/material';
import { PadBox } from '../containers/pad-box';
import React, { useMemo, useState } from 'react';
import { VipCollapse } from '../vip/collapse';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { theme } from '@/web/util/theme';
import { Colors } from '@/shared/util/colors';
//@ts-ignore
const RenderedHtmlWrapper = styled.span(props => ({
  fontFamily: theme.typography.fontFamily,
  '& p,ol,li': theme.typography.body1,
  '& h1,h2,h3,h4,h5,h6,h7,h8': {
    ...theme.typography.h6,
    color: Colors.Primary,
  },
  '& a': { ...theme.typography.body1, color: Colors.Primary },
}));

export const BottomSeoContent = () => {
  const seoOverride = useAppSelector(state => state.srpReducer.seoOverride);
  const [collapsed, setCollapsed] = useState(true);
  const { t } = useTranslation();

  const parsedData = useMemo(() => {
    if (!(seoOverride && seoOverride.description)) {
      return '';
    }
    return seoOverride.description;
  }, [seoOverride]);
  return (
    <>
      {parsedData && (
        <PadBox bgColor={'#F6FAFB'}>
          <VipCollapse backgroundColor="#F6FAFB" collapsed={collapsed}>
            {seoOverride && seoOverride.description && (
              <RenderedHtmlWrapper
                dangerouslySetInnerHTML={{ __html: parsedData }}
              />
            )}
          </VipCollapse>
          {seoOverride && seoOverride.description && (
            <Typography paddingTop={2} sx={{ cursor: 'pointer' }}>
              <Link
                onClick={() => setCollapsed(!collapsed)}
                underline="none"
                fontSize={'15px'}
                id={
                  collapsed ? 'lnk-seotext-showmore' : 'lnk-seotext-showless'
                }>
                {collapsed ? t('SRP.ShowMore') : t('SRP.ShowLess')}
              </Link>
            </Typography>
          )}
        </PadBox>
      )}
    </>
  );
};
