/* eslint-disable @next/next/no-img-element */
import { useVipContext } from '@/pages/[headline]/[[...id]]';
import { useScreenSizeContext } from '@/pages/_app';
import {
  GetFullscreenImage,
  GetMediumImage,
  GetSmallImage,
} from '@/shared/util/helpers/image-helper';
import { Box, Link } from '@mui/material';
import React, { useMemo } from 'react';

export const VipGalleryMobile = () => {
  const { item } = useVipContext();
  const { isMobile } = useScreenSizeContext();
  const galleryItems = useMemo(() => {
    if (!item) {
      return [];
    }

    const images = item?.images.map(image => {
      return {
        fullscreen: GetFullscreenImage(image.folderId, image.id),
        original: GetMediumImage(image.folderId, image.id),
        thumbnail: GetSmallImage(image.folderId, image.id),
        type: 'image',
      };
    });

    const videos = item?.videos.map(video => {
      return {
        fullscreen: video.videoUrl,
        original: video.videoUrl,
        thumbnail: video.videoPosterUrl,
        type: 'video',
      };
    });

    let combined = [...videos, ...images];
    return combined;
  }, [item]);

  const _renderVideo = item => {
    return (
      <video
        key={item.original}
        controls={true}
        loop={true}
        playsInline
        autoPlay={true}
        style={{
          width: '100%',
          height: '100%',
          maxWidth: '1440px',
          marginBottom: '2px',
        }}>
        <source type="video/mp4" src={item.original} />
      </video>
    );
  };

  const _renderImage = image => {
    return (
      <Link
        key={image.fullscreen}
        href={image.fullscreen.split('?')[0]}
        style={{ marginBottom: '2px' }}>
        <img
          alt={item.headLine}
          style={{
            minHeight: isMobile ? 180 : 550,
            maxWidth: '100%',
            objectFit: 'scale-down',
          }}
          loading="lazy"
          src={image.fullscreen}
        />
      </Link>
    );
  };

  return (
    <Box
      bgcolor={'#fff'}
      display="flex"
      flexDirection="column"
      alignItems={'center'}>
      {galleryItems.map(item =>
        item.type === 'video' ? _renderVideo(item) : _renderImage(item),
      )}
    </Box>
  );
};
