import { Box, Grid, Link, styled, Typography } from '@mui/material';
import React, { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubHeaderTextField } from '../default-components/text/sub-header-text-field';
import { PadBox } from '../containers/pad-box';
import { VipCollapse } from './collapse';
import { VipHeadline } from './headline';
import { useVipContext } from '@/pages/[headline]/[[...id]]';

const Label = styled(Typography)({
  fontSize: '15px',
  fontWeight: 700,
});

const Value = styled(Typography)({
  fontSize: '15px',
});

export const VipSpecCard = () => {
  const { item } = useVipContext();
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(true);

  const specs = useMemo(
    () =>
      item?.specifications &&
      item?.specifications.map((field, index) => {
        return (
          <Grid key={index} item xs={12} md={3}>
            <SubHeaderTextField subHeader={field.label} text={field.value} />
          </Grid>
        );
      }),
    [item],
  );

  if (!item || specs.length < 1) {
    return null;
  }

  return (
    <PadBox>
      <VipHeadline headline={t('Vip.Specs')} />

      {/* Desktop render */}
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Grid container rowSpacing={2} columnSpacing={2} marginTop={1}>
          {specs}
        </Grid>
      </Box>

      {/* Mobile render */}
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <VipCollapse collapsed={collapsed}>
          <Grid container marginTop={2}>
            {item?.specifications.map((field, index) => {
              return (
                <Fragment key={index}>
                  <Grid
                    item
                    xs={6}
                    borderBottom={'1px solid #E5ECEF'}
                    paddingY={1.25}>
                    <Label>{field.label}</Label>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    textAlign={'right'}
                    borderBottom={'1px solid #E5ECEF'}
                    paddingY={1.25}>
                    <Value>{field.value}</Value>
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>
        </VipCollapse>
        {item?.specifications.length > 7 && (
          <Typography paddingTop={2} sx={{ cursor: 'pointer' }}>
            <Link
              onClick={() => setCollapsed(!collapsed)}
              underline="none"
              fontSize={'15px'}>
              {collapsed
                ? t('Vip.ShowMoreSpecifications')
                : t('Vip.ShowLessSpecifications')}
            </Link>
          </Typography>
        )}
      </Box>
    </PadBox>
  );
};
