import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ClassifiedItem } from '@/shared/lib-api';
import { useRequestHelper } from '@/shared/hooks/request-helper';
import { useApi } from '@/shared/util/api';
import { LoadingState } from '@/shared/util/general-helpers';
import { CallIcon } from '../../default-components/input/input-icons/callNowIcon';
import {
  BuildInTrackEvents,
  TrackClassifiedItemEvent,
} from '@/shared/util/gtm';
import { Box, Hidden } from '@mui/material';
import { CallDealerFormModal } from '../../default-components/call-dealer-form-modal/call-dealer-form-modal';
import { useRouter } from 'next/router';

type PropsFromParent = {
  item: ClassifiedItem;
};

const LazyCallNow: FunctionComponent<PropsFromParent> = ({ item }) => {
  const [phone, setPhone] = useState<string>(
    item.owner.phoneOverride ?? item.owner.phone,
  );
  const router = useRouter();
  const trackCallClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    router.replace(`${router.asPath.split('#')[0]}#${item.id}`, undefined, {
      shallow: true,
    });
    TrackClassifiedItemEvent(BuildInTrackEvents.OnDealerListCallClicked, item);
  };

  const [openCallModal, setOpenCallModal] = useState(false);
  const flipCallModalOpen = useCallback(
    (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
      e.stopPropagation();
      setOpenCallModal(openCallModal ? false : true);
    },
    [openCallModal],
  );

  return (
    <>
      <Hidden mdUp>
        <Box>
          <a href={`tel:${phone}`} onClick={e => e.stopPropagation()}>
            <CallIcon onClick={trackCallClick} loading={false} />
          </a>
        </Box>
      </Hidden>
      <Hidden mdDown>
        <Box onClick={flipCallModalOpen}>
          <CallIcon onClick={trackCallClick} loading={false} />
        </Box>
        <CallDealerFormModal
          phone={phone}
          classified-item={item}
          open={openCallModal}
          close={flipCallModalOpen}
        />
      </Hidden>
    </>
  );
};

export default LazyCallNow;
