/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IApiElementModel,
    IApiElementModelFromJSON,
    IApiElementModelFromJSONTyped,
    IApiElementModelToJSON,
} from './IApiElementModel';
import {
    RelatedArticlesPropertiesModel,
    RelatedArticlesPropertiesModelFromJSON,
    RelatedArticlesPropertiesModelFromJSONTyped,
    RelatedArticlesPropertiesModelToJSON,
} from './RelatedArticlesPropertiesModel';

/**
 * 
 * @export
 * @interface RelatedArticlesElementModel
 */
export interface RelatedArticlesElementModel extends IApiElementModel {
    /**
     * 
     * @type {RelatedArticlesPropertiesModel}
     * @memberof RelatedArticlesElementModel
     */
    properties?: RelatedArticlesPropertiesModel;
}

export function RelatedArticlesElementModelFromJSON(json: any): RelatedArticlesElementModel {
    return RelatedArticlesElementModelFromJSONTyped(json, false);
}

export function RelatedArticlesElementModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelatedArticlesElementModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...IApiElementModelFromJSONTyped(json, ignoreDiscriminator),
        'properties': !exists(json, 'properties') ? undefined : RelatedArticlesPropertiesModelFromJSON(json['properties']),
    };
}

export function RelatedArticlesElementModelToJSON(value?: RelatedArticlesElementModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...IApiElementModelToJSON(value),
        'properties': RelatedArticlesPropertiesModelToJSON(value.properties),
    };
}

