import { Box, Typography } from '@mui/material';
import React from 'react';

interface SubHeaderTextFieldProps {
  subHeader?: string;
  text?: string;
}

export const SubHeaderTextField = (props: SubHeaderTextFieldProps) => (
  <Box>
    <Typography variant="caption">{props.subHeader}</Typography>
    <Typography>{props.text}</Typography>
  </Box>
);
