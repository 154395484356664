/* eslint-disable @next/next/no-img-element */
import { Dealer, SearchResult } from '@/shared/lib-api';
import { Colors } from '@/shared/util/colors';
import { Box, Button, Grid, Typography } from '@mui/material';
import Link from 'next/link';
import React, { useCallback, useState } from 'react';
import { Trans } from 'react-i18next';
import { PadBox } from '../containers/pad-box';
import { GalleryBoxGalleryCard } from '../default-components/gallery/gallery-box-srp';
import { WriteDealerFormModalFlat } from '../default-components/write-dealer-form-modal/write-dealer-form-modal';
import Dbfu from '@/shared/icons/dbfu-logo-2-blue.png';
import Autotjek from '@/shared/icons/autotjek-logo.png';
import { useRouter } from 'next/router';
import { GetMediumImage } from '@/shared/util/helpers/image-helper';

const InfoBox = (props: { children: JSX.Element }) => {
  return (
    <Box
      sx={{ padding: { xs: '15px', md: '25px' } }}
      marginTop={'25px'}
      width={'100%'}
      bgcolor={Colors.Background.SubBackgroundColor}
      borderRadius={'3px'}>
      {props.children}
    </Box>
  );
};

const Name = (props: { children: string }) => {
  return (
    <Typography fontWeight={700} fontSize={18} component={'h1'}>
      {props.children}
    </Typography>
  );
};

const Address = (props: { children: string; marginTop?: number }) => {
  return (
    <Typography
      marginTop={props.marginTop}
      fontWeight={300}
      fontSize={18}
      lineHeight={'30px'}>
      {props.children}
    </Typography>
  );
};

const Phone = (props: {
  children: JSX.Element | string;
  onClick?: () => void;
}) => {
  return (
    <Typography
      fontSize={18}
      variant={'clickAble'}
      onClick={props.onClick ? props.onClick : () => {}}>
      {props.children}
    </Typography>
  );
};

const Website = (props: { children: JSX.Element | string }) => {
  return (
    <Typography fontSize={16} variant="clickAble">
      {props.children}
    </Typography>
  );
};

interface DealerPageProps {
  dealer?: Dealer;
  searchResult?: SearchResult;
}

export const DealerCard = (props: DealerPageProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const showPhoneNumberClicked = useCallback(() => {
    setShowPhoneNumber(true);
  }, []);
  const router = useRouter();

  const flipModalOpen = useCallback(() => {
    setOpenModal(openModal ? false : true);
  }, [openModal]);

  const goToDbfu = useCallback(() => {
    router.push('/dbfu');
  }, [router]);

  const goToAutotjek = useCallback(() => {
    router.push('/100pct-autotjek');
  }, [router]);

  const dealer = props.dealer;
  if (!dealer) {
    return <></>;
  }

  return (
    <>
      <WriteDealerFormModalFlat
        carModel=""
        carMake=""
        carVariant=""
        open={openModal}
        close={flipModalOpen}
        ownerId={dealer.id}
        ownerName={dealer.name}
        ownerAddress={dealer.address}
        ownerZipCode={dealer.zipCode}
        ownerCity={dealer.city}
      />

      <PadBox>
        <Box display="flex" width="100%">
          <Box
            flexGrow={1}
            display="flex"
            flexDirection={'column'}
            justifyContent="space-between">
            {dealer.imageUrl && (
              <Box>
                <img
                  alt={dealer.name}
                  width={400}
                  height={100}
                  loading="lazy"
                  style={{
                    objectFit: 'scale-down',
                    objectPosition: 'left center',
                    maxWidth: '100%',
                  }}
                  src={dealer.imageUrl}
                />
              </Box>
            )}

            <InfoBox>
              <>
                <Box display="flex" justifyContent={'space-between'}>
                  <Box>
                    <Name>{dealer.name}</Name>
                    <Address marginTop={2}>{dealer.address}</Address>
                    <Address>
                      {`${dealer.zipCode ?? ''} ${dealer.city}`.trim()}
                    </Address>
                  </Box>
                  {dealer.isDBFUMember && (
                    <Box style={{ cursor: 'pointer' }} onClick={goToDbfu}>
                      <Typography>Medlem af:</Typography>
                      <img
                        alt={'dbfu'}
                        width={110}
                        height={50}
                        loading="lazy"
                        style={{
                          objectFit: 'scale-down',
                          objectPosition: 'left center',
                          maxWidth: '100%',
                          marginTop: '10px',
                        }}
                        src={Dbfu.src}
                      />
                    </Box>
                  )}
                  {dealer.isAutoTjekMember && (
                    <Box
                      textAlign={'center'}
                      style={{ cursor: 'pointer' }}
                      onClick={goToAutotjek}>
                      {!dealer.isDBFUMember && (
                        <Typography>Vi er en del af:</Typography>
                      )}
                      <img
                        alt={'100autotjek'}
                        height={70}
                        loading="lazy"
                        style={{
                          objectFit: 'scale-down',
                          objectPosition: 'left center',
                          maxWidth: '100%',
                          marginTop: '10px',
                        }}
                        src={Autotjek.src}
                      />
                    </Box>
                  )}
                </Box>

                <Grid container width="100%">
                  <Grid item xs={12} sm={6} marginTop={2}>
                    <Box></Box>
                    {/* {dealer.website && (
                      <Box marginTop={2}>
                        <Link
                          target={'_blank'}
                          id={'lnk-dealervip-website'}
                          className={'dealer-website'}
                          href={`https://${dealer.website
                            .replace('https://', '')
                            .replace(
                              'http://',
                              '',
                            )}?utm_source=Bilhandel&utm_medium=referral&utm_campaign=forhandlerside`}>
                          <Website>
                            <Trans i18nKey={'Labels.SeeHomePage'} />
                          </Website>
                        </Link>
                      </Box>
                    )} */}
                  </Grid>
                  <Grid item xs={12} sm={6} marginTop={2}>
                    {props.dealer?.hasActiveClassifieds && (
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        flexDirection="column"
                        height="100%">
                        <Box
                          alignSelf="flex-end"
                          width="100%"
                          sx={{ maxWidth: { sm: '200px' } }}>
                          <Button
                            className="dealer-write"
                            color={'secondary'}
                            fullWidth={true}
                            onClick={flipModalOpen}>
                            <Trans i18nKey={'Labels.ContactUs'} />
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </>
            </InfoBox>
          </Box>
          <Box marginLeft={3} sx={{ display: { xs: 'none', md: 'block' } }}>
            <Box width="500px" height="100%">
              <GalleryBoxGalleryCard
                minHeight={0}
                borderRadiusImage="none"
                items={props.searchResult.items?.flatMap(item =>
                  item.images
                    ?.slice(0, 1)
                    .flatMap(image => GetMediumImage(image.folderId, image.id)),
                )}
                alt={props.dealer.name}
              />
            </Box>
          </Box>
        </Box>
      </PadBox>
    </>
  );
};
