/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IApiElementModel,
    IApiElementModelFromJSON,
    IApiElementModelFromJSONTyped,
    IApiElementModelToJSON,
} from './IApiElementModel';
import {
    SektionFarvefordelingPropertiesModel,
    SektionFarvefordelingPropertiesModelFromJSON,
    SektionFarvefordelingPropertiesModelFromJSONTyped,
    SektionFarvefordelingPropertiesModelToJSON,
} from './SektionFarvefordelingPropertiesModel';

/**
 * 
 * @export
 * @interface SektionFarvefordelingElementModel
 */
export interface SektionFarvefordelingElementModel extends IApiElementModel {
    /**
     * 
     * @type {SektionFarvefordelingPropertiesModel}
     * @memberof SektionFarvefordelingElementModel
     */
    properties?: SektionFarvefordelingPropertiesModel;
}

export function SektionFarvefordelingElementModelFromJSON(json: any): SektionFarvefordelingElementModel {
    return SektionFarvefordelingElementModelFromJSONTyped(json, false);
}

export function SektionFarvefordelingElementModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SektionFarvefordelingElementModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...IApiElementModelFromJSONTyped(json, ignoreDiscriminator),
        'properties': !exists(json, 'properties') ? undefined : SektionFarvefordelingPropertiesModelFromJSON(json['properties']),
    };
}

export function SektionFarvefordelingElementModelToJSON(value?: SektionFarvefordelingElementModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...IApiElementModelToJSON(value),
        'properties': SektionFarvefordelingPropertiesModelToJSON(value.properties),
    };
}

