import { Dealer, SearchPageAttribute, SearchResult } from '@/shared/lib-api';
import { thousandNumberSeperator } from '@/shared/util/general-helpers';
import { setHtml } from '@/shared/util/helpers/general-helper';
import { Button, Grid, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PadBox } from '../containers/pad-box';
import { DealerSearchFilters as SearchFilters } from '@/pages/forhandler/[dealer]/[city]/[id]/[[...slug]]';
import SimpleInputSelect, {
  SimpleInputSelectItem,
} from '../default-components/input/defaults/selects/simple-input-select';
import { useApi } from '@/shared/util/api';

interface DealerSearchFiltersProps {
  amountForSaleText: string;
  makes: SearchPageAttribute[];
  searchFilters?: SearchFilters;
  searchResult?: SearchResult;
  onChangeFilter?: React.Dispatch<React.SetStateAction<SearchFilters>>;
}
const range = (start: number, end: number): number[] =>
  Array.from(Array(end - start + 1).keys()).map(x => x + start);

export const DealerSearchFilters = (props: DealerSearchFiltersProps) => {
  const { t } = useTranslation();

  const searchFilters = props.searchFilters;

  let defaultValuePrice = '';
  if (searchFilters?.priceFrom && searchFilters?.priceTo) {
    defaultValuePrice = `${searchFilters.priceFrom},${searchFilters.priceTo}`;
  }
  const defaultValueYear = searchFilters?.year
    ? searchFilters.year.toString()
    : '';
  const defaultValueMake = searchFilters?.make
    ? searchFilters.make.toString()
    : '';

  const [filterMake, setFilterMake] = useState<string>(defaultValueMake);
  const [filterPrice, setFilterPrice] = useState<string>(defaultValuePrice);
  const [filterYear, setFilterYear] = useState<string>(defaultValueYear);

  const handleSetFilters = () => {
    if (props.onChangeFilter) {
      const filter: SearchFilters = {};

      if (filterMake) {
        filter.make = filterMake;
      }
      if (filterPrice) {
        const price = filterPrice.split(',');
        filter.priceFrom = +price[0];
        filter.priceTo = price[1] !== '' ? +price[1] : undefined;
      }
      if (filterYear) {
        filter.year = parseInt(filterYear);
      }

      props.onChangeFilter(filter);
    }
  };

  const MakeOptions = useMemo(() => {
    const makeOptions = props.makes.map(
      m =>
        ({
          label: m.label,
          value: m.value,
        } as SimpleInputSelectItem),
    );

    makeOptions.unshift({
      label: 'Alle mærker',
      value: '',
    } as SimpleInputSelectItem);
    return makeOptions;
  }, [props.makes]);

  const YearOptions = useMemo(() => {
    const years = range(1900, new Date().getFullYear());
    let yearOptions = years.map(
      m =>
        ({
          label: m.toString(),
          value: m.toString(),
        } as SimpleInputSelectItem),
    );
    yearOptions.reverse();
    yearOptions.unshift({
      label: 'Alle årgange',
      value: '',
    } as SimpleInputSelectItem);
    return yearOptions;
  }, []);

  const PriceOptions = useMemo(() => {
    const priceRanges = [
      { from: 0, to: 10000 },
      { from: 10000, to: 20000 },
      { from: 20000, to: 35000 },
      { from: 35000, to: 50000 },
      { from: 50000, to: 75000 },
      { from: 75000, to: 100000 },
      { from: 100000, to: 150000 },
      { from: 150000, to: 200000 },
      { from: 200000, to: 250000 },
      { from: 250000, to: 300000 },
      { from: 300000, to: 400000 },
      { from: 400000, to: 500000 },
      { from: 500000, to: 1000000 },
      { from: 1000000, to: '' },
    ];

    let priceOptions = priceRanges.map(
      m =>
        ({
          label: `${thousandNumberSeperator(m.from)}${
            m.to ? ` - ${thousandNumberSeperator(m.to)} kr.` : ' - kr.'
          }`,
          value: `${m.from},${m.to}`,
        } as SimpleInputSelectItem),
    );
    priceOptions.unshift({
      label: 'Alle priser',
      value: '',
    } as SimpleInputSelectItem);
    return priceOptions;
  }, []);

  return (
    <PadBox>
      <Grid container>
        <Typography
          component="span"
          sx={{ fontSize: { xs: '15px', md: '18px' } }}
          dangerouslySetInnerHTML={setHtml(
            props.amountForSaleText,
          )}></Typography>
        <Grid
          marginTop={0}
          container
          rowSpacing={2}
          columnSpacing={2}
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}>
          <Grid item xs={12} sm={6} md={3}>
            {MakeOptions && (
              <SimpleInputSelect
                selectedValue={MakeOptions.find(o => o.value === filterMake)}
                values={MakeOptions}
                onChanged={value => {
                  setFilterMake(value);
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {PriceOptions && (
              <SimpleInputSelect
                selectedValue={PriceOptions.find(o => o.value === filterPrice)}
                values={PriceOptions}
                onChanged={value => {
                  setFilterPrice(value);
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {YearOptions && (
              <SimpleInputSelect
                selectedValue={YearOptions.find(o => o.value === filterYear)}
                values={YearOptions}
                onChanged={value => {
                  setFilterYear(value);
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {
              <Button
                fullWidth={true}
                color={'secondary'}
                onClick={() => handleSetFilters()}>
                {t('Labels.ShowResults')}
              </Button>
            }
          </Grid>
        </Grid>
      </Grid>
    </PadBox>
  );
};
