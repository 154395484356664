import { BasicCarCardType } from '@/shared/hooks/basic-car-hook';
import { ClassifiedItem } from '@/shared/lib-api';
import { useAppSelector } from '@/shared/store/hooks';
import { LoadingState } from '@/shared/util/general-helpers';
import { chunk } from '@/shared/util/helpers/general-helper';
import { DidNotFindResults } from '@/web/views/components/SRP/did-not-find-results';
import { ReceiveNewAdsInYourMail } from '@/web/views/components/SRP/receive-new-ads-in-your-mail';
import { Grid } from '@mui/material';
import { default as React, useMemo } from 'react';
import { NoResultsSrp } from './no-results-srp';
import { SRPListModeResults } from './srp-list-mode-results';

export const SRPListMode = (props: { loadingState: LoadingState }) => {
  const searchResults = useAppSelector(state => state.srpReducer.searchData);

  const searchResultDatas = useMemo<ClassifiedItem[][]>(() => {
    if (searchResults?.items) {
      return chunk(searchResults.items, 6);
    }
    return [];
  }, [searchResults]);
  return (
    <>
      <SRPListModeResults
        items={
          props.loadingState === LoadingState.Loading
            ? undefined
            : searchResultDatas[0] ?? []
        }
      />
      {searchResultDatas && searchResultDatas.length > 1 && (
        <>
          <Grid marginBottom={{ xs: '12px', sm: 2 }} item xs={12}>
            <ReceiveNewAdsInYourMail />
          </Grid>
          <SRPListModeResults
            items={
              props.loadingState === LoadingState.Loading
                ? undefined
                : searchResultDatas[1] ?? []
            }
          />
        </>
      )}
      {searchResultDatas.length === 0 &&
        props.loadingState !== LoadingState.Loading && (
          <NoResultsSrp origin={BasicCarCardType.SRPList} />
        )}
      {searchResultDatas.length > 0 && (
        <Grid item xs={12}>
          <DidNotFindResults />
        </Grid>
      )}
    </>
  );
};
