/* eslint-disable @next/next/no-img-element */
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  SxProps,
  Typography,
} from '@mui/material';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import leftArrowImage from '@/shared/icons/left-arrow-image.svg';
import rightArrowImage from '@/shared/icons/right-arrow-image.svg';
import volumeHighIcon from '@/shared/icons/volume-high.svg';
import volumeXmarkIcon from '@/shared/icons/volume-xmark.svg';
import galleryIcon from '@/shared/icons/gallery.svg';
import placeholderImage from '@/shared/images/placeholder-image.svg';
import { cursorPointer } from '@/shared/util/helpers/general-helper';
import { Colors } from '@/shared/util/colors';
import { getClassifiedVipGalleryUrl } from '@/web/util/helpers/url-helpers';
//import Image from '@/web/views/components/image/image';
import Image from './../image/image';

import { useScreenSizeContext } from '@/pages/_app';
import { VipGalleryMobile } from './gallery-mobile';
import { ClassifiedFavorite } from '../default-components/classified/classified-favorite';
import { DialogClose } from '../default-components/dialog/dialog-close';
import { WriteDealerFormModal } from '../default-components/write-dealer-form-modal/write-dealer-form-modal';
import { ClassifiedItem, SalesType, UserProfileType } from '@/shared/lib-api';
import { OpenExternalLink } from '@/web/util/helpers/open-external-link';
import { Facts } from '@/web/views/components/vip/facts';
import { useVipContext } from '@/pages/[headline]/[[...id]]';
import {
  GetFullscreenImage,
  GetMediumDesktopImage,
  GetMediumImage,
  GetMediumMobileImage,
  GetSmallImage,
} from '@/shared/util/helpers/image-helper';

const _renderVideo = (isMuted, isMobile, item) => {
  return (
    <video
      src={item.original}
      loop={true}
      muted={isMuted}
      playsInline
      autoPlay={true}
      style={
        isMobile
          ? {
              width: '100%',
              verticalAlign: 'middle',
              marginTop: 'auto',
              marginBottom: 'auto',
              flexShrink: 0,
              minWidth: '100%',
              minHeight: '100%',
              maxWidth: '100%',
              maxHeight: '100%',
            }
          : {}
      }>
      <source type="video/mp4" src={item.original} />
    </video>
  );
};

const visitReCar = e => {
  e.stopPropagation();
  e.preventDefault();
  OpenExternalLink(
    'https://recar.dk/?utm_source=bilhandel&utm_medium=cpc&utm_campaign=billede',
  );
  return false;
};

const _renderImage = (
  isMobile: boolean,
  addTestDriveButton: boolean,
  addReCar: boolean,
  onClickCall: () => void,
  item,
) => {
  if (addReCar) {
    const style = isMobile ? { width: '384px', height: '288px' } : {};
    return (
      <>
        <img
          onClick={visitReCar}
          style={style}
          src={'/content/images/partners/recar-vip-gallery.svg'}
          alt={''}
        />
      </>
    );
  }

  const style = addTestDriveButton ? { filter: 'blur(8px)' } : {};
  return (
    <>
      <Box>
        {addTestDriveButton && (
          <Box
            zIndex={100}
            position={'absolute'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            width={1}
            height={1}>
            <Button
              id={'btn-gallery-dealer-write'}
              onClick={onClickCall}
              sx={{
                lineHeight: '120%',
                color: '#050505',
                backgroundColor: '#E4E6EA',
                whiteSpace: 'nowrap',
              }}
              color={'white'}>
              Vil du prøve bilen? Bestil prøvetur her
            </Button>
          </Box>
        )}
        <Box overflow={'hidden'}>
          <img
            src={isMobile ? item.thumbnail : item.original}
            alt={''}
            style={style}
          />
        </Box>
      </Box>
    </>
  );
};

export const VipGallery = () => {
  const { item } = useVipContext();
  const [isFullscreen] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const refImg = useRef(null);
  const [bigScreenModalOpen, setBigScreenModalOpen] = useState(false);
  const { isMobile } = useScreenSizeContext();
  const [openModal, setOpenModal] = useState(false);

  const galleryItems = useMemo(() => {
    if (!item) return [];

    const images = item.images.map((image, index) => {
      return {
        fullscreen: GetFullscreenImage(image.folderId, image.id),
        original: isMobile
          ? GetMediumMobileImage(
              image.folderId,
              image.id,
              item.owner.profileType,
            )
          : item.systemData.isActive
          ? GetMediumDesktopImage(image.folderId, image.id)
          : GetMediumImage(image.folderId, image.id),
        thumbnail: GetSmallImage(image.folderId, image.id),
        type: 'image',
        alt: item.headLine,
      };
    });

    // Add last image extra time for trade car view (blured image with trade button)
    if (
      item.owner?.profileType === UserProfileType.Company &&
      item.systemData.isSold === false &&
      images.length > 1 &&
      item.systemData.salesType !== SalesType.Engros
    ) {
      images.push(images[images.length - 1]);
    }

    // Add ReCar for engross
    if (
      item.owner?.profileType === UserProfileType.Company &&
      item.systemData.isSold === false &&
      images.length > 1 &&
      item.systemData.salesType === SalesType.Engros
    ) {
      //images.push(images[images.length - 1]);
      images.push(images[images.length - 1]);
    }

    const videos = item.videos.map(video => {
      return {
        fullscreen: video.videoUrl,
        original: video.videoUrl,
        thumbnail: video.videoPosterUrl,
        type: 'video',
        alt: item.headLine,
      };
    });

    return [...videos, ...images];
  }, [item, isMobile]);

  const galleryCssClass: string = useMemo(() => {
    if (isFullscreen) {
      return '';
    }
    return isMobile
      ? ''
      : item.systemData.isActive
      ? 'vip-image-gallery'
      : 'vip-image-gallery vip-image-sold';
  }, [isFullscreen, isMobile, item.systemData.isActive]);

  const handleFullScreen = () => {
    if (item && ((item.images && item.images.length === 0) || !item.images)) {
      return;
    }
    setBigScreenModalOpen(prev => !prev);
  };

  const handleScreenChange = (isFullscreen: boolean) => {
    //setIsFullscreen(isFullscreen);
  };

  const handleToogleMute = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setIsMuted(!isMuted);
  };

  const onClickWrapper = useCallback(
    (
      e: React.MouseEvent<HTMLInputElement, MouseEvent>,
      onClick: React.MouseEventHandler<HTMLElement>,
    ) => {
      e.stopPropagation();
      e.preventDefault();
      onClick(e);
    },
    [],
  );

  const onClickCall = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      setOpenModal(true);
    },
    [],
  );

  const _renderRightNav = (onClick, disabled) => {
    if (isMobile) {
      return <></>;
    }
    return (
      <input
        type="image"
        src={rightArrowImage.src}
        className="image-gallery-icon image-gallery-right-nav"
        disabled={disabled}
        onClick={e => onClickWrapper(e, onClick)}
        style={{ cursor: 'pointer', paddingRight: 0, filter: 'none' }}
      />
    );
  };

  const _renderLeftNav = (onClick, disabled) => {
    if (isMobile) {
      return <></>;
    }
    return (
      <input
        type="image"
        src={leftArrowImage.src}
        className="image-gallery-icon image-gallery-left-nav"
        disabled={disabled}
        onClick={e => onClickWrapper(e, onClick)}
        style={{ cursor: 'pointer', paddingLeft: 0, filter: 'none' }}
      />
    );
  };

  const _renderCustomControls = () => {
    // custom render for fullscreen and index
    if (galleryItems.length === 0) {
      return <></>;
    }

    const currentItemNumber = refImg.current?.getCurrentIndex() + 1;
    const totalItems = galleryItems.length;
    const hasVideos = galleryItems.find(item => {
      return item.type === 'video';
    });

    return (
      <>
        {hasVideos && (
          <Box
            sx={{ display: currentItemNumber === 1 ? 'block' : 'none' }}
            bottom={'0px'}
            right={'0px'}
            position={'absolute'}
            zIndex={100}
            onClick={handleToogleMute}
            style={{ cursor: 'pointer' }}>
            <Box
              marginBottom={2}
              marginRight={2}
              width={58}
              height={46}
              bgcolor={'rgba(255, 255, 255, 0.9)'}
              display={'flex'}
              flexDirection={'row'}
              borderRadius={'4px'}>
              <Box
                height={'100%'}
                minWidth={58}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}>
                {isMuted ? (
                  <Image
                    alt="Lyd til"
                    src={volumeXmarkIcon.src}
                    height={16}
                    width={16}
                    id="img-vip-gallery-unmute"
                    className="unmute"
                  />
                ) : (
                  <Image
                    alt="Lyd fra"
                    src={volumeHighIcon.src}
                    height={16}
                    width={16}
                    id="img-vip-gallery-mute"
                    className="unmute"
                  />
                )}
              </Box>
            </Box>
          </Box>
        )}
        {!isFullscreen && (
          <>
            <Box
              sx={{ display: { xs: 'flex', lg: 'none' } }}
              alignItems={'flex-end'}
              position={'absolute'}
              width={'100%'}
              zIndex={1}
              flexDirection={'column'}>
              <Box
                marginTop={2}
                marginRight={2}
                borderRadius={'4px'}
                bgcolor={'rgba(0, 0, 0, 0.7)'}
                height={32}
                display={'flex'}
                flexDirection={'row'}
                className="vip-gallery-fullscreen">
                <Box
                  sx={cursorPointer}
                  height={'100%'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'left'}
                  paddingLeft={'6px'}>
                  <Image
                    width={16}
                    height={16}
                    alt="Se alle"
                    src={galleryIcon.src}
                  />
                </Box>
                <Box
                  height={'100%'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'right'}
                  paddingX={'6px'}>
                  <Typography
                    fontSize={'12px'}
                    color={'#fff'}
                    minWidth={'23px'}
                    textAlign="right">
                    {currentItemNumber
                      ? `${currentItemNumber}/${totalItems}`
                      : ''}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: { xs: 'flex' },
                justifyContent: { xs: 'flex-start', lg: 'flex-end' },
              }}
              alignItems={'flex-end'}
              position={'absolute'}
              bottom={0}
              right={0}
              zIndex={2}>
              <Box
                marginBottom={2}
                marginRight={2}
                marginLeft={2}
                display={'flex'}>
                <Facts classifiedId={item.id} />
              </Box>
            </Box>
            <Box
              sx={{ display: { xs: 'none', lg: 'flex' } }}
              alignItems={'flex-end'}
              position={'absolute'}
              height={'100%'}
              zIndex={1}>
              <Box
                marginBottom={2}
                marginLeft={2}
                height={32}
                bgcolor={'rgba(0, 0, 0, 0.7)'}
                display={'flex'}
                flexDirection={'row'}
                borderRadius={'4px'}>
                <Box
                  sx={cursorPointer}
                  height={'100%'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'left'}
                  paddingLeft={'6px'}>
                  <Image
                    width={16}
                    height={16}
                    alt="Se alle"
                    src={galleryIcon.src}
                  />
                </Box>
                <Box
                  height={'100%'}
                  minWidth={40}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}>
                  <Typography color={'#fff'} fontSize={'14px'} fontWeight={500}>
                    {`${currentItemNumber}/${totalItems}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </>
    );
  };

  const addTradeButton = (
    item: ClassifiedItem,
    galleryItemCount: number,
    currentIndex: number,
  ) => {
    return (
      galleryItemCount > 1 &&
      currentIndex == galleryItemCount - 1 &&
      item.owner?.profileType === UserProfileType.Company &&
      item.systemData.isSold === false &&
      item.systemData.salesType !== SalesType.Engros
    );
  };

  const addReCar = (
    item: ClassifiedItem,
    galleryItemCount: number,
    currentIndex: number,
  ) => {
    return (
      galleryItemCount > 1 &&
      currentIndex == galleryItemCount - 1 &&
      item.owner?.profileType === UserProfileType.Company &&
      item.systemData.isSold === false &&
      item.systemData.salesType === SalesType.Engros
    );
  };

  const imageGalleryItems = useMemo(
    () =>
      galleryItems.length > 0
        ? galleryItems.map((galleryItem, index) => ({
            fullscreen: galleryItem.fullscreen,
            original: galleryItem.original,
            thumbnail: galleryItem.thumbnail,
            originalAlt: galleryItem.alt,
            renderItem:
              galleryItem.type == 'video'
                ? _renderVideo.bind(galleryItem, isMuted, isMobile)
                : _renderImage.bind(
                    galleryItem,
                    isMobile,
                    addTradeButton(item, galleryItems.length, index),
                    false,
                    onClickCall,
                  ),
          }))
        : [
            {
              fullscreen: `${placeholderImage.src}`,
              original: `${placeholderImage.src}`,
              thumbnail: `${placeholderImage.src}`,
              type: 'image',
            },
          ],
    [item, galleryItems, isMuted, isMobile, onClickCall],
  );

  const sxs: SxProps = {
    minHeight: 10,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 0,
    backgroundColor: Colors.White,

    borderTopRightRadius: {
      xs: 10,
      lg: 0,
    },
    borderBottomLeftRadius: {
      xs: 0,
      lg: 10,
    },
  };

  return (
    <>
      <Grid item xs={12} lg={8} display={'flex'} flexDirection={'row'} sx={sxs}>
        {imageGalleryItems.length > 0 && (
          <Box
            width={1}
            sx={[
              sxs,
              {
                minHeight: {
                  xs: 288,
                  lg: item.systemData.isActive ? 526 : 576,
                },
                overflow: 'clip',
                maxHeight: item.systemData.isActive ? 526 : 576,
                marginY: 'auto',
              },
            ]}
            justifyContent={'center'}
            display={'flex'}>
            {isMobile ? (
              <Link
                href={`${getClassifiedVipGalleryUrl(item.id, item.headLine)}`}>
                <ImageGallery
                  ref={refImg}
                  items={imageGalleryItems}
                  showThumbnails={false}
                  showPlayButton={false}
                  infinite={true}
                  renderRightNav={_renderRightNav}
                  renderLeftNav={_renderLeftNav}
                  renderCustomControls={_renderCustomControls}
                  showFullscreenButton={isFullscreen}
                  onScreenChange={handleScreenChange}
                  showIndex={false}
                  additionalClass={galleryCssClass}
                  lazyLoad={true}
                />
              </Link>
            ) : (
              <>
                <Box style={{ cursor: 'pointer' }} onClick={handleFullScreen}>
                  <ImageGallery
                    ref={refImg}
                    items={imageGalleryItems}
                    showThumbnails={false}
                    showPlayButton={false}
                    infinite={true}
                    renderRightNav={_renderRightNav}
                    renderLeftNav={_renderLeftNav}
                    renderCustomControls={_renderCustomControls}
                    showFullscreenButton={isFullscreen}
                    onScreenChange={handleScreenChange}
                    showIndex={false}
                    additionalClass={galleryCssClass}
                    lazyLoad={true}
                  />
                </Box>
                <Dialog
                  disableRestoreFocus
                  open={bigScreenModalOpen}
                  onClose={() => setBigScreenModalOpen(false)}
                  scroll="paper"
                  maxWidth={'lg'}>
                  <DialogTitle style={{ padding: 16 }}>
                    <Box
                      display="flex"
                      width={'100%'}
                      justifyContent={'flex-end'}
                      alignItems={'center'}>
                      <Box flexGrow={1}>
                        <Typography>{item.headLine}</Typography>
                      </Box>
                      <Box display="flex">
                        <ClassifiedFavorite item={item} />
                        <Box marginLeft={4}>
                          <DialogClose
                            onClose={() => setBigScreenModalOpen(false)}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </DialogTitle>
                  <DialogContent style={{ padding: 16, paddingTop: 0 }}>
                    <VipGalleryMobile />
                  </DialogContent>
                </Dialog>
              </>
            )}
          </Box>
        )}
      </Grid>

      <WriteDealerFormModal
        close={() => setOpenModal(false)}
        open={openModal}
        classified-item={item}
        callOptionInitialChecked={false}
        testDriveOptionInitialChecked={true}
        tradeOptionInitialChecked={false}
      />
    </>
  );
};
