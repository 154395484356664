import React from 'react';
import dynamic from 'next/dynamic';
import { DefaultMapProps } from '@/web/views/components/default-components/map/default-map';
import { isCoalesceSupported } from '@/shared/util/general-helpers';

const Map = dynamic(
  () => import('@/web/views/components/default-components/map/default-map'),
  { ssr: false },
);

const DefaultMapWrapper = ({
  markerPosition,
  markerRadius,
  borderBottomRadius,
  borderTopRadius,
}: DefaultMapProps) => {
  return (
    <>
      {isCoalesceSupported() && (
        <Map
          markerPosition={markerPosition}
          markerRadius={markerRadius}
          borderBottomRadius={borderBottomRadius}
          borderTopRadius={borderTopRadius}
        />
      )}
    </>
  );
};

export default DefaultMapWrapper;
