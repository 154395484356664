/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IApiContentRouteModel,
    IApiContentRouteModelFromJSON,
    IApiContentRouteModelFromJSONTyped,
    IApiContentRouteModelToJSON,
} from './IApiContentRouteModel';
import {
    LinkTypeModel,
    LinkTypeModelFromJSON,
    LinkTypeModelFromJSONTyped,
    LinkTypeModelToJSON,
} from './LinkTypeModel';

/**
 * 
 * @export
 * @interface ApiLinkModel
 */
export interface ApiLinkModel {
    /**
     * 
     * @type {string}
     * @memberof ApiLinkModel
     */
    readonly url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiLinkModel
     */
    readonly queryString?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiLinkModel
     */
    readonly title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiLinkModel
     */
    readonly target?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiLinkModel
     */
    readonly destinationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiLinkModel
     */
    readonly destinationType?: string | null;
    /**
     * 
     * @type {IApiContentRouteModel}
     * @memberof ApiLinkModel
     */
    route?: IApiContentRouteModel;
    /**
     * 
     * @type {LinkTypeModel}
     * @memberof ApiLinkModel
     */
    linkType?: LinkTypeModel;
}

export function ApiLinkModelFromJSON(json: any): ApiLinkModel {
    return ApiLinkModelFromJSONTyped(json, false);
}

export function ApiLinkModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiLinkModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': !exists(json, 'url') ? undefined : json['url'],
        'queryString': !exists(json, 'queryString') ? undefined : json['queryString'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'target': !exists(json, 'target') ? undefined : json['target'],
        'destinationId': !exists(json, 'destinationId') ? undefined : json['destinationId'],
        'destinationType': !exists(json, 'destinationType') ? undefined : json['destinationType'],
        'route': !exists(json, 'route') ? undefined : IApiContentRouteModelFromJSON(json['route']),
        'linkType': !exists(json, 'linkType') ? undefined : LinkTypeModelFromJSON(json['linkType']),
    };
}

export function ApiLinkModelToJSON(value?: ApiLinkModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'route': IApiContentRouteModelToJSON(value.route),
        'linkType': LinkTypeModelToJSON(value.linkType),
    };
}

