import React, { useEffect, useState } from 'react';
import { useBasicCar } from '@/shared/hooks/basic-car-hook';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@/shared/hooks/auth-hook';
import { ClassifiedItem } from '@/shared/lib-api';
import { Box } from '@mui/material';

import notFavoriteIcon from '@/shared/icons/favorite/not_favorite.svg';
import isFavoriteIcon from '@/shared/icons/favorite/is_favorite.svg';
import hoverFavorite from '@/shared/icons/favorite/hover_favorite.svg';
import Image from '@/web/views/components/image/image';
import { LoadingState } from '@/shared/util/general-helpers';

interface BasicCarFavoriteProps {
  item?: ClassifiedItem;
}
export const BasicCarFavorite = (props: BasicCarFavoriteProps) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuth();
  const [initialLoggedInState, setInitialLoggedInState] =
    useState<boolean>(false);
  const [isHovering, setIsHovered] = useState(false);

  const onMouseEnter = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);

  const {
    loginAndLike: loginAndLike,
    addLike,
    removeLike,
    isCurrentlyLiked,
    loading,
  } = useBasicCar({
    item: props.item,
  });

  useEffect(() => {
    setInitialLoggedInState(isLoggedIn);
  }, [isLoggedIn]);

  const handleFavorite = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // if loggedin => toggle favorite
    // if not loggedin => always add
    e.preventDefault();
    e.stopPropagation();
    if (initialLoggedInState) {
      if (isCurrentlyLiked) {
        removeLike();
      } else {
        addLike();
      }
    } else {
      loginAndLike();
    }
    setIsHovered(false);
  };

  return (
    <Box
      height={'40px'}
      width={'40px'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      sx={{ cursor: 'pointer' }}
      onClick={loading === LoadingState.Loading ? () => {} : handleFavorite}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      {isCurrentlyLiked ? (
        <>
          <Image
            width={40}
            height={40}
            alt={t('Labels.Saved')}
            title={t('Labels.Saved')}
            src={isFavoriteIcon.src}
          />
        </>
      ) : isHovering ? (
        <Image
          width={40}
          height={40}
          alt={t('Labels.Save')}
          title={t('Labels.Save')}
          src={hoverFavorite.src}
        />
      ) : (
        <Image
          width={40}
          height={40}
          alt={t('Labels.Save')}
          title={t('Labels.Save')}
          src={notFavoriteIcon.src}
        />
      )}
    </Box>
  );
};
