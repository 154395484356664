/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FactPropertiesModel
 */
export interface FactPropertiesModel {
    /**
     * 
     * @type {string}
     * @memberof FactPropertiesModel
     */
    facts?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactPropertiesModel
     */
    headline?: string | null;
}

export function FactPropertiesModelFromJSON(json: any): FactPropertiesModel {
    return FactPropertiesModelFromJSONTyped(json, false);
}

export function FactPropertiesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FactPropertiesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'facts': !exists(json, 'facts') ? undefined : json['facts'],
        'headline': !exists(json, 'headline') ? undefined : json['headline'],
    };
}

export function FactPropertiesModelToJSON(value?: FactPropertiesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'facts': value.facts,
        'headline': value.headline,
    };
}

