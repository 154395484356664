import { Dealer, SeoUrl } from '@/shared/lib-api';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

const DealerHead = (props: { dealer: Dealer; seoData: SeoUrl }) => {
  const router = useRouter();
  const dealer = props.dealer;

  const descriptionText = useMemo(() => {
    if (props.seoData && props.seoData.metaDescription) {
      return props.seoData.metaDescription;
    }
    if (dealer && dealer.address && dealer.zipCode && dealer.city) {
      return `Biler til salg på Bilhandel ✓ ${dealer.name}, ${dealer.address}, ${dealer.zipCode} ${dealer.city}`;
    }
    return null;
  }, [dealer, props.seoData]);

  const titleText = useMemo(() => {
    if (props.seoData && props.seoData.title) {
      return props.seoData.title;
    }
    return dealer
      ? `Køb bil hos ${dealer.name} - ${dealer.city} - [Biler til salg] | Bilhandel`
      : null;
  }, [dealer, props.seoData]);

  const imageUrl = useMemo(() => {
    return dealer?.imageUrl ? dealer.imageUrl : null;
  }, [dealer]);

  const url = useMemo(() => {
    return 'https://bilhandel.dk' + router.asPath.split('?')[0];
  }, [router]);

  if (!dealer) {
    return null;
  }

  return (
    <Head>
      {titleText && (
        <>
          <title key="title">{titleText}</title>
        </>
      )}
      {descriptionText && (
        <>
          <meta
            name="description"
            content={descriptionText}
            key="meta-description"
          />
          <meta
            property="og:description"
            content={descriptionText}
            key="og-description"
          />
        </>
      )}
      {url && (
        <>
          <link rel="canonical" href={url} key="link-url" />
          <meta property="og:url" content={url} key="og-url" />
        </>
      )}
      {imageUrl && (
        <>
          <meta property="og:image" content={imageUrl} key="og-image" />
          <meta
            property="og:image:secure_url"
            content={imageUrl}
            key="og-image-secure_url"
          />
          <meta property="og:image:width" content="1440" key="og-image-width" />
          <meta
            property="og:image:height"
            content="1080"
            key="og-image-height"
          />
        </>
      )}

      <meta property="og:type" content="business.business" key="og-type" />
      <meta property="business:contact_data:country_name" content="Denmark" />
      {dealer?.name && (
        <meta property="og:title" content={dealer.name} key="og-title" />
      )}
      {dealer?.address && (
        <meta
          property="business:contact_data:street_address"
          content={dealer.address}
        />
      )}
      {dealer?.city && (
        <meta property="business:contact_data:locality" content={dealer.city} />
      )}
      {dealer?.zipCode && (
        <meta
          property="business:contact_data:postal_code"
          content={dealer.zipCode.toString()}
        />
      )}
    </Head>
  );
};

export default DealerHead;
