/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FaqPropertiesModel,
    FaqPropertiesModelFromJSON,
    FaqPropertiesModelFromJSONTyped,
    FaqPropertiesModelToJSON,
} from './FaqPropertiesModel';
import {
    IApiElementModel,
    IApiElementModelFromJSON,
    IApiElementModelFromJSONTyped,
    IApiElementModelToJSON,
} from './IApiElementModel';

/**
 * 
 * @export
 * @interface FaqElementModel
 */
export interface FaqElementModel extends IApiElementModel {
    /**
     * 
     * @type {FaqPropertiesModel}
     * @memberof FaqElementModel
     */
    properties?: FaqPropertiesModel;
}

export function FaqElementModelFromJSON(json: any): FaqElementModel {
    return FaqElementModelFromJSONTyped(json, false);
}

export function FaqElementModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FaqElementModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...IApiElementModelFromJSONTyped(json, ignoreDiscriminator),
        'properties': !exists(json, 'properties') ? undefined : FaqPropertiesModelFromJSON(json['properties']),
    };
}

export function FaqElementModelToJSON(value?: FaqElementModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...IApiElementModelToJSON(value),
        'properties': FaqPropertiesModelToJSON(value.properties),
    };
}

