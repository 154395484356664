import {
  ClassifiedItem,
  SearchPageAttribute,
  SearchPageOptions,
} from '@/shared/lib-api';
import { useAppDispatch } from '@/shared/store/hooks';
import { thousandNumberSeperator } from '@/shared/util/general-helpers';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Box, Button, Chip, Paper, styled, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useVipContext } from '@/pages/[headline]/[[...id]]';
import { useLoginOrCreatedEvent } from '@/shared/hooks/login-hook';
import { useSearchAgent } from '@/shared/hooks/search-agent-hook';
import { SetSrpSearchOptions } from '@/shared/store/reducers/SRPSlice';
import { Colors } from '@/shared/util/colors';
import { slugifyOptions } from '@/web/util/helpers/url-helpers';
import { toast } from 'react-toastify';
import slugify from 'slugify';
import { PadBox } from '../containers/pad-box';

interface Chips {
  category: SearchPageAttribute;

  makeFilterEnabled: boolean;
  makeText: string;
  make: SearchPageAttribute;
  model?: SearchPageAttribute;

  yearFilterEnabled: boolean;
  yearText?: string;
  yearFrom?: SearchPageAttribute;
  yearTo?: SearchPageAttribute;

  mileageFilterEnabled: boolean;
  mileageText?: string;
  mileageFrom?: SearchPageAttribute;
  mileageTo?: SearchPageAttribute;
}

const Headline = styled(Typography)({
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '125%',
});

const Message = styled(Typography)({
  fontSize: '15px',
  lineHeight: '25px',
});

const ShowSimilar = styled(Button)({
  fontSize: '15px',
  fontWeight: 700,
  height: '44px',
  whiteSpace: 'nowrap',
});

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const convertChipsToSearchPageOptions = (chips: Chips): SearchPageOptions => {
  let options: SearchPageOptions = {
    category: { label: '', value: '' }, // chips.category, // seems to be required
  };

  if (chips.makeFilterEnabled) {
    options.makeModelVariantOptions = [
      {
        brand: chips.make,
        model: chips.model,
        variant: '',
      },
    ];
  }

  if (chips.yearFilterEnabled) {
    options.yearFrom = chips.yearFrom;
    options.yearTo = chips.yearTo;
  }

  if (chips.mileageFilterEnabled) {
    options.milageFrom = chips.mileageFrom;
    options.milageTo = chips.mileageTo;
  }

  return options;
};
const convertClassifiedItemToChips = (item: ClassifiedItem): Chips => {
  let chips: Chips = {
    category: {
      label: item.systemData.category,
      value: slugify(item.systemData.category, slugifyOptions),
    },
    makeText: item.make,
    make: { label: item.make, value: slugify(item.make, slugifyOptions) },
    makeFilterEnabled: true,
    yearFilterEnabled: false,
    mileageFilterEnabled: false,
  };

  if (item.model) {
    chips.makeText = `${item.make} ${item.model}`;
    chips.model = {
      label: item.model,
      value: slugify(item.model, slugifyOptions),
    };
  }

  if (item.yearOfManufacture) {
    const from = item.yearOfManufacture - 1;
    const to = item.yearOfManufacture + 1;

    chips.yearText = `${from} - ${to}`;
    chips.yearFrom = { label: from.toString(), value: from.toString() };
    chips.yearTo = { label: to.toString(), value: to.toString() };
    chips.yearFilterEnabled = true;
  }

  if (item.mileage) {
    let from: number;
    let to: number;

    if (item.mileage === 0) {
      from = 0;
      to = 50000;
    } else {
      const rounded = item.mileage / 50000;
      from = rounded >= 4 ? 200000 : Math.floor(rounded) * 50000;
      to = rounded >= 4 ? null : from + 50000;
    }

    chips.mileageText = to
      ? `${thousandNumberSeperator(from)} - ${thousandNumberSeperator(to)} km`
      : `${thousandNumberSeperator(from)} km -`;
    chips.mileageFrom = {
      label: thousandNumberSeperator(from),
      value: from.toString(),
    };

    chips.mileageTo = {
      label: to === null ? undefined : thousandNumberSeperator(to),
      value: to === null ? '' : to.toString(),
    };

    chips.mileageFilterEnabled = true;
  }

  return chips;
};

export const VipCreateSearchAgentCard = () => {
  const { add } = useSearchAgent();
  const { t } = useTranslation();
  const { item } = useVipContext();
  const dispatch = useAppDispatch();

  const [chipData, setChipData] = React.useState<Chips>(
    convertClassifiedItemToChips(item),
  );
  const [saved] = React.useState<boolean>(false);

  useEffect(() => {
    const chips = convertClassifiedItemToChips(item);
    setChipData(chips);
  }, [item]);

  useEffect(() => {
    if (chipData) {
      const options: SearchPageOptions =
        convertChipsToSearchPageOptions(chipData);
      dispatch(SetSrpSearchOptions(options));
    }
  }, [chipData, dispatch]);

  const showSearchAgentSaved = () =>
    toast.success(
      <Trans
        i18nKey="SRP.SavedSearchNotificationSaved"
        components={{
          u: <u />,
          a: <a style={{ color: '#1066B6', textDecoration: 'underline' }} />,
        }}
      />,
      {
        autoClose: 5000,
      },
    );

  const saveAfterLogin = useCallback(async () => {
    const options: SearchPageOptions =
      convertChipsToSearchPageOptions(chipData);

    add(options).then(() => {
      showSearchAgentSaved();
    });
  }, [chipData, add]);

  const { loginOrCreate } = useLoginOrCreatedEvent(saveAfterLogin);

  const handleDisableMakeFilter = () => {
    setChipData(preState => ({ ...preState, makeFilterEnabled: false }));
  };

  const handleDisableYearFilter = () => {
    setChipData(preState => ({ ...preState, yearFilterEnabled: false }));
  };

  const handleDisableMilageFilter = () => {
    setChipData(preState => ({ ...preState, mileageFilterEnabled: false }));
  };

  return (
    <PadBox>
      {chipData && (
        <>
          <Headline>{t('Vip.CreateSearchAgent.Title')}</Headline>
          <Message marginTop={1}>
            {t('Vip.CreateSearchAgent.SubTitle', {
              makeAndModel: `${item.make} ${item.model}`,
            })}
          </Message>
          <Box marginTop={1}>
            <Paper
              sx={{
                display: 'flex',
                justifyContent: 'start',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0,
                m: 0,
                background: 'none',
              }}
              component="ul">
              {chipData.makeFilterEnabled && (
                <ListItem>
                  <Chip
                    disabled={saved}
                    size="small"
                    sx={{ bgcolor: Colors.Background.Cms, color: '#302626' }}
                    label={chipData.makeText}
                    deleteIcon={<ClearIcon sx={{ height: '15px' }} />}
                    onDelete={handleDisableMakeFilter}
                  />
                </ListItem>
              )}
              {chipData.yearFilterEnabled && (
                <ListItem>
                  <Chip
                    disabled={saved}
                    size="small"
                    sx={{ bgcolor: Colors.Background.Cms, color: '#302626' }}
                    label={chipData.yearText}
                    deleteIcon={<ClearIcon sx={{ height: '15px' }} />}
                    onDelete={handleDisableYearFilter}
                  />
                </ListItem>
              )}
              {chipData.mileageFilterEnabled && (
                <ListItem>
                  <Chip
                    disabled={saved}
                    size="small"
                    sx={{
                      bgcolor: Colors.Background.Cms,
                      color: '#302626',
                    }}
                    label={chipData.mileageText}
                    deleteIcon={<ClearIcon sx={{ height: '15px' }} />}
                    onDelete={handleDisableMilageFilter}
                  />
                </ListItem>
              )}
            </Paper>
          </Box>

          {(chipData.makeFilterEnabled ||
            chipData.yearFilterEnabled ||
            chipData.mileageFilterEnabled) && (
            <>
              <Box marginTop={1.5}>
                <ShowSimilar
                  href="#"
                  color="secondary"
                  style={{
                    backgroundColor: 'transparent',
                    color: Colors.Secondary,
                    border: `1px solid ${Colors.Secondary}`,
                  }}
                  fullWidth={true}
                  disabled={saved}
                  startIcon={
                    saved ? (
                      <CheckIcon />
                    ) : (
                      <NotificationsActiveIcon
                        sx={{ transform: 'rotate(-20deg)' }}
                      />
                    )
                  }
                  onClick={loginOrCreate}>
                  {saved
                    ? t('Vip.SearchSaved')
                    : t('Vip.CreateSearchAgent.OnClickText')}
                </ShowSimilar>
              </Box>
            </>
          )}
        </>
      )}
    </PadBox>
  );
};
