import React, { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { OpenExternalLink } from '@/web/util/helpers/open-external-link';
import { Colors } from '@/shared/util/colors';
import { CmsSrpBottomBlockDto } from '@/shared/lib-api';
import { useScreenSizeContext } from '@/pages/_app';

const SrpBottomCard: FC<{ info: CmsSrpBottomBlockDto }> = props => {
  const { isMobile } = useScreenSizeContext();
  const handleOnClick = () => {
    let link = props.info.linkTo;
    if (!link.includes('https://')) {
      link = 'https://' + link;
    }
    OpenExternalLink(link);
  };
  if (props.info.logo === undefined) {
    return null;
  }

  return (
    <>
      <Grid
        id="bottom-srp-card"
        container
        borderRadius={2.5}
        bgcolor={Colors.Background.TableHead}
        paddingX={3}
        paddingY={2}
        rowGap={3}>
        <Grid
          item
          md={4}
          xs={12}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{ justifyContent: { xs: 'flex-start', md: 'center' } }}
          marginY={1}
          paddingRight={3}>
          <img
            alt="Logo"
            id="bottom-srp-card-img"
            src={
              isMobile
                ? props.info.logo?.pictureUrlCroppedRect
                : props.info.logo?.pictureUrl
            }
            style={{ cursor: 'pointer', maxWidth: '130px' }}
            onClick={handleOnClick}
          />
        </Grid>
        <Grid item md={8} xs={12}>
          <Typography fontWeight={700} fontSize={18}>
            {props.info.headline}
          </Typography>

          <Typography
            fontWeight={400}
            fontSize={14}
            marginTop={'12px'}
            lineHeight={'24.5px'}>
            {props.info.text}
          </Typography>

          <Box>
            <Typography
              id="bottom-srp-card-text"
              color={'#1066B6'}
              fontWeight={600}
              fontSize={14}
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              marginTop={'12px'}
              onClick={handleOnClick}>
              {props.info.linkText}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SrpBottomCard;
