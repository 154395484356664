import React, { useCallback, useMemo } from 'react';
import { Box, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PadBox } from '../containers/pad-box';
import { VipHeadline } from './headline';
import { useRouter } from 'next/router';
import { useClassifiedActions } from '@/shared/util/helpers/my-ads-helper';
import { EditIcon } from '../default-components/input/input-icons/editIcon';
import { DeleteIcon } from '../default-components/input/input-icons/deleteIcon';
import { Colors } from '@/shared/util/colors';
import { useVipContext } from '@/pages/[headline]/[[...id]]';

export const VipOwnerActionsCard = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { item } = useVipContext();
  const { deleteCar, setCarActive, setCarInactive } =
    useClassifiedActions(true);

  const isActive = useMemo(() => {
    return item?.systemData.isActive;
  }, [item]);

  const onToggleActivity = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (isActive) {
        setCarInactive(item?.id);
      } else {
        setCarActive(item?.id);
      }
    },
    [item, isActive, setCarActive, setCarInactive],
  );

  const onDelete = useCallback(() => {
    deleteCar(item?.id);
  }, [item, deleteCar]);

  const onEdit = useCallback(() => {
    router.push(`/opdater-annonce/${item?.id}`);
  }, [router, item]);
  if (!item) {
    return null;
  }
  return (
    <PadBox bgColor={Colors.Yellow}>
      <VipHeadline
        headline={
          isActive
            ? t('Labels.ClassifiedIsActive')
            : t('Labels.ClassifiedIsNotActive')
        }
      />
      <Box marginTop={2} display={'flex'} flexDirection={'row'}>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <Box display="flex">
            <Switch onChange={onToggleActivity} checked={isActive} />
            <Typography paddingLeft={1}>
              {isActive
                ? t('MyBilhandel.Ads.AdActive')
                : t('MyBilhandel.Ads.AdInactive')}
            </Typography>
          </Box>
          <Box
            paddingLeft={2}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}>
            <EditIcon onEdit={onEdit} loading={false} />
          </Box>
          <Box paddingLeft={2} paddingRight={2}>
            <DeleteIcon onDelete={onDelete} loading={false} />
          </Box>
        </Box>
      </Box>
    </PadBox>
  );
};
