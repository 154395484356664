import { Box, Grid } from '@mui/material';
import Image from '@/web/views/components/image/image';
import galleryIcon from '@/shared/icons/gallery-icon.svg';
import listIcon from '@/shared/icons/list-icon.svg';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Colors } from '@/shared/util/colors';
import SortSrp from './sort-srp';
import { useScreenSizeContext } from '@/pages/_app';
import { SrpViewMode } from '@/shared/hooks/srp-hook';

export const SelectTopBarSRP: React.FC<{
  viewMode: SrpViewMode;
  setViewMode: Dispatch<SetStateAction<SrpViewMode>>;
}> = ({ viewMode, setViewMode }) => {
  const selectList = useCallback(() => {
    setViewMode(SrpViewMode.List);
  }, [setViewMode]);
  const selectGallery = useCallback(() => {
    setViewMode(SrpViewMode.Gallery);
  }, [setViewMode]);
  const { isMobile } = useScreenSizeContext();
  return (
    <Grid
      container
      marginBottom={{ sm: 2 }}
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}>
      <Grid item xs={12} sm="auto">
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={isMobile ? 'space-between' : 'initial'}>
          <Box
            style={{ cursor: 'pointer' }}
            borderRadius={'3px'}
            bgcolor={
              viewMode === SrpViewMode.List
                ? Colors.White
                : Colors.Background.Default
            }
            width={90}
            height={38}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            onClick={selectList}>
            <Image
              alt="listevisning"
              height={23}
              width={73}
              loading={'lazy'}
              src={listIcon.src}
            />
          </Box>
          <Box
            style={{ cursor: 'pointer' }}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            borderRadius={'3px'}
            bgcolor={
              viewMode === SrpViewMode.Gallery
                ? Colors.White
                : Colors.Background.Default
            }
            width={90}
            height={38}
            onClick={selectGallery}
            marginLeft={1}>
            <Image
              alt="gittervisning"
              height={23}
              width={73}
              loading={'lazy'}
              src={galleryIcon.src}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm="auto">
        <SortSrp />
      </Grid>
    </Grid>
  );
};
