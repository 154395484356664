import { useScreenSizeContext } from '@/pages/_app';
import { useSearchForm } from '@/shared/hooks/search-form-field-hook';
import { useAppSelector } from '@/shared/store/hooks';
import { Colors } from '@/shared/util/colors';
import { Box, Pagination, PaginationItem } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo } from 'react';

const paginationStyle: React.CSSProperties = {};

const paginationItemStyle: React.CSSProperties = {
  paddingTop: '2px',
  fontSize: 14,
  margin: '0 4px',
};

const paginationItemSelectedBorderStyle = `1px solid ${Colors.DarkerBlue}`;
const paginationItemNextPrevBackgroundStyle = Colors.DarkerBlue;
const paginationItemNextPrevColorStyle = Colors.White;

const urls = [
  'alle-biler',
  'autocampere',
  'biler-uden-afgift',
  'brugte-biler',
  'busser',
  'lastbiler',
  'leasing-biler',
  'varevogn-ekskl-moms',
  'varevogn-inkl-moms',
];

export const PaginationSRP = () => {
  const { isMobile } = useScreenSizeContext();
  const { field: pageNumber, onChange: onChangePageNumber } =
    useSearchForm<number>('pageNumber');
  const router = useRouter();

  useEffect(() => {
    router.beforePopState(({ url, as, options }) => {
      if (
        url.split('/').length > 1 &&
        (!urls.includes(url.replace('/s', '').split('/')[1]) ||
          !url.includes('/s/'))
      ) {
        return true;
      }

      let pathComponents = as.split('?')[0].split('/');
      let result = pathComponents[pathComponents.length - 1].replace(
        'side-',
        '',
      );
      let pageFromUrl = parseInt(result, 10);
      if (isNaN(pageFromUrl)) {
        pageFromUrl = 1;
      }
      onChangePageNumber(pageFromUrl);
      return true;
    });
  }, [router, onChangePageNumber]);

  var { field: pageSize } = useSearchForm<number>('pageSize');

  const handleChange = useCallback(
    (value: number) => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto',
      });
      onChangePageNumber(value);
    },
    [onChangePageNumber],
  );
  const totalResults = useAppSelector(
    state => state.srpReducer.searchData?.totalResults,
  );

  const showPagination = useMemo(() => {
    return totalResults > pageSize;
  }, [totalResults, pageSize]);

  return (
    <>
      {showPagination && (
        <Box
          paddingTop="45px"
          paddingBottom="20px"
          display={'flex'}
          justifyContent={'center'}>
          <Pagination
            siblingCount={isMobile ? 0 : 2}
            style={paginationStyle}
            renderItem={item => {
              let itemStyle = { ...paginationItemStyle };
              if (item.selected) {
                itemStyle = {
                  ...itemStyle,
                  border: paginationItemSelectedBorderStyle,
                };
              }
              if (item.type === 'previous' || item.type === 'next') {
                itemStyle = {
                  ...itemStyle,
                  backgroundColor: paginationItemNextPrevBackgroundStyle,
                  color: paginationItemNextPrevColorStyle,
                };
              }

              if (item.type === 'previous' && item.page === 0) {
                return <PaginationItem style={itemStyle} {...item} />;
              }
              if (
                item.type === 'next' &&
                item.page > Math.ceil(totalResults / pageSize)
              ) {
                return <PaginationItem style={itemStyle} {...item} />;
              }

              if (item.page >= 4) {
                return (
                  <Box
                    onClick={() => {
                      handleChange(item.page);
                    }}>
                    <PaginationItem style={itemStyle} {...item} />
                  </Box>
                );
              }

              return (
                <Link
                  scroll={false}
                  shallow
                  onClick={() => handleChange(item.page)}
                  href={`${router.asPath.split('?')[0].split('/side-')[0]}${
                    item.page === 1 || item.page === null
                      ? ''
                      : `/side-${item.page}`
                  }?${router.asPath.split('?')[1] ?? ''}`}>
                  <PaginationItem style={itemStyle} {...item} />
                </Link>
              );
            }}
            count={Math.ceil(totalResults / pageSize)}
            page={pageNumber}
          />
        </Box>
      )}
    </>
  );
};
