import React, { useCallback, useState } from 'react';
import { Box, Button, Grid, Hidden, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Image from '@/web/views/components/image/image';
import { PadBox } from '../containers/pad-box';
import { VipHeadline } from './headline';
import { WriteDealerFormModal } from '../default-components/write-dealer-form-modal/write-dealer-form-modal';
import fragus from '@/shared/images/fragus.png';
import { OpenExternalLink } from '@/web/util/helpers/open-external-link';
import { Colors } from '@/shared/util/colors';
import { useScreenSizeContext } from '@/pages/_app';
import { useVipContext } from '@/pages/[headline]/[[...id]]';

export const VipFragusCard = () => {
  const { t } = useTranslation();
  const { item } = useVipContext();
  const [openModal, setOpenModal] = useState(false);
  const showModal = useCallback(() => {
    setOpenModal(prevState => !prevState);
  }, []);
  const { isMobile } = useScreenSizeContext();

  return (
    <>
      <PadBox>
        <Grid container>
          <Grid item xs={12} md={true}>
            <VipHeadline headline={t('Labels.Fragus')} />
            <Typography
              fontSize="15px"
              lineHeight="26px"
              marginTop={1}
              whiteSpace="pre-line"
              sx={{ wordBreak: 'break-word' }}>
              {t('Vip.FragusBody')}
            </Typography>
            <Hidden mdDown>
              <Box
                display="flex"
                justifyContent={'space-between'}
                marginTop={1}>
                <Button
                  fullWidth={isMobile}
                  style={{ marginRight: 12 }}
                  onClick={showModal}
                  id="gtm-fragus"
                  color="secondary">
                  {t('Vip.FragusButtonText')}
                </Button>
                <Button
                  onClick={() =>
                    OpenExternalLink(
                      'https://fragus.com/dk/vores-produkter-og-tjenester/gosafe-garantier/?utm_source=bilhandel&utm_medium=integration&utm_campaign=boks_paa_bilvisning',
                    )
                  }
                  fullWidth={isMobile}
                  id="gtm-fragus-read-more"
                  style={{
                    marginRight: 12,
                    backgroundColor: 'transparent',
                    color: Colors.Secondary,
                    border: `1px solid ${Colors.Secondary}`,
                    marginTop: isMobile ? 12 : 0,
                  }}>
                  {t('Vip.FragusButtonText2')}
                </Button>
                <Image alt="Fragus" src={fragus.src} height={43} width={131} />
              </Box>
            </Hidden>
          </Grid>
          <Hidden mdUp>
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 12,
              }}
              item
              xs={12}
              md={'auto'}>
              <Image alt="Fragus" src={fragus.src} height={43} width={131} />
            </Grid>
          </Hidden>
        </Grid>
        <Hidden mdUp>
          <Box marginTop={1}>
            <Button
              fullWidth={isMobile}
              style={{ marginRight: 12 }}
              onClick={showModal}
              id="gtm-fragus"
              color="secondary">
              {t('Vip.FragusButtonText')}
            </Button>
            <Button
              onClick={() =>
                OpenExternalLink(
                  'https://fragus.com/dk/vores-produkter-og-tjenester/gosafe-garantier/?utm_source=bilhandel&utm_medium=integration&utm_campaign=boks_paa_bilvisning',
                )
              }
              fullWidth={isMobile}
              id="gtm-fragus-read-more"
              style={{
                backgroundColor: 'transparent',
                color: Colors.Secondary,
                border: `1px solid ${Colors.Secondary}`,
                marginTop: isMobile ? 12 : 0,
              }}>
              {t('Vip.FragusButtonText2')}
            </Button>
          </Box>
        </Hidden>
      </PadBox>
      <WriteDealerFormModal
        close={showModal}
        open={openModal}
        classified-item={item}
      />
    </>
  );
};
