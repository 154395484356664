/* eslint-disable @next/next/no-img-element */
import React, { useCallback } from 'react';
import { Box, Grid, Link, styled, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { UserProfileType } from '@/shared/lib-api';
import { getDealerVipUrl } from '@/web/util/helpers/url-helpers';
import dealerLocationIcon from '@/shared/icons/dealer-location-icon.svg';
import dealerPhoneIcon from '@/shared/icons/dealer-phone-icon.svg';
import { VipTodaysOpeningHours } from './todays-opening-hours';
import { PadBox } from '../containers/pad-box';
import DefaultMapWrapper from '../default-components/map/default-map-wrapper';
import Dbfu from '@/shared/icons/dbfu-logo-2-blue.png';
import Autotjek from '@/shared/icons/autotjek-logo.png';
import { VipWriteToDealer } from './write-to-dealer';
import { VipHeadline } from './headline';
import { Colors } from '@/shared/util/colors';
import Image from '@/web/views/components/image/image';
import ClassificationLimitWrapper from '../containers/classification-limit-wrapper';
import { useRouter } from 'next/router';
import { useVipContext } from '@/pages/[headline]/[[...id]]';
import Codan from '@/shared/icons/partner/codan.png';
import Fragus from '@/shared/icons/partner/gosafe.svg';
import Ok from '@/shared/icons/partner/ok-logo.svg';
import Santander from '@/shared/icons/partner/banco-santander-logo.svg';
import Clever from '@/shared/icons/partner/clever.svg';
import Cargarantie from '@/shared/icons/partner/logo_cargarantie.png';

const GoogleMapsContainter = styled(Box)({
  position: 'absolute',
  zIndex: 1000,
  backgroundColor: 'white',
  marginLeft: 'auto',
  marginRight: 'auto',
  right: '20px',
  bottom: '20px',
  fontSize: '16px',
  borderRadius: '3px',
});

const GoogleMapsLink = styled(Link)({
  fontSize: '16px',
  fontWeight: '700',
  cursor: 'pointer',
  padding: '11px 18px 10px 18px',
  display: 'inline-block',
  textDecoration: 'none',
  color: Colors.Black,
});

const Badge = styled(Typography)({
  borderRadius: '2px',
  marginLeft: '15px',
  paddingLeft: '8px',
  paddingRight: '8px',
  backgroundColor: '#E1EAED',
  fontSize: '12px',
});

const MapContainer = styled(Box)({
  position: 'relative',
});

const MapMapContainer = styled(Box)({
  width: '100%',
  height: '285px',
  marginTop: '-16px',
  zIndex: 2,
});

export const VipDealerMapCardXs = () => {
  const { item } = useVipContext();
  const { t } = useTranslation();
  const router = useRouter();
  const goToDbfu = useCallback(() => {
    router.push('/dbfu');
  }, [router]);

  const goToAutotjek = useCallback(() => {
    router.push('/100pct-autotjek');
  }, [router]);

  if (!item?.owner) {
    return <></>;
  }

  const phoneDisplay = () => {
    const part1 = item.owner.phone.substring(0, 2);
    const part2 = item.owner.phone.substring(2, 4);
    const part3 = item.owner.phone.substring(4, 6);
    const part4 = item.owner.phone.substring(6, 8);

    return (
      <span style={{ marginLeft: '2px' }}>
        {/* <span style={{ marginRight: '2px' }}>{part1}</span>
        <span style={{ marginRight: '2px' }}>{part2}</span>
        <span style={{ marginRight: '2px' }}>{part3}</span>
        <span style={{ marginRight: '2px' }}>{part4}</span> */}
        {`${part1} ${part2} ${part3} ${part4}`}
      </span>
    );
  };

  return (
    <>
      <PadBox>
        <Box display="flex" flexDirection="row" alignItems="center">
          <VipHeadline headline={t('Labels.Seller')} />
          <Badge>
            {item.owner.profileType === UserProfileType.Company
              ? t('Labels.Dealer')
              : t('Labels.Private')}
          </Badge>
        </Box>
        {item.owner.imageUrl && (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            width={332}
            height={56}
            style={{
              objectFit: 'scale-down',
              objectPosition: 'left center',
              maxWidth: '100%',
            }}
            loading="lazy"
            alt={item.owner.name}
            src={item.owner.imageUrl}
          />
        )}
        <Box display="flex" flexDirection="row" alignItems="flex-start">
          <Image
            height={20}
            width={20}
            alt="Lokation"
            src={dealerLocationIcon.src}
          />
          <Box paddingLeft={1}>
            <Typography fontWeight="bold">{item.owner.name}</Typography>
            <Typography lineHeight={1.2}>
              {item.owner.address && (
                <>
                  {item.owner.address}
                  <br />
                </>
              )}
              {item.owner.zipCode} {item.owner.city}
            </Typography>
          </Box>
        </Box>
        {item.owner.phone && (
          <Box paddingTop={2} display="flex" flexDirection="row">
            <Image
              width={20}
              height={18}
              alt="Telefonnummer"
              src={dealerPhoneIcon.src}
            />
            <Box paddingLeft={1}>
              <Typography>
                <a
                  href={`tel:${item.owner.phone}`}
                  id={'lnk-vip-xs-map-dealer-call'}
                  className={'dealer-call'}>
                  {phoneDisplay()}
                </a>
              </Typography>
            </Box>
          </Box>
        )}
        {item.owner.profileType === UserProfileType.Company && (
          <>
            <VipTodaysOpeningHours />
            {item.owner.isDBFUMember && (
              <Box
                marginTop={1}
                style={{ cursor: 'pointer' }}
                onClick={goToDbfu}>
                <Typography>Medlem af Dansk Bilforhandler Union:</Typography>
                <img
                  alt={'dbfu'}
                  width={110}
                  height={50}
                  loading="lazy"
                  style={{
                    objectFit: 'scale-down',
                    objectPosition: 'left center',
                    maxWidth: '100%',
                    marginTop: '10px',
                  }}
                  src={Dbfu.src}
                />
              </Box>
            )}
            {item.owner.isAutoTjekMember && (
              <Box
                marginTop={1}
                style={{ cursor: 'pointer' }}
                onClick={goToAutotjek}>
                <Typography>Vi er en del af 100% Autotjek:</Typography>
                <img
                  alt={'100autotjek'}
                  height={70}
                  loading="lazy"
                  style={{
                    objectFit: 'scale-down',
                    objectPosition: 'left center',
                    maxWidth: '100%',
                    marginTop: '10px',
                  }}
                  src={Autotjek.src}
                />
              </Box>
            )}
            <Box
              marginTop={2}
              paddingTop={2}
              display="flex"
              flexDirection="row"
              borderTop="1px solid #E1EAED">
              <Typography>
                <Link
                  underline="none"
                  href={getDealerVipUrl(
                    item.owner.name,
                    item.owner.city,
                    item.owner.zipCode,
                    item.owner.id,
                  )}
                  id={'lnk-vip-xs-map-show-dealer'}
                  className={'dealer-show'}>
                  {t('Vip.ShowAllDealerCars')}
                </Link>
              </Typography>
            </Box>
          </>
        )}
      </PadBox>
      <MapContainer>
        {item.owner.profileType === UserProfileType.Company && (
          <GoogleMapsContainter>
            <GoogleMapsLink
              href={`https://www.google.com/maps/search/?api=1&query=${item.owner.latitude},${item.owner.longitude}`}
              target="_blank">
              <Trans i18nKey="Vip.ShowOnGoogleMaps" />
            </GoogleMapsLink>
          </GoogleMapsContainter>
        )}

        <MapMapContainer>
          <ClassificationLimitWrapper classification={51}>
            <DefaultMapWrapper
              markerPosition={
                item.owner.latitude && item.owner.longitude
                  ? [item.owner.latitude, item.owner.longitude]
                  : null
              }
              markerRadius={
                item.owner.profileType == UserProfileType.Company ? 0 : 100
              }
              borderBottomRadius="10px"
            />
          </ClassificationLimitWrapper>
        </MapMapContainer>
      </MapContainer>
      <PadBox>
        { item.owner.writeEnabled && 
        <VipWriteToDealer classified-item={item} />
        }
        {item.owner.isDBFUMember &&
          (item.owner.partnerOK ||
            item.owner.partnerFragus ||
            item.owner.partnerCarGarantie ||
            item.owner.partnerClever ||
            item.owner.partnerCodan ||
            item.owner.partnerSantander) && (
            <Grid marginTop={2} container>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {item.owner.name} og DBFU samarbejder med:
                    </Typography>
                  </Grid>
                  {item.owner.partnerCodan && (
                    <Grid item xs={6}>
                      <Box
                        paddingY={1}
                        display="flex"
                        flexDirection={'column'}
                        height={140}
                        justifyContent={'space-between'}>
                        <img
                          src={Codan.src}
                          alt="codan"
                          style={{ maxWidth: '100%' }}
                        />
                        <Typography minHeight={66}>
                          Vi kan forsikre din bil til markedets bedste vilkår.
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {item.owner.partnerCarGarantie && (
                    <Grid item xs={6}>
                      <Box
                        paddingY={1}
                        display="flex"
                        flexDirection={'column'}
                        height={140}
                        justifyContent={'space-between'}>
                        <img
                          src={Cargarantie.src}
                          alt="cargarantie"
                          style={{ maxWidth: '100%' }}
                        />
                        <Typography minHeight={66}>
                          Holder dig kørende og fri for omkostninger
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {item.owner.partnerClever && (
                    <Grid item xs={6}>
                      <Box
                        paddingY={1}
                        display="flex"
                        flexDirection={'column'}
                        height={140}
                        justifyContent={'space-between'}>
                        <img
                          src={Clever.src}
                          alt="clever"
                          style={{ maxWidth: '100%' }}
                        />
                        <Typography minHeight={66}>
                          Mærk friheden - Kør med på Danmarks største
                          ladenetværk
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {item.owner.partnerFragus && (
                    <Grid item xs={6}>
                      <Box
                        paddingY={1}
                        display="flex"
                        flexDirection={'column'}
                        height={140}
                        justifyContent={'space-between'}>
                        <img
                          src={Fragus.src}
                          alt="fragus"
                          style={{ maxWidth: '100%' }}
                        />
                        <Typography minHeight={66}>
                          Brugtbilsgaranti - til dig, der ønsker lidt mere
                          sikkerhed.
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {item.owner.partnerOK && (
                    <Grid item xs={6}>
                      <Box
                        paddingY={1}
                        display="flex"
                        flexDirection={'column'}
                        height={140}
                        justifyContent={'space-between'}>
                        <img
                          src={Ok.src}
                          alt="ok"
                          style={{
                            maxWidth: '100%',
                            width: 'fit-content',
                            maxHeight: 30,
                          }}
                        />
                        <Typography minHeight={66}>
                          Vi hjælper dig med at finde den rette ladeløsning hos
                          OK.
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {item.owner.partnerSantander && (
                    <Grid item xs={6}>
                      <Box
                        paddingY={1}
                        display="flex"
                        flexDirection={'column'}
                        height={140}
                        justifyContent={'space-between'}>
                        <img
                          src={Santander.src}
                          alt="santander"
                          style={{ maxWidth: '100%' }}
                        />
                        <Typography minHeight={66}>
                          Finansier dit bilkøb med et konkurrencedygtigt billån
                          via Santander.
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
      </PadBox>
    </>
  );
};
