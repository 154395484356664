/* eslint-disable @next/next/no-img-element */
import React, { useCallback } from 'react';
import { Box, Button, List, ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PadBox } from '../containers/pad-box';
import { BuildInTrackEvents, TrackEvent } from '@/shared/util/gtm';
import { useVipContext } from '@/pages/[headline]/[[...id]]';
import fdm from '@/shared/icons/partner/fdm_s.svg';
import checkmark from '@/shared/icons/checkmark-primary.svg';

const link =
  'https://fdm.dk/vi-tilbyder/forsikring/bilreparation?providerid=7574bb1f-bb36-4909-bc57-e315326df0ad';

export const VipFdmCard = () => {
  const { item } = useVipContext();
  const { t } = useTranslation();
  const goToFDM = useCallback(() => {
    TrackEvent(BuildInTrackEvents.OnClassifiedFdmClicked);
    window.open(link, '_blank');
  }, []);

  return (
    <PadBox bgColor={'#bfdbff'}>
      <Typography fontWeight="bold" fontSize={20}>
        {t('Vip.FDM.Title')}
      </Typography>
      <Box marginTop={1}>
        <Typography>{t('Vip.FDM.SubTitle1')} </Typography>
      </Box>
      <List sx={{ paddingLeft: 1 }}>
        <ListItem sx={{ paddingY: 0 }}>
          <img alt="Tjek" src={checkmark.src} height={15.98} width={12.19} />
          <Typography marginLeft={1}>{t('Vip.FDM.1')}</Typography>
        </ListItem>
        <ListItem sx={{ paddingY: 0 }}>
          <img alt="Tjek" src={checkmark.src} height={15.98} width={12.19} />
          <Typography marginLeft={1}>{t('Vip.FDM.2')}</Typography>
        </ListItem>
        <ListItem sx={{ paddingY: 0 }}>
          <img alt="Tjek" src={checkmark.src} height={15.98} width={12.19} />
          <Typography marginLeft={1}>{t('Vip.FDM.3')}</Typography>
        </ListItem>
      </List>
      <Box
        marginTop={2}
        display="flex"
        alignItems={'center'}
        justifyContent={'space-between'}>
        <Button
          fullWidth
          className="dealer-FDM"
          sx={{
            fontSize: '15px',
            fontWeight: 700,
            height: '44px',
            whiteSpace: 'nowrap',
            marginRight: 2,
          }}
          onClick={goToFDM}
          color="fdmYellow">
          {t('Vip.FDM.OnClickText')}
        </Button>
        <img src={fdm.src} height={44} alt="fdm" />
      </Box>
    </PadBox>
  );
};
