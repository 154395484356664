/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiLinkModel,
    ApiLinkModelFromJSON,
    ApiLinkModelFromJSONTyped,
    ApiLinkModelToJSON,
} from './ApiLinkModel';

/**
 * 
 * @export
 * @interface LinkPropertiesModel
 */
export interface LinkPropertiesModel {
    /**
     * 
     * @type {Array<ApiLinkModel>}
     * @memberof LinkPropertiesModel
     */
    url?: Array<ApiLinkModel> | null;
}

export function LinkPropertiesModelFromJSON(json: any): LinkPropertiesModel {
    return LinkPropertiesModelFromJSONTyped(json, false);
}

export function LinkPropertiesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkPropertiesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': !exists(json, 'url') ? undefined : (json['url'] === null ? null : (json['url'] as Array<any>).map(ApiLinkModelFromJSON)),
    };
}

export function LinkPropertiesModelToJSON(value?: LinkPropertiesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url === undefined ? undefined : (value.url === null ? null : (value.url as Array<any>).map(ApiLinkModelToJSON)),
    };
}

