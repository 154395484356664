/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum LinkTypeModel {
    Content = 'Content',
    Media = 'Media',
    External = 'External'
}

export function LinkTypeModelFromJSON(json: any): LinkTypeModel {
    return LinkTypeModelFromJSONTyped(json, false);
}

export function LinkTypeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkTypeModel {
    return json as LinkTypeModel;
}

export function LinkTypeModelToJSON(value?: LinkTypeModel | null): any {
    return value as any;
}

