/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IApiContentStartItemModel
 */
export interface IApiContentStartItemModel {
    /**
     * 
     * @type {string}
     * @memberof IApiContentStartItemModel
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof IApiContentStartItemModel
     */
    readonly path?: string;
}

export function IApiContentStartItemModelFromJSON(json: any): IApiContentStartItemModel {
    return IApiContentStartItemModelFromJSONTyped(json, false);
}

export function IApiContentStartItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IApiContentStartItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'path': !exists(json, 'path') ? undefined : json['path'],
    };
}

export function IApiContentStartItemModelToJSON(value?: IApiContentStartItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

