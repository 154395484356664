/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImageFocalPointModel
 */
export interface ImageFocalPointModel {
    /**
     * 
     * @type {number}
     * @memberof ImageFocalPointModel
     */
    left?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageFocalPointModel
     */
    top?: number;
}

export function ImageFocalPointModelFromJSON(json: any): ImageFocalPointModel {
    return ImageFocalPointModelFromJSONTyped(json, false);
}

export function ImageFocalPointModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageFocalPointModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'left': !exists(json, 'left') ? undefined : json['left'],
        'top': !exists(json, 'top') ? undefined : json['top'],
    };
}

export function ImageFocalPointModelToJSON(value?: ImageFocalPointModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'left': value.left,
        'top': value.top,
    };
}

