import { Box, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import Image from '@/web/views/components/image/image';

import mikro from '@/shared/icons/car-types/mikro.svg';
import hatchback from '@/shared/icons/car-types/hatchback.svg';
import sedan from '@/shared/icons/car-types/sedan.svg';
import stationcar from '@/shared/icons/car-types/stationcar.svg';
import mpv from '@/shared/icons/car-types/mpv.svg';
import suv from '@/shared/icons/car-types/suv.svg';
import cabriolet from '@/shared/icons/car-types/cabriolet.svg';
import coupe from '@/shared/icons/car-types/coupe.svg';
import crossover from '@/shared/icons/car-types/cuv.svg';
import el from '@/shared/icons/car-types/el.svg';
import { useTranslation } from 'react-i18next';
import { PadBox } from '../containers/pad-box';
import { TrackEvent } from '@/shared/util/gtm';

interface Icon {
  src: string;
  height: number;
  width: number;
}

interface CarType {
  icon: Icon;
  url: string;
}

const itemSlices: CarType[] = [
  {
    url: '/brugte-biler/biltype-mikro',
    icon: { src: mikro.src, height: 70, width: 78 },
  },
  {
    url: '/brugte-biler/biltype-stationcar',
    icon: { src: stationcar.src, height: 70, width: 78 },
  },
  {
    url: '/brugte-biler/biltype-hatchback',
    icon: { src: hatchback.src, height: 70, width: 78 },
  },
  {
    url: '/brugte-biler/biltype-sedan',
    icon: { src: sedan.src, height: 70, width: 78 },
  },
  {
    url: '/brugte-biler/biltype-suv',
    icon: { src: suv.src, height: 70, width: 78 },
  },
  {
    url: '/brugte-biler/biltype-crossover',
    icon: { src: crossover.src, height: 70, width: 103 },
  },
  {
    url: '/brugte-biler/biltype-mpv',
    icon: { src: mpv.src, height: 70, width: 93 },
  },
  {
    url: '/brugte-biler/biltype-cabriolet',
    icon: { src: cabriolet.src, height: 70, width: 78 },
  },
  {
    url: '/brugte-biler/biltype-coupe',
    icon: { src: coupe.src, height: 70, width: 78 },
  },
  {
    url: '/brugte-biler/braendstoftype-el',
    icon: { src: el.src, height: 70, width: 65 },
  },
];

function FindCarAfterTypeSection() {
  const handleLinkClick = (url: string) => {
    TrackEvent('on_cartype_clicked', url.split('biltype-')[1]);
  };
  const { t } = useTranslation();
  return (
    <>
      <Typography variant={'h2'}>{t('Labels.FindCarByType')} </Typography>
      <PadBox>
        {/* HACK: marginBottom={'-15px'}, cant control next/image style; add padding below image when display:block is not set  */}
        <Grid container spacing={0} rowGap={'29px'} marginBottom={'-15px'}>
          <Grid item xs={12} md={12} marginX={'auto'}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              flexWrap={'wrap'}
              columnGap={'10px'}
              width={'100%'}
              alignContent={'flex-start'}
              justifyContent={'space-around'}>
              {itemSlices.map((item, innerIndex) => {
                return (
                  <Box key={`${innerIndex}`} minWidth={'80px'}>
                    <Link
                      onClick={e => handleLinkClick(item.url)}
                      href={item.url}>
                      <Image
                        alt={item.url}
                        src={item.icon.src}
                        height={item.icon.height}
                        width={item.icon.width}
                      />
                    </Link>
                  </Box>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </PadBox>
    </>
  );
}

export default FindCarAfterTypeSection;
