import {
  Configuration,
  Dealer,
  DealerApi,
  LeadProductStatus,
  SearchApi,
  SearchPageAttribute,
  SearchPageOptions,
  SearchResult,
  SeoUrl,
  UserProfileType,
} from '@/shared/lib-api';
import { RemoveUndefinedsFromObject } from '@/shared/util/helpers/next-helpers/node-server-helper';
import {
  getDealerVipUrl,
  slugifyOptions,
} from '@/web/util/helpers/url-helpers';
import { DealerContent } from '@/web/views/components/dealer/dealer-content';
import DealerHead from '@/web/views/components/dealer/head';
import ContactConsentModal from '@/web/views/components/modals/contactConsentModal';
import { SRPSubTopBar } from '@/web/views/components/SRP/srp-sub-top-bar';
import { SRPTopBar } from '@/web/views/components/SRP/srp-top-bar';
import { Box } from '@mui/material';
import { NextPage } from 'next';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import TagManager from 'react-gtm-module';
import slugify from 'slugify';

export interface DealerPageProps {
  dealer?: Dealer;
  searchResult?: SearchResult;
  searchFilters?: DealerSearchFilters;
  page: number;
  sort: string;
  seoData: SeoUrl;
}

export interface DealerSearchFilters {
  make?: string;
  priceFrom?: number;
  priceTo?: number;
  year?: number;
}

interface DealerVipContextType {
  askForContactConsent?: (classifiedId: number) => void;
}

const DealerVipContext = createContext<DealerVipContextType | undefined>(undefined);
export const useDealerVipContext = () => {
  return useContext(DealerVipContext);
};

export const DealerPage: NextPage<DealerPageProps> = props => {
  const [showContactConsent, setShowContactConsent] = useState({show: false, classifedId: 0});

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'DL_DEALER_VIP',
        dlv_dealer_name: props.dealer.name,
        dlv_dealer_id: props.dealer.id,
        dlv_dealer_islead:
          (props.dealer.leadProductStatus === undefined ||
            props.dealer.leadProductStatus === LeadProductStatus.Active) &&
          props.dealer.isLead,
        dlv_dealer_type: UserProfileType.Company,
        dlv_pagetype: 'dealer_vip',
      },
    });
  }, [props.dealer]);

  return (
    <>
      {(props.dealer.leadProductStatus === undefined ||
        props.dealer.leadProductStatus === LeadProductStatus.Active) &&
        props.dealer.isLead && (
          <Box
            component="p"
            style={{ position: 'fixed', color: 'transparent', zIndex: -1 }}
            id="lead-forhandler">
            lead-forhandler
          </Box>
        )}
      <DealerHead dealer={props.dealer} seoData={props.seoData} />
      <SRPTopBar />
      <SRPSubTopBar />
      <ContactConsentModal open={showContactConsent.show} onClose={() => setShowContactConsent({show: false, classifedId: 0})} classifiedId={showContactConsent.classifedId} />
      <DealerVipContext.Provider value={{askForContactConsent: (classifiedId: number) => {
            setShowContactConsent({show: true, classifedId: classifiedId});
          }}}>
        <DealerContent {...props} />
      </DealerVipContext.Provider>
    </>
  );
};
export default DealerPage;

export async function getServerSideProps(context) {
  const { query } = context;
  const queryId: string = query.id as string;
  const idRegEx: RegExp = /id-(\d+)$/;
  const idMatch = queryId.match(idRegEx);

  if (idMatch?.length !== 2) {
    return { notFound: true };
  }

  const id: number = +idMatch[1];
  const qName = query.dealer as string;
  const qCityAndZip = query.city as string;

  const dealerApi = new DealerApi(
    new Configuration({
      basePath: process.env.INTERNAL_SITE_API,
    }),
  );

  let dealer: Dealer | undefined;
  try {
    dealer = await dealerApi.apiDealerGetcompanydealerGet({ id });
  } catch (error) {}

  if (!dealer) {
    return { notFound: true };
  }

  // check slugs
  if (
    qName !== slugify(dealer.name.toLowerCase(), slugifyOptions) ||
    qCityAndZip !==
      `${slugify(dealer.city.toLowerCase(), slugifyOptions)}-${dealer.zipCode}`
  ) {
    var url = getDealerVipUrl(dealer.name, dealer.city, dealer.zipCode, id);
    return {
      redirect: {
        permanent: false,
        destination: url,
      },
    };
  }

  // parse route + query
  const querySlugs: string[] = query.slug ? query.slug : [];

  let pageNumber: number = 1;
  let sort: string = query.sort ?? 'nyest';
  let queryMakeFilter: string = null;

  querySlugs.forEach(slug => {
    if (slug.startsWith('side-')) {
      pageNumber = Number.parseInt(slug.replace('side-', ''), 10);
    } else {
      queryMakeFilter = slug;
    }
  });

  const queryPriceFilter: string = query.pris;
  const queryYearFilter: string = query.aargang;

  let makeFilter: string | undefined = null;
  let priceFromFilter: number | undefined = null;
  let priceToFilter: number | undefined = null;
  let yearFilter: number | undefined = null;

  if (queryMakeFilter) {
    makeFilter = queryMakeFilter.toLowerCase();
  }

  if (queryPriceFilter) {
    var priceSplit = queryPriceFilter.split(',');
    if (
      priceSplit.length === 2 &&
      !isNaN(+priceSplit[0]) &&
      !isNaN(+priceSplit[1])
    ) {
      priceFromFilter = +priceSplit[0];
      priceToFilter = +priceSplit[1];
    }
  }

  if (queryYearFilter && !isNaN(+queryYearFilter)) {
    yearFilter = +queryYearFilter;
  }

  // build search request
  let searchPageOptions: SearchPageOptions = {
    dealerId: { value: dealer.id.toString(), label: 'dealerId' },
    pageNumber: pageNumber,
    pageSize: 9,
    sort: { value: sort, label: sort },
  };

  if (makeFilter) {
    searchPageOptions.makeModelVariantOptions = [
      { brand: { value: queryMakeFilter.toLowerCase(), label: 'brand' } },
    ];
  } else {
    searchPageOptions.makeModelVariantOptions = [
      { brand: { value: '', label: 'brand' } },
    ];
  }

  if (yearFilter) {
    searchPageOptions.yearFrom = {
      value: yearFilter.toString(),
      label: 'yearFrom',
    };
    searchPageOptions.yearTo = {
      value: yearFilter.toString(),
      label: 'yearTo',
    };
  }

  if (priceFromFilter) {
    searchPageOptions.priceFrom = {
      value: priceFromFilter.toString(),
      label: 'priceFrom',
    };
  }

  if (priceToFilter) {
    searchPageOptions.priceTo = {
      value: priceToFilter.toString(),
      label: 'priceTo',
    };
  }
  // do search
  let pageOptions = await dealerApi.apiDealerGetcompanyclassifiedsPost({
    searchPageOptions: searchPageOptions,
  });

  return {
    props: {
      dealer: RemoveUndefinedsFromObject(dealer),
      seoData: RemoveUndefinedsFromObject(pageOptions.seoData),
      searchResult: RemoveUndefinedsFromObject(pageOptions.searchResult),
      searchFilters: {
        make: makeFilter,
        priceFrom: priceFromFilter,
        priceTo: priceToFilter,
        year: yearFilter,
      },
      page: pageNumber,
      sort: sort,
    },
  } as { props: DealerPageProps };
}
