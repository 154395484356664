/* eslint-disable @next/next/no-img-element */
import React, { useCallback } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ClassifiedStatus, UserProfileType } from '@/shared/lib-api';
import dealerLocationIcon from '@/shared/icons/dealer-location-icon.svg';
import dealerPhoneIcon from '@/shared/icons/dealer-phone-icon.svg';
import { getDealerVipUrl } from '@/web/util/helpers/url-helpers';
import { VipTodaysOpeningHours } from './todays-opening-hours';
import { PadBox } from '../containers/pad-box';
import Image from '@/web/views/components/image/image';
import Dbfu from '@/shared/icons/dbfu-logo-2-blue.png';
import Autotjek from '@/shared/icons/autotjek-logo.png';
import { useRouter } from 'next/router';
import { useVipContext } from '@/pages/[headline]/[[...id]]';

export const VipDealerCard = () => {
  const { item } = useVipContext();
  const { t } = useTranslation();
  const router = useRouter();
  const goToDbfu = useCallback(() => {
    router.push('/dbfu');
  }, [router]);

  const goToAutotjek = useCallback(() => {
    router.push('/100pct-autotjek');
  }, [router]);

  if (!item?.owner) {
    return null;
  }
  const isLimitedVipView =
    item.systemData.status === ClassifiedStatus.Sold ||
    item.systemData.status === ClassifiedStatus.Inactive;

  const shouldShowInfoOnSoldCar =
    !isLimitedVipView || item.owner.profileType === UserProfileType.Company;

  const phoneDisplay = () => {
    const part1 = item.owner.phone.substring(0, 2);
    const part2 = item.owner.phone.substring(2, 4);
    const part3 = item.owner.phone.substring(4, 6);
    const part4 = item.owner.phone.substring(6, 8);

    return (
      <span style={{ marginLeft: '2px' }}>
        {/* <span style={{ marginRight: '2px' }}>{part1}</span>
        <span style={{ marginRight: '2px' }}>{part2}</span>
        <span style={{ marginRight: '2px' }}>{part3}</span>
        <span style={{ marginRight: '2px' }}>{part4}</span> */}
        {`${part1} ${part2} ${part3} ${part4}`}
      </span>
    );
  };

  return (
    <PadBox>
      {item.owner.imageUrl && (
        <Box marginBottom={3}>
          <img
            width={332}
            height={item.owner.imageUrl.includes('maxwidth=400') ? 80 : 56}
            style={{
              objectFit: 'scale-down',
              objectPosition: 'left center',
              maxWidth: '100%',
            }}
            loading="lazy"
            alt={item.owner.name}
            src={item.owner.imageUrl}
          />
        </Box>
      )}

      <Box display="flex" flexDirection="row" alignItems="flex-start">
        <Image
          height={20}
          width={20}
          alt="Lokation"
          src={dealerLocationIcon.src}
        />
        <Box paddingLeft={1}>
          <Typography fontWeight="bold">
            {shouldShowInfoOnSoldCar
              ? item.owner.name
              : item.owner.name.split(' ')[0]}
          </Typography>
          <Typography lineHeight={1.2}>
            {item.owner.address && (
              <>
                {shouldShowInfoOnSoldCar && item.owner.address}
                <br />
              </>
            )}
            {shouldShowInfoOnSoldCar && item.owner.zipCode} {item.owner.city}
          </Typography>
        </Box>
      </Box>

      {item.owner.phone && shouldShowInfoOnSoldCar && (
        <Box paddingTop={2} display="flex" flexDirection="row">
          <Image
            width={20}
            height={18}
            alt="Telefonnummer"
            src={dealerPhoneIcon.src}
          />
          <Box paddingLeft={1}>
            <Typography>
              <a
                href={`tel:${item.owner.phone}`}
                id={'lnk-vip-dealer-call'}
                className={'dealer-call'}>
                {phoneDisplay()}
              </a>
            </Typography>
          </Box>
        </Box>
      )}

      {item.owner.profileType === UserProfileType.Company && (
        <>
          <VipTodaysOpeningHours />
          {item.owner.isDBFUMember && (
            <Box marginTop={1} style={{ cursor: 'pointer' }} onClick={goToDbfu}>
              <Typography>Medlem af Dansk Bilforhandler Union:</Typography>
              <img
                alt={'dbfu'}
                width={110}
                height={50}
                loading="lazy"
                style={{
                  objectFit: 'scale-down',
                  objectPosition: 'left center',
                  maxWidth: '100%',
                  marginTop: '10px',
                }}
                src={Dbfu.src}
              />
            </Box>
          )}
          {item.owner.isAutoTjekMember && (
            <Box
              marginTop={1}
              style={{ cursor: 'pointer' }}
              onClick={goToAutotjek}>
              <Typography>Vi er en del af 100% Autotjek:</Typography>

              <img
                alt={'100autotjek'}
                height={70}
                loading="lazy"
                style={{
                  objectFit: 'scale-down',
                  objectPosition: 'left center',
                  maxWidth: '100%',
                  marginTop: '10px',
                }}
                src={Autotjek.src}
              />
            </Box>
          )}
          <Box
            marginTop={2}
            paddingTop={2}
            display="flex"
            flexDirection="row"
            borderTop="1px solid #E1EAED">
            <Typography>
              <Link
                underline="none"
                href={getDealerVipUrl(
                  item.owner.name,
                  item.owner.city,
                  item.owner.zipCode,
                  item.owner.id,
                )}
                id={'lnk-vip-show-dealer'}
                className={'dealer-show'}>
                {t('Vip.ShowAllDealerCars')}
              </Link>
            </Typography>
          </Box>
        </>
      )}
    </PadBox>
  );
};
