import { useVipContext } from '@/pages/[headline]/[[...id]]';
import { useScreenSizeContext } from '@/pages/_app';
import { useAuth } from '@/shared/hooks/auth-hook';
import { BasicCarCardType, useBasicCar } from '@/shared/hooks/basic-car-hook';
import { useRequestHelper } from '@/shared/hooks/request-helper';
import { useWriteToDealer } from '@/shared/hooks/write-to-dealer/write-to-dealer-hook';
import svgCheckmarkBoxed from '@/shared/icons/checkmark-boxed.svg';
import svgGreenbow from '@/shared/icons/Greenbow.svg';
import svgUmbrella from '@/shared/icons/umbrella.svg';
import { WriteToDealerValues } from '@/shared/interfaces/writeToDealerValues';
import { ClassifiedItem, SalesType, UserProfileType } from '@/shared/lib-api';
import { useAppDispatch } from '@/shared/store/hooks';
import { useApi } from '@/shared/util/api';
import { Colors } from '@/shared/util/colors';
import { LoadingState } from '@/shared/util/general-helpers';
import { TrackEvent } from '@/shared/util/gtm';
import { slugifyOptions } from '@/web/util/helpers/url-helpers';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  SvgIcon,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { Formik, useField, useFormikContext } from 'formik';
import Image from '@/web/views/components/image/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import slugify from 'slugify';
import { BasicCarGalleryCard } from '../cards/basic-car-card/basic-car-gallery-card';
import BasicCarListCard from '../cards/basic-car-card/basic-car-list-card';
import { DialogClose } from '../default-components/dialog/dialog-close';
import { DefaultReponseError } from '../default-components/input/default-response-error';
import DefaultInputError from '../default-components/input/defaults/default-input-error';
import SubHeaderInputField from '../default-components/input/formik/sub-header-input-field';
import { PickTestDriveTime } from './pick-test-drive-time';
import { useTjekbilApi } from '@/shared/util/tjekbil-api';
import { OpenExternalLink } from '@/web/util/helpers/open-external-link';
import { useSRPModalMessageContext } from '../default-components/write-dealer-form-modal/write-dealer-form-modal';
import { setUser } from '@/shared/store/reducers/AuthSlice';

interface WriteToDealerForm {
  name: string;
  email: string;
  phone: string;
  message: string;
  acceptService: boolean;
  signUpToNewsletter: boolean;
}

interface VipWriteToDealerProps {
  'classified-item'?: ClassifiedItem;
}

export const VipWriteToDealer = (props: VipWriteToDealerProps) => {
  const item = props['classified-item'];

  if (!item) {
    return <></>;
  }

  if (item.disableWriteTo) {
    return <></>;
  }

  return (
    <>
      <VipWriteToDealerFlat
        classifiedId={item.id}
        ownerId={item.owner.id}
        carMake={item.make}
        carModel={item.model}
        carVariant={item.alternativeVariant}
        ownerName={item.owner.name}
        ownerAddress={item.owner.address}
        ownerZipCode={item.owner.zipCode}
        ownerCity={item.owner.city}
        ownerProfileType={item.owner.profileType}
        isModal={false}
        loadAlternatives={false}
      />
    </>
  );
};

interface VipWriteToDealerFlatProps {
  classifiedId?: number;
  ownerId?: number;
  carMake?: string;
  carModel: string;
  carVariant?: string;
  onClose?: React.MouseEventHandler<HTMLImageElement>;
  ownerName?: string;
  ownerAddress?: string;
  ownerZipCode?: number;
  ownerCity?: string;
  ownerProfileType?: UserProfileType;
  callOptionInitialChecked?: boolean;
  testDriveOptionInitialChecked?: boolean;
  tradeOptionInitialChecked?: boolean;
  leasingOfferOptionInitialChecked?: boolean;
  isModal: boolean;
  loadAlternatives: boolean;
}

export const VipWriteToDealerFlat = (props: VipWriteToDealerFlatProps) => {
  const { item: itemVip } = useVipContext();
  const { item: itemSrp } = useSRPModalMessageContext();
  const item = itemVip ?? itemSrp;
  const [dealerContacted, setDealerContacted] = useState(false);
  const [greenbowAccepted, setGreenbowAccepted] = useState(false);
  const [greenbowAcceptedSecondStep, setGreenbowAcceptedSecondStep] =
    useState(false);
  const [greenbowData, setGreenbowData] = useState(undefined);
  const { isMobile } = useScreenSizeContext();
  const { isLoggedIn } = useAuth();
  const { dealerApi, classifiedApi, authApi } = useApi();
  const { isCurrentlyLiked, addLike, loading } = useBasicCar({
    item: item,
  });
  const { handleRequest } = useRequestHelper();
  const [alternatives, setAlternatives] = useState<ClassifiedItem[]>();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isLeasing =
    item?.systemData?.category?.toLowerCase() === 'leasing biler';

  const loadAlternatives = useCallback(async () => {
    if (!props.loadAlternatives) {
      return;
    }
    var req = dealerApi.apiDealerAlternativecarsGet({ id: props.classifiedId });
    let [res, errorAndState] = await handleRequest(req);
    if (errorAndState.state === LoadingState.Success) {
      setAlternatives(res);
    }
  }, [dealerApi, handleRequest, props.classifiedId, props.loadAlternatives]);

  useEffect(() => {
    var id = setTimeout(async () => {
      await loadAlternatives();
    }, 1500);
    return () => {
      clearTimeout(id);
    };
  }, [dispatch, loadAlternatives]);

  useEffect(() => {
    setDealerContacted(false);
    setGreenbowAccepted(false);
    setGreenbowAcceptedSecondStep(false);
    setGreenbowData(undefined);
  }, []);

  const {
    initialValues,
    onSubmit,
    validationSchema,
    requestState,
    errorMessage,
    zipCodeRequired,
    personalInfo,
  } = useWriteToDealer(
    props.classifiedId,
    props.ownerId,
    props.callOptionInitialChecked ?? false,
    props.testDriveOptionInitialChecked ?? false,
    props.tradeOptionInitialChecked ?? false,
    props.leasingOfferOptionInitialChecked ?? false,
  );

  const displayGreenbow =
    item?.fueltype === 'El' ||
    item?.fueltype == 'Benzin/El' ||
    item?.fueltype == 'Diesel/El';

  const sendToGreenbowAction = useCallback(
    async (email: string, phone: string, name: string) => {
      await classifiedApi.apiClassifiedSavegreenbowleadPost({
        classifiedId: props.classifiedId,
        screenName: name,
        email: email,
        phoneNumber: phone,
      });
      setGreenbowAccepted(true);
    },
    [setGreenbowAccepted, classifiedApi, props.classifiedId],
  );

  const sendToGreenbow = useCallback(async () => {
    if (greenbowData === undefined) {
      return;
    }
    sendToGreenbowAction(
      greenbowData.email,
      greenbowData.phone,
      greenbowData.name,
    );
    setGreenbowAcceptedSecondStep(true);
  }, [setGreenbowAcceptedSecondStep, sendToGreenbowAction, greenbowData]);

  const onSuccess = useCallback(
    async (values: WriteToDealerValues) => {
      if (isLoggedIn && !isCurrentlyLiked && loading !== LoadingState.Loading) {
        addLike(false);
      }
      if (displayGreenbow && values.greenbow) {
        await sendToGreenbowAction(
          values.emailAddress,
          values.phoneNumber,
          values.name,
        );
      } else if (displayGreenbow) {
        setGreenbowData({
          name: values.name,
          email: values.emailAddress,
          phone: values.phoneNumber,
        });
      }
      if (
        isLoggedIn &&
        loading !== LoadingState.Loading &&
        (personalInfo.phoneNumber !== values.phoneNumber ||
          personalInfo.screenName !== values.name)
      ) {
        var result = await authApi.apiAuthUpdateprofileinformationdataPost({
          profileInformationData: {
            phoneNumber: values.phoneNumber,
            name: values.name,
            countryCode: personalInfo.countryCode,
            zipCode: personalInfo.zipCode,
            city: personalInfo.city,
            email: personalInfo.email,
          },
        });
        dispatch(setUser(result));
      }

      setDealerContacted(true);
    },
    [
      addLike,
      isLoggedIn,
      isCurrentlyLiked,
      loading,
      setDealerContacted,
      displayGreenbow,
      sendToGreenbowAction,
      personalInfo,
    ],
  );

  const onSuccessInvoked = useCallback(
    (values: WriteToDealerValues) => onSubmit(values, () => onSuccess(values)),
    [onSubmit, onSuccess],
  );

  const [trade, setTrade] = useState<boolean>(
    props.tradeOptionInitialChecked ?? false,
  );
  const handleTradeOnChange = (value: boolean) => {
    setTrade(value);
  };

  const [testDrive, setTestDrive] = useState<boolean>(
    props.testDriveOptionInitialChecked ?? false,
  );

  const handleTestDriveOnChange = (value: boolean) => {
    setTestDrive(value);
  };

  const [leasingOffer, setLeasingOffer] = useState<boolean>(
    props.leasingOfferOptionInitialChecked ?? false,
  );
  const handleLeasingOfferOnChange = (value: boolean) => {
    setLeasingOffer(value);
  };

  const displayLeasingOffer =
    (isLeasing || item?.id === 1204629) && item?.owner?.leasingOfferEnabled;
  const displayChoices =
    props.ownerProfileType === UserProfileType.Company &&
    (item?.owner?.writeEnabled ||
      item?.owner?.callEnabled ||
      item?.owner?.tradeEnabled ||
      item?.owner?.testDriveEnabled);

  const smallOrDesktopNotModal = isMobile || (!isMobile && !props.isModal);
  return (
    <>
      <Box
        display={'flex'}
        height={'100%'}
        flexDirection={'column'}
        justifyContent={'space-between'}>
        {dealerContacted && (
          <MessageSend
            sendToGreenbow={sendToGreenbow}
            item={item}
            isModal={props.isModal}
            alternatives={alternatives}
            onClose={props.onClose}
            displayGreenbow={displayGreenbow}
            greenbowAccepted={greenbowAccepted}
            greenbowAcceptedSecondStep={greenbowAcceptedSecondStep}
            isTestDrive={testDrive}
          />
        )}
        {!dealerContacted && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSuccessInvoked}>
            <Box>
              <Box
                display={'flex'}
                width={'100%'}
                flexDirection={'row'}
                justifyContent={'space-between'}>
                <Typography paddingBottom={1} variant={'h6'}>
                  {t('Vip.WriteToDealer.Title')}
                </Typography>
                {props.onClose && <DialogClose onClose={props.onClose} />}
              </Box>

              {!isMobile && props.isModal && (
                <Box
                  bgcolor={'#fff'}
                  paddingY="16px"
                  paddingX="25px"
                  marginBottom={'12px'}
                  borderRadius={'3px'}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography fontSize={'24px'} fontWeight={700}>
                        {props.carMake} {props.carModel}
                      </Typography>
                      <Typography
                        paddingTop={0.4}
                        fontSize={'15px'}
                        fontWeight={700}>
                        {props.carVariant}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                      <Box display={'inline-block'} textAlign="left">
                        <Typography fontSize={'15px'} fontWeight={700}>
                          {props.ownerName}
                        </Typography>
                        <Typography fontSize={'15px'} lineHeight={1.2}>
                          {props.ownerAddress && (
                            <>
                              {props.ownerAddress}
                              <br />
                            </>
                          )}
                          {props.ownerZipCode} {props.ownerCity}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}

              <Grid
                marginTop={0}
                container
                rowSpacing={2}
                columnSpacing={2}
                flexDirection={'row-reverse'}>
                {!isMobile && props.isModal && (
                  <Grid item xs={smallOrDesktopNotModal ? 12 : 6}>
                    <Typography
                      color={Colors.Primary}
                      fontWeight={700}
                      paddingBottom={0}
                      display="block"
                      variant={'h8'}>
                      {t('Vip.WriteToDealer.MessageTitle')}
                    </Typography>
                    <MessageTextField limit={200} name={'message'} />
                    {!smallOrDesktopNotModal && (
                      <Box marginTop={2}>
                        {props.isModal && displayGreenbow && (
                          <Box marginBottom={2}>
                            <CustomWriteToDealerCheckboxField
                              title={t('Vip.WriteToDealer.GreenbowTitle')}
                              text={t('Vip.WriteToDealer.GreenbowText')}
                              name={'greenbow'}
                            />
                          </Box>
                        )}
                        <Box>
                          <DefaultReponseError
                            state={requestState}
                            messageError={errorMessage}
                          />
                          <SubmitButton id={props.classifiedId} />
                          <Box paddingTop={2}>
                            <Typography
                              fontSize={'11px'}
                              lineHeight={'14.4px'}
                              color="#3F3F3F">
                              {t('Vip.WriteToDealer.BottomText')}
                              <Link
                                target={'_blank'}
                                href="https://heymate.dk/vilkaar-og-privatlivspolitik/">
                                {t('Vip.WriteToDealer.BottomText2')}
                              </Link>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                )}
                <Grid item xs={smallOrDesktopNotModal ? 12 : 6}>
                  {displayChoices && (
                    <Box>
                      <Typography fontWeight={'bold'} color={Colors.Primary}>
                        Jeg vil gerne
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                          marginBottom: 1,
                        }}>
                        {item?.owner?.writeEnabled && (
                          <Box sx={{ bgcolor: 'none' }}>
                            <CustomOptionCheckboxField
                              text={'Ringes op'}
                              name={'call'}
                            />
                          </Box>
                        )}
                        {item?.owner?.testDriveEnabled && (
                          <Box sx={{ bgcolor: 'none' }}>
                            <CustomOptionCheckboxField
                              text={'Bestille prøvetur'}
                              name={'testDrive'}
                              onChange={handleTestDriveOnChange}
                            />
                          </Box>
                        )}
                        {item?.owner?.tradeEnabled && (
                          <Box sx={{ bgcolor: 'none' }}>
                            <CustomOptionCheckboxField
                              text={'Få byttepris'}
                              name={'trade'}
                              onChange={handleTradeOnChange}
                            />
                          </Box>
                        )}
                        {displayLeasingOffer && (
                          <Box sx={{ bgcolor: 'none' }}>
                            <CustomOptionCheckboxField
                              text={'Modtage leasing tilbud'}
                              name={'leasingOffer'}
                              onChange={handleLeasingOfferOnChange}
                            />
                          </Box>
                        )}
                      </Box>

                      {testDrive && (
                        <Box marginBottom={1}>
                          <PickTestDriveTime name={'testDriveTime'} />
                        </Box>
                      )}

                      {trade && (
                        <Grid container columnSpacing={4} marginBottom={1}>
                          <Grid item xs={6}>
                            <SubHeaderInputField
                              name={'licensePlate'}
                              placeholder={'AB 12 345'}
                              subHeader={'Din bils nummerplade'}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <SubHeaderInputField
                              name={'mileage'}
                              placeholder={'fx 75.000'}
                              subHeader={'Kørte kilometer'}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TradeCar />
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                  )}
                  {smallOrDesktopNotModal && (
                    <Box marginY={1}>
                      <Typography
                        color={Colors.Primary}
                        fontWeight={700}
                        paddingBottom={0}
                        display="block"
                        variant={'h8'}>
                        {t('Vip.WriteToDealer.MessageTitle')}
                      </Typography>
                      <MessageTextField limit={200} name={'message'} />
                    </Box>
                  )}
                  <Grid container rowSpacing={2} columnSpacing={1}>
                    <Grid item xs={12}>
                      <SubHeaderInputField
                        name={'name'}
                        placeholder={t('Auth.NamePlaceholder')}
                        subHeader={t('Auth.Name')}
                      />
                    </Grid>
                    <Grid item xs={!props.isModal && displayGreenbow ? 6 : 12}>
                      <SubHeaderInputField
                        name={'emailAddress'}
                        placeholder={t('Auth.EmailPlaceholder')}
                        subHeader={t('Auth.Email')}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={
                        zipCodeRequired || (!props.isModal && displayGreenbow)
                          ? 6
                          : 12
                      }>
                      <SubHeaderInputField
                        name={'phoneNumber'}
                        placeholder={t('Auth.PhonePlaceholder')}
                        subHeader={t('Auth.Phone')}
                      />
                    </Grid>
                    {zipCodeRequired && (
                      <Grid item xs={6}>
                        <SubHeaderInputField
                          name={'zipCode'}
                          placeholder={'Dit postnummer'}
                          subHeader={'Postnummer'}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {!smallOrDesktopNotModal && (
                    <Box marginTop={2}>
                      <CustomWriteToDealerCheckboxField
                        text={t('Vip.WriteToDealer.AcceptNewsletterText')}
                        name={'signUpToNewsletter'}
                      />
                      <Box marginTop={2}>
                        <CustomWriteToDealerCheckboxField
                          text={t('Vip.WriteToDealer.AcceptServicesText')}
                          name={'acceptService'}
                        />
                      </Box>
                      {!props.isModal && displayGreenbow && (
                        <Box marginTop={2}>
                          <CustomWriteToDealerCheckboxField
                            title={t('Vip.WriteToDealer.GreenbowTitle')}
                            text={t('Vip.WriteToDealer.GreenbowText')}
                            name={'greenbow'}
                          />
                        </Box>
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>
              {smallOrDesktopNotModal && (
                <Grid container paddingTop={2} rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={smallOrDesktopNotModal ? 12 : 6}>
                    <CustomWriteToDealerCheckboxField
                      text={t('Vip.WriteToDealer.AcceptNewsletterText')}
                      name={'signUpToNewsletter'}
                    />
                    <Box marginTop={2}>
                      <CustomWriteToDealerCheckboxField
                        text={t('Vip.WriteToDealer.AcceptServicesText')}
                        name={'acceptService'}
                      />
                    </Box>
                    {!props.isModal && displayGreenbow && (
                      <Box marginTop={2}>
                        <CustomWriteToDealerCheckboxField
                          title={t('Vip.WriteToDealer.GreenbowTitle')}
                          text={t('Vip.WriteToDealer.GreenbowText')}
                          name={'greenbow'}
                        />
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={smallOrDesktopNotModal ? 12 : 6}>
                    {props.isModal && displayGreenbow && (
                      <Box marginBottom={2}>
                        <CustomWriteToDealerCheckboxField
                          title={t('Vip.WriteToDealer.GreenbowTitle')}
                          text={t('Vip.WriteToDealer.GreenbowText')}
                          name={'greenbow'}
                        />
                      </Box>
                    )}
                    <Box>
                      <DefaultReponseError
                        state={requestState}
                        messageError={errorMessage}
                      />
                      <SubmitButton id={props.classifiedId} />
                      <Box paddingTop={2}>
                        <Typography
                          fontSize={'11px'}
                          lineHeight={'14.4px'}
                          color="#3F3F3F">
                          {t('Vip.WriteToDealer.BottomText')}
                          <Link
                            target={'_blank'}
                            href="https://heymate.dk/vilkaar-og-privatlivspolitik/">
                            {t('Vip.WriteToDealer.BottomText2')}
                          </Link>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Formik>
        )}
      </Box>
    </>
  );
};

const TradeCar = () => {
  const [vehicleName, setVehicleName] = useState<string | undefined>(undefined);
  const { lookupApi } = useTjekbilApi();
  const formik = useFormikContext<WriteToDealerValues>();
  const lookupVehicle = useCallback(async () => {
    let licensePlate = formik.values.licensePlate
      .replace(' ', '')
      .replace(' ', '');
    if (licensePlate.length >= 5) {
      try {
        var results = await lookupApi.getDmrByRegNrQuery({
          regnr: licensePlate,
          amount: 1,
        });
        if (results.length > 0) {
          setVehicleName(
            'Din bil: ' +
              results[0].maerkeTypeNavn +
              ' ' +
              results[0].modelTypeNavn,
          );
        } else {
          setVehicleName('Kan ikke finde bilen');
        }
      } catch (error) {
        setVehicleName('Kan ikke finde bilen');
      }
    } else {
      setVehicleName(undefined);
    }
  }, [lookupApi, formik.values.licensePlate]);
  useEffect(() => {
    lookupVehicle();
  }, [lookupVehicle]);
  return (
    <Typography marginTop={1} fontWeight={700}>
      {vehicleName}
    </Typography>
  );
};

const SubmitButton = (props: { id: number }) => {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const onClick = useCallback(() => {
    formik.handleSubmit();
  }, [formik]);
  return (
    <Button onClick={onClick} data-id={props.id} fullWidth color="secondary">
      {t('Vip.WriteToDealer.ButtonText')}{' '}
    </Button>
  );
};

interface MessageTextFieldProps {
  limit: number;
  name: string;
}

const MessageTextField = (props: MessageTextFieldProps) => {
  const { isMobile } = useScreenSizeContext();
  const [field, meta, helpers] = useField(props.name!);
  const { t } = useTranslation();
  const formik = useFormikContext<WriteToDealerForm>();
  const onChange = useCallback(
    (value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      formik.setFieldValue(props.name, value.target.value);
    },
    [formik, props.name],
  );
  const hasError = meta.touched && meta.error;
  return (
    <>
      <Box display={'flex'} alignItems={'flex-end'} justifyContent={'flex-end'}>
        <Box
          width={'100%'}
          bgcolor={hasError ? Colors.ErrorBackGround : Colors.White}
          borderRadius={'2px'}
          border={hasError ? '2px solid ' + Colors.Error : '1px solid #D9D9D9'}>
          <TextField
            inputProps={{
              style: {
                paddingLeft: '12px',
                paddingRight: '12px',
              },
            }}
            multiline
            fullWidth={true}
            minRows={isMobile ? 4 : 6}
            value={formik.values[props.name]}
            placeholder={t('Vip.WriteToDealer.Message')}
            onChange={onChange}
            margin={'dense'}
          />
        </Box>
      </Box>
      <DefaultInputError error={hasError} />
    </>
  );
};

interface CustomWriteToDealerCheckboxFieldProps {
  text: string;
  name: string;
  title?: string;
}
const CTA = styled(Button)({
  borderRadius: '5px',
  fontSize: '18px',
  lineHeight: '30px',
  border: '1px solid #1066B6',
  padding: '10px 30px',
  whiteSpace: 'nowrap',
});
const CustomWriteToDealerCheckboxField = (
  props: CustomWriteToDealerCheckboxFieldProps,
) => {
  const formik = useFormikContext();
  const [field, meta, helpers] = useField(props.name!);
  const { t } = useTranslation();

  const onCheckboxClicked = React.useCallback(() => {
    const currentValue = formik.values[props.name];
    formik.setFieldValue(props.name, currentValue ? false : true);
  }, [formik, props.name]);

  const bgColor = Colors.Background.VipWriteToSellerCheckBoxModal;

  const hasError = meta.touched && meta.error;
  return (
    <>
      <Box
        bgcolor={hasError ? Colors.ErrorBackGround : bgColor}
        border={hasError ? '2px solid ' + Colors.Error : '2px solid ' + bgColor}
        borderRadius="3px"
        padding={1}>
        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}>
          <FormGroup>
            <FormControlLabel
              onChange={onCheckboxClicked}
              label={
                <>
                  {props.title && (
                    <Typography fontSize={13} fontWeight={700}>
                      {t(props.title)}
                    </Typography>
                  )}
                  <Typography fontSize={13}>{t(props.text)}</Typography>
                </>
              }
              control={
                <Checkbox
                  icon={
                    <SvgIcon>
                      <rect
                        rx="1"
                        stroke="rgba(0, 0, 0, 0.6)"
                        fill="#fff"
                        x="4"
                        y="4"
                        width="16"
                        height="16"
                        strokeWidth="2"
                      />
                    </SvgIcon>
                  }
                  checked={formik.values[props.name] as boolean}
                  sx={{
                    color: hasError ? Colors.Error : 'none',
                  }}
                />
              }
            />
          </FormGroup>
        </Box>
      </Box>
      <DefaultInputError error={hasError} />
    </>
  );
};

interface CustomOptionCheckboxFieldProps {
  text: string;
  name: string;
  onChange?: (value: boolean) => void;
}

const CustomOptionCheckboxField = (props: CustomOptionCheckboxFieldProps) => {
  const formik = useFormikContext();
  const [field, meta, helpers] = useField(props.name!);
  const { t } = useTranslation();

  const onCheckboxClicked = React.useCallback(() => {
    const currentValue = formik.values[props.name];
    const newValue = currentValue ? false : true;
    formik.setFieldValue(props.name, newValue);
    if (props.onChange) {
      props.onChange(newValue);
    }
  }, [formik, props]);

  const bgColor = 'none';

  const hasError = meta.touched && meta.error;
  return (
    <>
      <Box
        bgcolor={hasError ? Colors.ErrorBackGround : bgColor}
        border={hasError ? '2px solid ' + Colors.Error : '2px solid ' + bgColor}
        borderRadius="3px">
        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}>
          <FormGroup>
            <FormControlLabel
              onChange={onCheckboxClicked}
              label={t(props.text) as string}
              sx={{
                marginRight: 1,
                '.MuiFormControlLabel-label': {
                  lineHeight: '100%',
                },
              }}
              control={
                <Checkbox
                  icon={
                    <SvgIcon>
                      <rect
                        rx="1"
                        stroke="rgba(0, 0, 0, 0.6)"
                        fill="#fff"
                        x="4"
                        y="4"
                        width="16"
                        height="16"
                        strokeWidth="2"
                      />
                    </SvgIcon>
                  }
                  checked={formik.values[props.name] as boolean}
                  sx={{
                    color: hasError ? Colors.Error : 'none',
                    marginRight: 0,
                    paddingRight: 0.5,
                    lineHeight: '5px',
                  }}
                />
              }
            />
          </FormGroup>
        </Box>
      </Box>
      <DefaultInputError error={hasError} />
    </>
  );
};

interface PropsFromParent {
  item?: ClassifiedItem;
  onClose: React.MouseEventHandler<HTMLImageElement>;
  alternatives: ClassifiedItem[];
  displayGreenbow: boolean;
  greenbowAccepted: boolean;
  greenbowAcceptedSecondStep: boolean;
  isModal: boolean;
  sendToGreenbow: () => void;
  isTestDrive: boolean;
}
const MessageSend = (props: PropsFromParent) => {
  const { item, isTestDrive } = props;
  const { t } = useTranslation();
  const { isMobile } = useScreenSizeContext();
  const router = useRouter();
  const alternativeItem =
    props.alternatives && props.alternatives.length !== 0
      ? props.alternatives.at(0)
      : undefined;

  const isToyota = item?.make === 'Toyota';

  const displayOffer =
    item?.systemData.salesType === SalesType.Sell &&
    item?.owner.profileType === UserProfileType.Company;

  const handleOfferOnClick = () => {
    const licensePlateType = item?.systemData.category.includes('Varevogn')
      ? ''
      : 'white';
    const make = item?.make ?? '';
    const model = item?.model ?? '';
    const variant = item?.variant ?? '';
    const year = item?.year ?? '';

    const linkWithUtm = `https://www.samlino.dk/bilforsikring?licensePlateType=${licensePlateType}&carBrand=${make}&carModel=${model}&carVintage=${year}&carEngineVariant=${variant}&knowLicensePlateNum=false&utm_source=bilhandel&utm_medium=cpc&utm_campaign=ciweek44_23&products=car&single-product-journey=true`;
    OpenExternalLink(linkWithUtm);
  };

  const goToAll = useCallback(() => {
    TrackEvent('see-all-modal-button-clicked');
    router.push(
      `/s/alle-biler/${slugify(alternativeItem.make, slugifyOptions)}/${slugify(
        alternativeItem.model,
        slugifyOptions,
      )}`,
    );
  }, [router, alternativeItem]);

  return (
    <Box>
      <Box
        display={'flex'}
        width={'100%'}
        flexDirection={'row'}
        justifyContent={'space-between'}>
        <Box />
        {props.onClose && <DialogClose onClose={props.onClose} />}
      </Box>

      <Box
        marginBottom={2}
        bgcolor={!props.isModal ? 'rgb(242, 246, 248)' : Colors.White}
        borderRadius={2}>
        <Box
          sx={{
            minHeight: '150px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}>
          <Typography paddingBottom={1} variant={'h6'}>
            {t('Vip.WriteToDealer.MessageSent')}
          </Typography>
          <Image
            height={70}
            width={70}
            alt="Tjek"
            style={{
              verticalAlign: 'middle',
            }}
            src={svgCheckmarkBoxed.src}
          />

          {isTestDrive && (
            <>
              <Typography
                variant="body2"
                marginBottom={0}
                marginTop={2}
                marginX={2}>
                Tak for at du har bestilt en prøvekørsel, forhandleren går nu i
                gang med at gøre klar til at du kommer
              </Typography>
              <ol>
                <li>
                  Du bliver kontaktet for at lave en endelig aftale omkring det
                  præcise tidspunkt for prøvekørslen
                </li>
                <li>Dokumenter og prøveplader klargøres</li>
                <li>Bilen bliver optanket/opladet til en god prøvetur</li>
                <li>
                  Bilen holder klar når du ankommer, så du undgår ventetid”
                </li>
              </ol>
            </>
          )}
        </Box>
      </Box>

      <Grid
        justifyContent={
          (props.greenbowAccepted && !props.greenbowAcceptedSecondStep) ||
          !props.displayGreenbow
            ? 'center'
            : 'initial'
        }
        container
        spacing={2}>
        {(displayOffer || isToyota) && (
          <Grid item xs={!props.isModal || isMobile ? 12 : 6}>
            <Box
              height={'100%'}
              bgcolor={!props.isModal ? 'rgb(242, 246, 248)' : Colors.White}
              borderRadius={2}
              padding={2}>
              {!isToyota && displayOffer && (
                <Box
                  sx={{
                    minHeight: '150px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                  }}>
                  <Image
                    height={87}
                    alt="Greenbow"
                    style={{
                      verticalAlign: 'middle',
                    }}
                    src={svgUmbrella.src}
                  />
                  <Typography
                    variant={'h6'}
                    sx={{ fontSize: '17px' }}
                    align="center">
                    Undgå at betale for meget i bilforsikring
                  </Typography>
                  <Typography
                    variant={'body1'}
                    sx={{ fontSize: '13px' }}
                    marginTop={1}
                    align="center">
                    Sammenlign forsikringer fra mange selskaber og finde den
                    bedste pris.
                  </Typography>
                  <Box marginTop={2} justifyContent={'center'} display={'flex'}>
                    <Button
                      onClick={handleOfferOnClick}
                      color={'secondary'}
                      style={{
                        paddingLeft: 60,
                        paddingRight: 60,
                        maxWidth: '100%',
                      }}>
                      Find den billigste forsikring
                    </Button>
                  </Box>
                </Box>
              )}

              {isToyota && (
                <Box
                  sx={{
                    minHeight: '150px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                  }}>
                  <Typography variant={'h6'} sx={{ fontSize: '17px' }}>
                    Toyota Relax – serviceaktiveret garanti til bilen fylder 10
                    år
                  </Typography>
                  <Typography
                    variant={'body1'}
                    sx={{ fontSize: '13px' }}
                    marginTop={1}>
                    Hvis du køber service på et aut. Toyota-værksted, får du
                    automatisk 12 mdr. garanti på din Toyota - helt indtil den
                    fylder 10 år eller når 185.000 km (hvad der kommer først).
                  </Typography>
                  <Box marginTop={2}>
                    <Button
                      color={'secondary'}
                      fullWidth={true}
                      href="https://www.toyota.dk/toyota-ejere/om-toyota-service/derfor-toyota-service/toyota-garanti/toyota-relax?utm_source=n&utm_medium=web&utm_campaign=tjekbil&utm_id=tjekq2"
                      target="_blank">
                      Læs mere om Relax
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        )}
        {(!props.greenbowAccepted || props.greenbowAcceptedSecondStep) &&
          props.displayGreenbow && (
            <Grid item xs={!props.isModal || isMobile ? 12 : 6}>
              <Box
                height={'100%'}
                bgcolor={!props.isModal ? 'rgb(242, 246, 248)' : Colors.White}
                borderRadius={2}
                padding={2}>
                <Box
                  sx={{
                    minHeight: '150px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                  }}>
                  <Image
                    height={29}
                    alt="Greenbow"
                    style={{
                      verticalAlign: 'middle',
                    }}
                    src={svgGreenbow.src}
                  />
                  {props.greenbowAcceptedSecondStep ? (
                    <Typography
                      marginTop={1}
                      sx={{ fontSize: '17px' }}
                      variant={'h6'}>
                      Du bliver kontaktet af Greenbow!
                    </Typography>
                  ) : (
                    <>
                      <Typography
                        marginTop={1}
                        sx={{ fontSize: '17px' }}
                        variant={'h6'}>
                        Få gratis rådgivning om ladeløsning
                      </Typography>
                      <Typography sx={{ fontSize: '13px' }}>
                        Greenbow kontakter dig, og rådgiver gratis og
                        uforpligtende om ladeløsninger. De tilbyder bl.a. strøm
                        til indkøbspris og har 30 dages installationsgaranti.
                      </Typography>
                      <Box marginY={1} width={200}>
                        <Button
                          color={'secondary'}
                          fullWidth={true}
                          onClick={props.sendToGreenbow}>
                          Ja tak, hjælp mig
                        </Button>
                      </Box>
                      <Typography sx={{ fontSize: '12px' }}>
                        Jeg giver samtykke til at Greenbow må kontakte mig på
                        telefon og/eller sms og e-mail med rådgivning og tilbud
                        fra Greenbow. Jeg kan til enhver tid tilbagekalde mit
                        samtykke ved at kontakte Greenbow direkte.
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
            </Grid>
          )}
      </Grid>

      {props?.alternatives !== undefined && props.alternatives.length !== 0 && (
        <Box marginTop={2}>
          <Divider />
          <Typography marginY={2} variant={'h6'} sx={{ fontSize: '17px' }}>
            Disse biler er måske også noget for dig
          </Typography>
          <Box onClick={props.onClose}>
            {isMobile &&
              props.alternatives.map((item, index) => {
                return (
                  <BasicCarListCard
                    eager={index === 0}
                    key={item.id}
                    origin={BasicCarCardType.AlternativesModal}
                    item={item}
                  />
                );
              })}
          </Box>
          {!isMobile && (
            <>
              <Grid
                container
                columnSpacing={2}
                onClick={props.onClose}
                rowSpacing={2}>
                {props.alternatives.map((item, index) => {
                  return (
                    <BasicCarGalleryCard
                      key={item.id}
                      origin={BasicCarCardType.AlternativesModal}
                      item={item}
                    />
                  );
                })}
              </Grid>
            </>
          )}
          <Box marginTop={2} display="flex" justifyContent={'center'}>
            <CTA
              fullWidth={isMobile}
              id={'modal-alternatives-see-all'}
              onClick={goToAll}
              color="secondary"
              title={`Se alle ${alternativeItem?.make} ${alternativeItem?.model}`}>
              Se alle {alternativeItem?.make} {alternativeItem?.model}
            </CTA>
          </Box>
        </Box>
      )}
    </Box>
  );
};
