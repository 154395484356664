/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImageCropCoordinatesModel,
    ImageCropCoordinatesModelFromJSON,
    ImageCropCoordinatesModelFromJSONTyped,
    ImageCropCoordinatesModelToJSON,
} from './ImageCropCoordinatesModel';

/**
 * 
 * @export
 * @interface ImageCropModel
 */
export interface ImageCropModel {
    /**
     * 
     * @type {string}
     * @memberof ImageCropModel
     */
    alias?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ImageCropModel
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageCropModel
     */
    height?: number;
    /**
     * 
     * @type {ImageCropCoordinatesModel}
     * @memberof ImageCropModel
     */
    coordinates?: ImageCropCoordinatesModel;
}

export function ImageCropModelFromJSON(json: any): ImageCropModel {
    return ImageCropModelFromJSONTyped(json, false);
}

export function ImageCropModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageCropModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'coordinates': !exists(json, 'coordinates') ? undefined : ImageCropCoordinatesModelFromJSON(json['coordinates']),
    };
}

export function ImageCropModelToJSON(value?: ImageCropModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alias': value.alias,
        'width': value.width,
        'height': value.height,
        'coordinates': ImageCropCoordinatesModelToJSON(value.coordinates),
    };
}

