/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MakeModelPickerPropertiesModel
 */
export interface MakeModelPickerPropertiesModel {
    /**
     * 
     * @type {string}
     * @memberof MakeModelPickerPropertiesModel
     */
    makeModel?: string | null;
}

export function MakeModelPickerPropertiesModelFromJSON(json: any): MakeModelPickerPropertiesModel {
    return MakeModelPickerPropertiesModelFromJSONTyped(json, false);
}

export function MakeModelPickerPropertiesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MakeModelPickerPropertiesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'makeModel': !exists(json, 'makeModel') ? undefined : json['makeModel'],
    };
}

export function MakeModelPickerPropertiesModelToJSON(value?: MakeModelPickerPropertiesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'makeModel': value.makeModel,
    };
}

