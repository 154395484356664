import { useVipContext } from '@/pages/[headline]/[[...id]]';
import { useRequestHelper } from '@/shared/hooks/request-helper';
import { TestDriveSlotsResponse } from '@/shared/lib-api';
import { useApi } from '@/shared/util/api';
import { LoadingState } from '@/shared/util/general-helpers';
import { Box, MenuItem, Select, Skeleton, Typography } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import DefaultInputError from '../default-components/input/defaults/default-input-error';
import { DefaultInputWrapper } from '../default-components/input/defaults/default-input-wrapper';
import { DefaultItemLabel } from '../default-components/input/defaults/default-item-label';

interface PickTestDriveTimeProps {
  name: string;
  //onChange?: (value: string) => void;
}

export const PickTestDriveTime = (props: PickTestDriveTimeProps) => {
  const { handleRequest } = useRequestHelper();
  const { dealerApi } = useApi();
  const { item } = useVipContext();
  const [_, meta] = useField(props.name);

  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormikContext();
  const [testDriveSlots, setTestDriveSlots] =
    useState<TestDriveSlotsResponse | null>();

  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedTime, setSelectedTime] = useState<string>('');

  const onDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedTime('');
    setSelectedDate(event.target.value);
    formik.setFieldValue(props.name, '');
  };

  const onTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedTime(event.target.value);
    const value =
      event.target.value !== '' ? `${selectedDate} ${event.target.value}` : '';
    formik.setFieldValue(props.name, value);
  };

  useEffect(() => {
    (async () => {
      if (item?.owner?.id) {
        setIsLoading(true);
        let request = dealerApi.apiDealerIdTestDriveSlotsGet({
          id: item.owner.id,
        });
        let [result, errorAndState] = await handleRequest(request);

        if (errorAndState.state === LoadingState.Success) {
          setTestDriveSlots(result);
        }
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.owner.id]);

  if (isLoading && !testDriveSlots) {
    return <></>;
  }

  const currentSelectedDate = testDriveSlots?.dates.find(
    d => d.dateLabel == selectedDate,
  );

  const error = meta.touched && meta.error;

  return (
    <>
      <Box display={'flex'} gap={2}>
        <Box width={1}>
          <DefaultItemLabel header={'Ønsket dato'} />
          <DefaultInputWrapper>
            <Select
              displayEmpty
              value={selectedDate}
              onChange={onDateChange}
              fullWidth
              MenuProps={{
                sx: {
                  maxHeight: {
                    sm: '300px',
                    variant: 'selectedMenu',
                  },
                },
              }}>
              <MenuItem value="">Vælg dato</MenuItem>
              {testDriveSlots?.dates.map(x => (
                <MenuItem key={x.dateLabel} value={x.dateLabel}>
                  {x.dateLabel}
                </MenuItem>
              ))}
            </Select>
          </DefaultInputWrapper>
        </Box>
        <Box width={1}>
          <DefaultItemLabel header={'Ønsket tidspunkt'} />
          <DefaultInputWrapper>
            <Select
              displayEmpty
              value={selectedTime}
              onChange={onTimeChange}
              fullWidth
              MenuProps={{
                sx: {
                  maxHeight: {
                    sm: '300px',
                    variant: 'selectedMenu',
                  },
                },
              }}>
              <MenuItem value="">Vælg tidspunkt</MenuItem>
              {currentSelectedDate &&
                currentSelectedDate.timeSlots?.map((x, index) => (
                  <MenuItem key={x} value={x.toString()}>
                    {x}
                  </MenuItem>
                ))}
            </Select>
          </DefaultInputWrapper>
        </Box>
      </Box>
      <DefaultInputError error={error} />
      <Typography color={'#3F3F3F'} marginTop={1} fontSize={12}>
        <Typography color={'#3F3F3F'} component={'span'} fontWeight={'bold'}>
          Bemærk:
        </Typography>{' '}
        Dette er kun en forespørgsel. Forhandleren kontakter dig snarest.
      </Typography>
    </>
  );
};
