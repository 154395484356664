import React, { useEffect, useState } from 'react';
import { DealerSearchFilters } from './dealer-search-filters';
import { DealerCard } from './dealer-card';
import { Container } from '@mui/material';
import { DealerPageProps } from '@/pages/forhandler/[dealer]/[city]/[id]/[[...slug]]';
import { DealerSearchFilters as SearchFilters } from '@/pages/forhandler/[dealer]/[city]/[id]/[[...slug]]';
import { getDealerVipFilteredUrl } from '@/web/util/helpers/url-helpers';
import { useRouter } from 'next/router';
import { DealerSearchResult } from './dealer-search-result';
import { DealerSearchPagination } from './dealer-search-pagination';
import DealerSearchSort from './dealer-search-sort';
import { DealerBreadcrumb } from './dealer-breadcrumb';
import { useTranslation } from 'react-i18next';
import { useApi } from '@/shared/util/api';
import { SearchPageAttribute } from '@/shared/lib-api';

export const DealerContent = (props: DealerPageProps) => {
  const router = useRouter();
  const { t } = useTranslation();
  const handleOnChangeFilter = (filters: SearchFilters) => {
    var url = getDealerVipFilteredUrl(
      props.dealer.name,
      props.dealer.city,
      props.dealer.zipCode,
      props.dealer.id,
      filters,
    );
    router.push(url);
  };

  const handlePageChange = (page: number) => {
    var url = getDealerVipFilteredUrl(
      props.dealer.name,
      props.dealer.city,
      props.dealer.zipCode,
      props.dealer.id,
      props.searchFilters,
      page,
      props.sort,
    );

    router.push(url);
  };

  const handleSortChange = (sort: string) => {
    var url = getDealerVipFilteredUrl(
      props.dealer.name,
      props.dealer.city,
      props.dealer.zipCode,
      props.dealer.id,
      props.searchFilters,
      1,
      sort,
    );

    router.push(url);
  };

  const getPageUrl = function (page: number): string {
    return getDealerVipFilteredUrl(
      props.dealer.name,
      props.dealer.city,
      props.dealer.zipCode,
      props.dealer.id,
      props.searchFilters,
      page,
    );
  };

  const [makes, setMakes] = useState<SearchPageAttribute[]>([]);
  const { dealerApi } = useApi();
  useEffect(() => {
    dealerApi.apiDealerMakesGet({ id: props.dealer.id }).then(response => {
      setMakes(response);
    });
  }, []);

  return (
    <Container>
      <DealerBreadcrumb dealer={props.dealer} />

      <DealerCard dealer={props.dealer} searchResult={props.searchResult} />
      {props.dealer?.hasActiveClassifieds && (
        <>
          <DealerSearchFilters
            amountForSaleText={t('Dealer.AmountForSale', {
              amount: props.searchResult?.totalResults,
              dealerName: props.dealer.name,
              city: props.dealer.city,
            })}
            makes={makes}
            searchResult={props.searchResult}
            searchFilters={props.searchFilters}
            onChangeFilter={handleOnChangeFilter}
          />

          <DealerSearchSort
            sort={props.sort}
            onSortChange={handleSortChange}
            searchResult={props.searchResult}
          />

          <DealerSearchResult searchResult={props.searchResult} />

          <DealerSearchPagination
            pageNumber={props.page}
            totalResults={props.searchResult?.totalResults}
            getPageUrl={getPageUrl}
            onSelectPage={handlePageChange}
          />
        </>
      )}
    </Container>
  );
};
