import { useApi } from '@/shared/util/api';
import { Box, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

export const Facts = props => {
  const { classifiedApi } = useApi();
  const { classifiedId } = props;
  const [facts, setFacts] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const load = async () => {
      var facts = await classifiedApi.apiClassifiedFactsIdGet({
        id: classifiedId,
      });
      setFacts(facts);
      if (facts.length > 0) {
        setOpen(true);
        return setTimeout(() => {
          setOpen(false);
        }, 8000);
      }
      return null;
    };

    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classifiedId]);

  return (
    <Box
      style={{
        transition: !open
          ? 'opacity 0.5s ease-in-out'
          : 'opacity 2s ease-in-out',
        display: 'flex',
        opacity: open ? 100 : 0,
      }}
      maxWidth={300}
      alignItems={'center'}
      bgcolor={'rgba(76, 160, 187, 0.8)'}
      borderRadius={2}
      padding={2}
      paddingRight={0}>
      <Box>
        {facts.map((f, index) => {
          return (
            <Typography key={index} fontSize="14px" color={'#fff'}>
              <span dangerouslySetInnerHTML={{ __html: f }} />
            </Typography>
          );
        })}
      </Box>
      <Box>
        <IconButton
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setOpen(false);
          }}>
          <CloseIcon sx={{ color: '#fff' }} />
        </IconButton>
      </Box>
    </Box>
  );
};
