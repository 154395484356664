/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
     AuthorElementModelFromJSONTyped,
     FactElementModelFromJSONTyped,
     FaqElementModelFromJSONTyped,
     FaqSectionElementModelFromJSONTyped,
     FueltypePickerElementModelFromJSONTyped,
     HeadlineElementModelFromJSONTyped,
     LinkElementModelFromJSONTyped,
     ListeMaerkeModelElementModelFromJSONTyped,
     MakeModelPickerElementModelFromJSONTyped,
     MakeOverviewElementModelFromJSONTyped,
     RelatedArticlesElementModelFromJSONTyped,
     SEOElementModelFromJSONTyped,
     SektionElementModelFromJSONTyped,
     SektionFarvefordelingElementModelFromJSONTyped,
     SektionListElementModelFromJSONTyped,
     SektionLiveLeasingTextElementModelFromJSONTyped,
     SektionLiveSalesTextElementModelFromJSONTyped,
     TableOfContentsElementModelFromJSONTyped,
     VehicleCarrouselElementModelFromJSONTyped
} from './';

/**
 * 
 * @export
 * @interface IApiElementModel
 */
export interface IApiElementModel {
    /**
     * 
     * @type {string}
     * @memberof IApiElementModel
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof IApiElementModel
     */
    readonly contentType?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof IApiElementModel
     */
    readonly properties?: { [key: string]: any; };
}

export function IApiElementModelFromJSON(json: any): IApiElementModel {
    return IApiElementModelFromJSONTyped(json, false);
}

export function IApiElementModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IApiElementModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['contentType'] === 'author') {
            return AuthorElementModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'fact') {
            return FactElementModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'faq') {
            return FaqElementModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'faqSection') {
            return FaqSectionElementModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'fueltypePicker') {
            return FueltypePickerElementModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'headline') {
            return HeadlineElementModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'link') {
            return LinkElementModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'listeMaerkeModel') {
            return ListeMaerkeModelElementModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'makeModelPicker') {
            return MakeModelPickerElementModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'makeOverview') {
            return MakeOverviewElementModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'relatedArticles') {
            return RelatedArticlesElementModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'sEO') {
            return SEOElementModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'sektion') {
            return SektionElementModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'sektionFarvefordeling') {
            return SektionFarvefordelingElementModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'sektionList') {
            return SektionListElementModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'sektionLiveLeasingText') {
            return SektionLiveLeasingTextElementModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'sektionLiveSalesText') {
            return SektionLiveSalesTextElementModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'tableOfContents') {
            return TableOfContentsElementModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'vehicleCarrousel') {
            return VehicleCarrouselElementModelFromJSONTyped(json, true);
        }
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'contentType': !exists(json, 'contentType') ? undefined : json['contentType'],
        'properties': !exists(json, 'properties') ? undefined : json['properties'],
    };
}

export function IApiElementModelToJSON(value?: IApiElementModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

