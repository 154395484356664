/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IApiContentResponseModel,
    IApiContentResponseModelFromJSON,
    IApiContentResponseModelFromJSONTyped,
    IApiContentResponseModelToJSON,
} from './IApiContentResponseModel';
import {
    NyhederContentModel,
    NyhederContentModelFromJSON,
    NyhederContentModelFromJSONTyped,
    NyhederContentModelToJSON,
} from './NyhederContentModel';

/**
 * 
 * @export
 * @interface NyhederContentResponseModel
 */
export interface NyhederContentResponseModel extends IApiContentResponseModel {
    /**
     * 
     * @type {string}
     * @memberof NyhederContentResponseModel
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof NyhederContentResponseModel
     */
    readonly contentType?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof NyhederContentResponseModel
     */
    readonly properties?: { [key: string]: any; };
}

export function NyhederContentResponseModelFromJSON(json: any): NyhederContentResponseModel {
    return NyhederContentResponseModelFromJSONTyped(json, false);
}

export function NyhederContentResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NyhederContentResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...IApiContentResponseModelFromJSONTyped(json, ignoreDiscriminator),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'contentType': !exists(json, 'contentType') ? undefined : json['contentType'],
        'properties': !exists(json, 'properties') ? undefined : json['properties'],
    };
}

export function NyhederContentResponseModelToJSON(value?: NyhederContentResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...IApiContentResponseModelToJSON(value),
    };
}

