import { ClassifiedItem, SearchResult } from '@/shared/lib-api';
import React from 'react';
import { getClassifiedVipUrl } from '../url-helpers';
import {
  GetFullscreenImage,
  GetMediumImage,
} from '@/shared/util/helpers/image-helper';

export const GetStructuredSrpData = (result: SearchResult, host: string) => {
  var data = result.items
    .filter(p => p.images !== undefined && p.images.length > 1)
    .map(car => {
      let result = {
        '@context': 'http://schema.org',
        '@type': 'Product',
        name: car.headLine,
        description: car.description,
        brand: {
          '@type': 'Brand',
          name: car.make,
        },
        image: {
          contentUrl: GetMediumImage(car.images[0].folderId, car.images[0].id),
          '@type': 'ImageObject',
        },
        model: car.model,
        offers: {
          '@type': 'Offer',
          availability: 'http://schema.org/InStock',
          price: car.systemData.price,
          priceCurrency: 'DKK',
          sku: car.id,
        },
      };
      if (car.headLine !== undefined) {
        result['url'] = host + getClassifiedVipUrl(car.id, car.headLine);
      }
      return result;
    });
  return (
    // <script
    //   id="srp-structured-data"
    //   type="application/ld+json"
    //   dangerouslySetInnerHTML={{
    //     __html: JSON.stringify({
    //       '@context': 'http://schema.org',
    //       '@type': 'Car',
    //       name: car.headLine,
    //       vehicleTransmission: car.gearType,
    //       url: host + getClassifiedVipUrl(car.id, car.headLine),
    //       image: car.images[0].fullScreenSizeUrl,
    //       description: car.description,
    //       brand: {
    //         '@type': 'Thing',
    //         name: car.make,
    //       },
    //       offers: {
    //         '@type': 'Offer',
    //         availability: 'http://schema.org/InStock',
    //         price: car.systemData.price,
    //         priceCurrency: 'DKK',
    //         sku: car.id,
    //       },
    //       mileageFromOdometer: {
    //         '@type': 'QuantitativeValue',
    //         value: car.mileage,
    //       },
    //     }),
    //   }}
    // />
    <script
      id="srp-structured-data"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(data),
      }}
    />
  );
};

export const GetStructuredVipAggregateOfferData = (item: ClassifiedItem) => {
  var data = {
    '@context': 'http://schema.org',
    '@type': 'AggregateOffer',
    lowPrice: item.systemData.price,
    highPrice: item.systemData.price,
    priceCurrency: 'DKK',
    availability: 'http://schema.org/InStock',
  };
  return (
    <script
      id="vip-agggregateoffer-structured-data"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(data),
      }}
    />
  );
};

export const GetStructuredVipCarData = (item: ClassifiedItem) => {
  var data = {
    '@context': 'http://schema.org',
    '@type': 'Car',
    itemCondition: { '@type': 'OfferItemCondition', name: 'UsedCondition' },
    name: item.headLine,
    manufacturer: {
      '@type': 'Organization',
      name: item.make,
    },
    model: item.model,
    vehicleModelDate: item.yearOfManufacture,
    vehicleTransmission: item.transmission,
    offers: {
      '@type': 'Offer',
      price: item.systemData.price,
      priceCurrency: 'DKK',
      seller: {
        '@type': 'Organization',
        name: item.owner.name,
        telephone: 'item.owner.phone',
      },
    },
  };
  if (item.images.length > 1) {
    data['image'] = {
      '@type': 'ImageObject',
      contentUrl: GetFullscreenImage(
        item.images[0].folderId,
        item.images[0].id,
      ),
    };
  }
  if (item.mileage) {
    data['mileageFromOdometer'] = {
      '@type': 'QuantitativeValue',
      unitCode: 'KMT',
      value: item.mileage,
    };
  }

  return (
    <script
      id="vip-car-structured-data"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(data),
      }}
    />
  );
};
