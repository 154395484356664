import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface VipCollapseProps {
  collapsed: boolean;
  backgroundColor?: string;
  children: JSX.Element;
}

const faderContainerStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
};

const faderChildStyle = {
  maxHeight: '280px',
  overflow: 'hidden',
  position: 'relative',
  '::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: '260px',
    left: 0,
    width: '100%',
    height: '20px',

    zIndex: 2,
    pointerEvents: 'none',
  },
};

export const VipCollapse = (props: VipCollapseProps) => {
  const [collapsed, setCollapsed] = useState(true);
  useEffect(() => {
    setCollapsed(props.collapsed);
  }, [props]);

  return (
    <Box sx={collapsed ? faderContainerStyle : {}}>
      <Box
        sx={
          collapsed
            ? [
                faderChildStyle,
                {
                  '::after': {
                    backgroundImage: `linear-gradient(to bottom, ${
                      props.backgroundColor
                        ? props.backgroundColor + '50'
                        : 'rgba(255, 255, 255, 0.5)'
                    }, ${props.backgroundColor ?? 'white'})`,
                  },
                },
              ]
            : {}
        }>
        {props.children}
      </Box>
    </Box>
  );
};
