/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IApiElementModel,
    IApiElementModelFromJSON,
    IApiElementModelFromJSONTyped,
    IApiElementModelToJSON,
} from './IApiElementModel';
import {
    LinkPropertiesModel,
    LinkPropertiesModelFromJSON,
    LinkPropertiesModelFromJSONTyped,
    LinkPropertiesModelToJSON,
} from './LinkPropertiesModel';

/**
 * 
 * @export
 * @interface LinkElementModel
 */
export interface LinkElementModel extends IApiElementModel {
    /**
     * 
     * @type {LinkPropertiesModel}
     * @memberof LinkElementModel
     */
    properties?: LinkPropertiesModel;
}

export function LinkElementModelFromJSON(json: any): LinkElementModel {
    return LinkElementModelFromJSONTyped(json, false);
}

export function LinkElementModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkElementModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...IApiElementModelFromJSONTyped(json, ignoreDiscriminator),
        'properties': !exists(json, 'properties') ? undefined : LinkPropertiesModelFromJSON(json['properties']),
    };
}

export function LinkElementModelToJSON(value?: LinkElementModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...IApiElementModelToJSON(value),
        'properties': LinkPropertiesModelToJSON(value.properties),
    };
}

