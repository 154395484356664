import { BasicCarCardType } from '@/shared/hooks/basic-car-hook';
import { useAppSelector } from '@/shared/store/hooks';
import { DidNotFindResults } from '@/web/views/components/SRP/did-not-find-results';
import { Grid, Typography } from '@mui/material';
import { default as React } from 'react';
import { BasicCarGalleryCard } from '../cards/basic-car-card/basic-car-gallery-card';
import { NoResultsSrp } from './no-results-srp';
import { SRPGalleryModeResults } from './srp-gallery-mode-results';

export const SRPGalleryMode = () => {
  const searchResults = useAppSelector(
    state => state.srpReducer.searchData.items,
  );
  return (
    <>
      <SRPGalleryModeResults items={searchResults} />
      {searchResults.length === 0 && (
        <NoResultsSrp origin={BasicCarCardType.SRPGallery} />
      )}
      {searchResults.length > 0 && (
        <Grid marginTop={2} item xs={12}>
          <DidNotFindResults />
        </Grid>
      )}
    </>
  );
};
