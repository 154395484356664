/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RelatedArticle
 */
export interface RelatedArticle {
    /**
     * 
     * @type {string}
     * @memberof RelatedArticle
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedArticle
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedArticle
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelatedArticle
     */
    authorName?: string;
    /**
     * 
     * @type {Date}
     * @memberof RelatedArticle
     */
    updateDate?: Date;
}

export function RelatedArticleFromJSON(json: any): RelatedArticle {
    return RelatedArticleFromJSONTyped(json, false);
}

export function RelatedArticleFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelatedArticle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'authorName': !exists(json, 'authorName') ? undefined : json['authorName'],
        'updateDate': !exists(json, 'updateDate') ? undefined : (new Date(json['updateDate'])),
    };
}

export function RelatedArticleToJSON(value?: RelatedArticle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'path': value.path,
        'imageUrl': value.imageUrl,
        'authorName': value.authorName,
        'updateDate': value.updateDate === undefined ? undefined : (value.updateDate.toISOString()),
    };
}

