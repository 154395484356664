import { useBasicCar } from '@/shared/hooks/basic-car-hook';
import { ClassifiedItem } from '@/shared/lib-api';
import { useAppSelector } from '@/shared/store/hooks';
import { GetSmallImage } from '@/shared/util/helpers/image-helper';
import { DidNotFindResults } from '@/web/views/components/SRP/did-not-find-results';
import { Box, Grid } from '@mui/material';
import Link from 'next/link';
import { default as React, useEffect, useMemo, useRef } from 'react';

export const VideoCard = (props: { item: ClassifiedItem }) => {
  const { item } = props;
  const { carRoute } = useBasicCar({
    item: item,
  });
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const videoUrl = item.videos[0].videoUrl;
  const posterUrl =
    item.images.length > 0
      ? GetSmallImage(item.images[0].folderId, item.images[0].id)
      : null;

  useEffect(() => {
    const handlePlayVideo = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      const video = videoRef.current;

      if (video) {
        if (entry.isIntersecting) {
          video.play();
        } else {
          video.pause();
        }
      }
    };

    const observer = new IntersectionObserver(handlePlayVideo, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      observer.disconnect(); // Cleanup the observer when the component is unmounted
    };
  }, []);

  const showControls = () => 
  {
    const video = videoRef.current;
    if (video) {
      video.setAttribute('controls', '');
    }   
  }

  const removeControls = () => 
  {
    const video = videoRef.current;
    if (video) {
      video.removeAttribute('controls');
    }   
  }

  const handleVideoInteraction = (event: React.MouseEvent<HTMLVideoElement> | React.TouchEvent<HTMLVideoElement>): void => {
    // showControls();  
    // event.stopPropagation();
  };

  return (
    <Box>
      <Box
        sx={{ width: { xs: '100%', sm: '410px', md: '272px' } }}
        bgcolor={'#000'}
        display={'flex'}
        justifyContent={'center'}
        >
        <video
          onClick={handleVideoInteraction}
          onMouseDown={handleVideoInteraction}
          onTouchStart={handleVideoInteraction}
          onMouseEnter={handleVideoInteraction}
          muted={true}
          autoPlay={false}
          loop={true}
          controls={false}
          playsInline={true}
          poster={posterUrl}
          ref={videoRef}
          style={{ width: '100%' }}
          >
          <source src={videoUrl} type="video/mp4" />
        </video>
      </Box>
    </Box>
  );
};

const DefaultCard = (props: { item: ClassifiedItem }) => {
  const { item } = props;
  const { carRoute } = useBasicCar({
    item: item,
  });

  const imageUrl =
    item.images.length > 0
      ? GetSmallImage(item.images[0].folderId, item.images[0].id)
      : null;

  return (
    <Box>
      [IMG] {item.headLine}
      <br />
      <Link href={carRoute}>Se her...</Link>
      <Box
        sx={{ width: 272 }}
        bgcolor={'#000'}
        display={'flex'}
        justifyContent={'center'}>
        <img src={imageUrl} style={{ width: 272 }} />
      </Box>
    </Box>
  );
};

export const SRPVideoMode = () => {
  const videoRefs = useRef([]);

  const searchResults = useAppSelector(
    state => state.srpReducer.searchData.items,
  );

  const renderItem = (item: ClassifiedItem, index: number) => {
    const hasVideo = item.videos.length > 0;

    //return <Box>{item.videos.length}</Box>

    return (
      <Box padding={2} border={1}>
        {hasVideo ? <VideoCard item={item} /> : <DefaultCard item={item} />}
      </Box>
    );
  };

  return (
    <>
      <Box>{searchResults.map((res, index) => renderItem(res, index))}</Box>

      {searchResults.length > 0 && (
        <Grid item xs={12}>
          <DidNotFindResults />
        </Grid>
      )}
    </>
  );
};
