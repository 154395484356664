import React, { useCallback } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PadBox } from '../containers/pad-box';
import { SalesType } from '@/shared/lib-api';
import { thousandNumberSeperator } from '@/shared/util/general-helpers';
import Link from 'next/link';
import { Colors } from '@/shared/util/colors';
import { BuildInTrackEvents, TrackEvent } from '@/shared/util/gtm';
import { useVipContext } from '@/pages/[headline]/[[...id]]';

export const VipRelatedCarCard = () => {
  const { t } = useTranslation();
  const { item } = useVipContext();
  const onLinkClick = useCallback(() => {
    TrackEvent(BuildInTrackEvents.OnClassifiedRelatedClicked);
  }, []);
  return (
    <>
      {item?.systemData.relatedClassifiedId && (
        <>
          <PadBox bgColor={'#D0E8F0'}>
            <Grid container>
              <Grid item xs={12} md={'auto'}>
                <Typography
                  marginRight={2}
                  color={Colors.Primary}
                  fontWeight={500}
                  fontSize="15px"
                  lineHeight="26px">
                  {`Denne bil kan også ${
                    item.systemData.salesType === SalesType.Leasing
                      ? 'købes'
                      : 'leases'
                  } til ${thousandNumberSeperator(
                    item.systemData.relatedClassifiedPrice,
                  )} ${
                    item.systemData.salesType === SalesType.Leasing
                      ? 'kr'
                      : 'kr/mdr'
                  }`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={true}>
                <Typography
                  fontWeight={700}
                  color={Colors.Secondary}
                  fontSize="15px"
                  lineHeight="26px">
                  <Link
                    target="_blank"
                    href={`/redir/id-${item.systemData.relatedClassifiedId}`}
                    onClick={onLinkClick}>
                    Se annoncen
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </PadBox>
        </>
      )}
    </>
  );
};
