/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiBlockGridAreaModel,
    ApiBlockGridAreaModelFromJSON,
    ApiBlockGridAreaModelFromJSONTyped,
    ApiBlockGridAreaModelToJSON,
} from './ApiBlockGridAreaModel';
import {
    ApiBlockItemModel,
    ApiBlockItemModelFromJSON,
    ApiBlockItemModelFromJSONTyped,
    ApiBlockItemModelToJSON,
} from './ApiBlockItemModel';
import {
    IApiElementModel,
    IApiElementModelFromJSON,
    IApiElementModelFromJSONTyped,
    IApiElementModelToJSON,
} from './IApiElementModel';

/**
 * 
 * @export
 * @interface ApiBlockGridItemModel
 */
export interface ApiBlockGridItemModel {
    /**
     * 
     * @type {number}
     * @memberof ApiBlockGridItemModel
     */
    rowSpan?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiBlockGridItemModel
     */
    columnSpan?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiBlockGridItemModel
     */
    areaGridColumns?: number;
    /**
     * 
     * @type {Array<ApiBlockGridAreaModel>}
     * @memberof ApiBlockGridItemModel
     */
    areas?: Array<ApiBlockGridAreaModel>;
    /**
     * 
     * @type {IApiElementModel}
     * @memberof ApiBlockGridItemModel
     */
    content?: IApiElementModel;
    /**
     * 
     * @type {IApiElementModel}
     * @memberof ApiBlockGridItemModel
     */
    settings?: IApiElementModel;
}

export function ApiBlockGridItemModelFromJSON(json: any): ApiBlockGridItemModel {
    return ApiBlockGridItemModelFromJSONTyped(json, false);
}

export function ApiBlockGridItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiBlockGridItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rowSpan': !exists(json, 'rowSpan') ? undefined : json['rowSpan'],
        'columnSpan': !exists(json, 'columnSpan') ? undefined : json['columnSpan'],
        'areaGridColumns': !exists(json, 'areaGridColumns') ? undefined : json['areaGridColumns'],
        'areas': !exists(json, 'areas') ? undefined : ((json['areas'] as Array<any>).map(ApiBlockGridAreaModelFromJSON)),
        'content': !exists(json, 'content') ? undefined : IApiElementModelFromJSON(json['content']),
        'settings': !exists(json, 'settings') ? undefined : IApiElementModelFromJSON(json['settings']),
    };
}

export function ApiBlockGridItemModelToJSON(value?: ApiBlockGridItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rowSpan': value.rowSpan,
        'columnSpan': value.columnSpan,
        'areaGridColumns': value.areaGridColumns,
        'areas': value.areas === undefined ? undefined : ((value.areas as Array<any>).map(ApiBlockGridAreaModelToJSON)),
        'content': IApiElementModelToJSON(value.content),
        'settings': IApiElementModelToJSON(value.settings),
    };
}

