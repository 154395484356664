import { useRequestHelper } from '@/shared/hooks/request-helper';
import { ClassifiedStatus } from '@/shared/lib-api';
import { useAppDispatch, useAppSelector } from '@/shared/store/hooks';
import {
  removeOwnCar,
  setOwnCars,
  updateOwnCarActivity,
} from '@/shared/store/reducers/AuthSlice';
import { useApi } from '@/shared/util/api';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import { LoadingState } from '../general-helpers';
import { BuildInTrackEvents, TrackEvent } from '@/shared/util/gtm';
import { useVipContext } from '@/pages/[headline]/[[...id]]';

export function useClassifieds() {
  const {
    ownCarsState,
    activeCars,
    inactiveCars,
    deleteCar,
    setCarActive,
    setCarInactive,
    requestState,
    handleRequest,
  } = useClassifiedActions();
  const dispatch = useAppDispatch();
  const { classifiedApi } = useApi();
  useEffect(() => {
    if (ownCarsState === LoadingState.None) {
      (async () => {
        let request = classifiedApi.apiClassifiedGetownclassifiedsGet();
        let [result, errorAndState] = await handleRequest(request);
        if (errorAndState.state === LoadingState.Success) {
          dispatch(setOwnCars(result));
        }
      })();
    }
  }, [ownCarsState, handleRequest, dispatch, classifiedApi]);

  return {
    activeCars,
    inactiveCars,
    deleteCar,
    setCarInactive,
    setCarActive,
    ownCarsState,
    requestState,
  };
}

export function useClassifiedActions(vip?: boolean) {
  const ownCarsState = useAppSelector(state => state.auth.ownCarsState);
  const ownCars = useAppSelector(state => state.auth.ownCars);
  const { handleRequest, requestState } = useRequestHelper();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { classifiedApi } = useApi();
  const { toggleActive } = useVipContext();

  const activeCars = useMemo(() => {
    return ownCars?.filter(
      p =>
        p.systemData.isActive || p.systemData.status === ClassifiedStatus.Limbo,
    );
  }, [ownCars]);

  const inactiveCars = useMemo(() => {
    return ownCars?.filter(
      p =>
        !p.systemData.isActive &&
        p.systemData.status !== ClassifiedStatus.Limbo,
    );
  }, [ownCars]);

  const deleteCar = useCallback(
    async (classifiedId: number) => {
      if (window.confirm('Er du sikker på du vil slette annoncen?')) {
        let request = classifiedApi.apiClassifiedChangestatePost({
          changeClassifiedStateRequest: {
            classifiedStatus: ClassifiedStatus.Deleted,
            classifiedId: classifiedId,
          },
        });
        let [result, errorAndState] = await handleRequest(request);
        if (errorAndState.state === LoadingState.Success) {
          dispatch(removeOwnCar(classifiedId));
          TrackEvent(BuildInTrackEvents.OnClassifiedDeleted);
        }
        if (vip) {
          router.push('/min-side/annoncer');
        }
      }
    },
    [classifiedApi, dispatch, handleRequest, vip, router],
  );

  const updateCarStatus = useCallback(
    async (classifiedId: number, status: ClassifiedStatus) => {
      let request = classifiedApi.apiClassifiedChangestatePost({
        changeClassifiedStateRequest: {
          classifiedStatus: status,
          classifiedId: classifiedId,
        },
      });
      let [result, errorAndState] = await handleRequest(request);
      if (errorAndState.state === LoadingState.Success) {
        dispatch(updateOwnCarActivity(classifiedId));
      }
      if (vip) {
        toggleActive();
      }
    },
    [classifiedApi, dispatch, toggleActive, handleRequest, vip],
  );

  const setCarActive = useCallback(
    async (classifiedId: number) => {
      await updateCarStatus(classifiedId, ClassifiedStatus.Active);
      TrackEvent(BuildInTrackEvents.OnClassifiedActive);
    },
    [updateCarStatus],
  );

  const setCarInactive = useCallback(
    async (classifiedId: number) => {
      await updateCarStatus(classifiedId, ClassifiedStatus.Inactive);
      TrackEvent(BuildInTrackEvents.OnClassifiedInActive);
    },
    [updateCarStatus],
  );

  return {
    activeCars,
    inactiveCars,
    deleteCar,
    setCarInactive,
    setCarActive,
    ownCarsState,
    requestState,
    handleRequest,
  };
}
