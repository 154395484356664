import React from 'react';
import { Box, Breadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MuiLink from '@mui/material/Link';
import { Colors } from '@/shared/util/colors';
import { GetSrpUrl } from '@/web/util/helpers/srp-url-helpers';
import { useVipContext } from '@/pages/[headline]/[[...id]]';

export const VipBreadcrumbs = () => {
  const { item } = useVipContext();

  const makeUrl = GetSrpUrl('alle-biler', item.make);
  const modelGroupUrl = GetSrpUrl('alle-biler', item.make, item.modelGroup);
  const modelUrl = GetSrpUrl('alle-biler', item.make, item.model);
  const variantUrl = GetSrpUrl(
    'alle-biler',
    item.make,
    item.model,
    item.variant,
  );
  return (
    <Box>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          color: Colors.Secondary,
          '& ol': {
            '& li': {
              margin: '0px',
            },
          },
        }}
        separator={<NavigateNextIcon fontSize="small" />}>
        <MuiLink
          id="breadcrumb-make"
          underline="none"
          color="inherit"
          href={makeUrl.toString()}>
          {item.make}
        </MuiLink>
        {item.modelGroup && (
          <MuiLink
            id="breadcrumb-modelgroup"
            underline="none"
            color="inherit"
            href={modelGroupUrl.toString()}>
            {item.modelGroup}
          </MuiLink>
        )}
        <MuiLink
          id="breadcrumb-model"
          underline="none"
          color="inherit"
          href={modelUrl.toString()}>
          {item.model}
        </MuiLink>
        <MuiLink
          id="breadcrumb-variant"
          underline="none"
          color="inherit"
          href={variantUrl.toString()}>
          {item.alternativeVariant}
        </MuiLink>
      </Breadcrumbs>
    </Box>
  );
};
