import { useCallback, useMemo, useState } from 'react';
import { useApi } from '../util/api';
import { useAuth } from './auth-hook';
import {
  BuildInTrackEvents,
  TrackClassifiedItemEvent,
  TrackEvent,
} from '@/shared/util/gtm';
import { ClassifiedItem, UserProfileType } from '../lib-api';
import { LoadingState } from '../util/general-helpers';
import { useVipContext } from '@/pages/[headline]/[[...id]]';
import { useSrpContext } from '@/web/views/components/SRP/srp';
import { useDealerVipContext } from '@/pages/forhandler/[dealer]/[city]/[id]/[[...slug]]';
import { useFrontPageContext } from '@/pages';

export function useFavourite(item: ClassifiedItem) {
  const [loading, setLoading] = useState<LoadingState>(LoadingState.None);
  const contextVip = useVipContext();
  const contextSrp = useSrpContext();
  const contextDealerVip = useDealerVipContext();
  const frontPageContext = useFrontPageContext()

  const {
    favorites: favorites,
    addToFavorites,
    removeFromFavorites,
  } = useAuth();

  const isCurrentlyLiked = useMemo(() => {
    return (
      item &&
      favorites &&
      favorites.some(favorite => favorite.classifiedId === item.id)
    );
  }, [favorites, item]);

  const { likeApi, authApi } = useApi();

  const add = useCallback(async (askForContactConsent: boolean = true) => {
    if (item) {
      setLoading(LoadingState.Loading);
      addToFavorites(item.id);
      await likeApi.apiLikeFavoritePost({
        favoriteRequest: {
          isFavorite: true,
          classifiedId: item.id,
        },
      });
      setLoading(LoadingState.Success);
      TrackClassifiedItemEvent(BuildInTrackEvents.OnClassifiedFavorited, item);

      if(askForContactConsent && item?.owner?.profileType === UserProfileType.Company)
      {
        const user = await authApi.apiAuthGetuserPost();
        if(!user.permissions?.consentForDealerContactOnFavorites)
        {
          if(contextVip?.askForContactConsent) { contextVip.askForContactConsent(); }
          else if(contextSrp?.askForContactConsent) { contextSrp.askForContactConsent(item.id); }
          else if(contextDealerVip?.askForContactConsent) { contextDealerVip.askForContactConsent(item.id); }
          else if(frontPageContext?.askForContactConsent) { frontPageContext.askForContactConsent(item.id); }
        }
      }
    }
  }, [likeApi, addToFavorites, item]);

  const remove = useCallback(async () => {
    if (item) {
      setLoading(LoadingState.Loading);
      removeFromFavorites(item.id);
      await likeApi.apiLikeFavoritePost({
        favoriteRequest: {
          isFavorite: false,
          classifiedId: item.id,
        },
      });
      setLoading(LoadingState.Success);
      TrackEvent(BuildInTrackEvents.OnClassifiedUnFavorited);
    }
  }, [likeApi, item, removeFromFavorites]);

  return {
    addLike: add,
    removeLike: remove,
    isCurrentlyLiked,
    loading,
  };
}
