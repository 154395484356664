import { SrpViewMode, useSrp } from '@/shared/hooks/srp-hook';
import { Configuration, SearchApi } from '@/shared/lib-api';
import {
  SearchInitResponse,
  SearchPageFields,
  SearchResult,
  SeoUrl,
} from '@/shared/lib-api/models';
import {
  SetModelAttributes,
  SetSeoOverrides,
  SetSRPHeadline,
  SetSrpNoResultsSearchData,
  SetSrpSearchData,
  SetSrpSearchFields,
  SetSrpSearchOptions,
  SetSRPText,
} from '@/shared/store/reducers/SRPSlice';
import { DidFindResults } from '@/web/views/components/SRP/did-find-results';
import { PaginationSRP } from '@/web/views/components/SRP/pagination-srp';
import SearchContent from '@/web/views/components/SRP/search-content';
import { SelectTopBarSRP } from '@/web/views/components/SRP/select-top-bar-srp';
import { SRPGalleryMode } from '@/web/views/components/SRP/srp-gallery-mode';
import { SRPListMode } from '@/web/views/components/SRP/srp-list-mode';
import { SRPSubTopBar } from '@/web/views/components/SRP/srp-sub-top-bar';
import { SRPTopBar } from '@/web/views/components/SRP/srp-top-bar';
import { Box, Container, Grid } from '@mui/material';
import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { BottomLinksSrp } from '@/web/views/components/SRP/bottom-links-srp';
import Head from 'next/head';
import { useAppSelector } from '@/shared/store/hooks';
import {
  GetCanonicalPageLink,
  GetNextPageLink,
  GetPreviousPageLink,
} from '@/web/util/helpers/seo-srp-helpers/canonical-helpers';
import { GetNoIndexMeta } from '@/web/util/helpers/seo-srp-helpers/noindex-helpers';
import { GetStructuredSrpData } from '@/web/util/helpers/seo-srp-helpers/structureddata-helpers';
import { useScreenSizeContext, useSiteSettingContext } from '@/pages/_app';
import {
  GetMetaTitle,
  GetOgTitle,
} from '@/web/util/helpers/seo-srp-helpers/title-meta-helpers';
import TagManager from 'react-gtm-module';
import {
  GetMetaDescription,
  GetOgDescription,
} from '@/web/util/helpers/seo-srp-helpers/description-meta-helpers';
import { GetOgImage } from '@/web/util/helpers/seo-srp-helpers/og-image-helpers';
import dynamic from 'next/dynamic';
import SrpBottomCard from '../cards/generic/srp-bottom-card';
import ContactConsentModal from '../modals/contactConsentModal';
import { ContentApi, Configuration as CMSConfig } from '@/shared/lib-api-cms';
import { GetServerSidePropsContext } from 'next';
import { ParsedUrlQuery } from 'querystring';

const SamlinoSrpCard = dynamic(
  () =>
    import('@/web/views/components/advertisement/samlino/srp-card/srp-card'),
  { suspense: false, ssr: false },
);
const ResursSrpCard = dynamic(
  () => import('@/web/views/components/advertisement/resurs/srp-card/srp-card'),
  { suspense: false, ssr: false },
);
const FdmSrpCard = dynamic(
  () => import('@/web/views/components/advertisement/fdm/srp-card/srp-card'),
  { suspense: false, ssr: false },
);

interface SrpContextType {
  askForContactConsent?: (classifiedId: number) => void;
}

const SrpContext = createContext<SrpContextType | undefined>(undefined);
export const useSrpContext = () => {
  return useContext(SrpContext);
};

const SRP = () => {
  const router = useRouter();
  const { viewMode, loadingState, setViewMode } = useSrp({
    router: router,
    updateUrlOnChange: true,
  });
  const { isMobile } = useScreenSizeContext();
  const [showFiltersMobile, setShowFiltersMobile] = useState(false);
  const searchOptions = useAppSelector(state => state.srpReducer.searchOptions);
  const seoOverride = useAppSelector(state => state.srpReducer.seoOverride);
  const cmsInfo = useAppSelector(state => state.srpReducer.cmsInfo);
  const searchResults = useAppSelector(state => state.srpReducer.searchData);
  const { hostname } = useSiteSettingContext();
  const [showContactConsent, setShowContactConsent] = useState({
    show: false,
    classifedId: 0,
  });

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'DL_CLASSIFED_SRP',
        dlv_pagetype: 'classified_srp',
      },
    });
  }, []);

  useEffect(() => {
    if (router.asPath.includes('/s/') && !router.asPath.includes('&link=yes')) {
      let link = router.asPath.includes('?')
        ? `${router.asPath.replace('&link=yes', '')}&link=yes`
        : `${router.asPath
            .replace('&link=yes', '')
            .replace('?link=yes', '')}?link=yes`;
      router.replace(link, undefined, {
        shallow: true,
      });
    }
  }, []);

  useEffect(() => {
    if (searchResults?.items?.length > 0) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'DL_RESULT_SRP',
          dlv_srp_result: searchResults.items.map(c => c.id),
        },
      });
    }
  }, [searchResults?.items]);

  useEffect(() => {
    if (searchOptions.searchQuery) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'DL_SEARCH_SRP',
          dlv_srp_search_query: searchOptions.searchQuery,
        },
      });
    }
  }, [searchOptions?.searchQuery]);

  let view =
    viewMode === SrpViewMode.List || isMobile ? (
      <SRPListMode loadingState={loadingState} />
    ) : (
      <SRPGalleryMode />
    );
  const srpHeadline = useAppSelector(state => state.srpReducer.srpHeadline);

  return (
    <>
      <Head>
        {GetStructuredSrpData(searchResults, hostname)}
        {GetPreviousPageLink(searchOptions, router.asPath)}
        {GetNextPageLink(
          searchOptions,
          router.asPath,
          searchResults.totalResults,
        )}
        {GetCanonicalPageLink(searchOptions, router.asPath, hostname)}
        {GetNoIndexMeta(
          searchOptions,
          router.asPath,
          searchResults.totalResults,
          srpHeadline != '' && !router.asPath.includes('/s/'),
        )}
        {GetOgTitle(searchOptions, searchResults.totalResults, seoOverride)}
        {GetMetaTitle(searchOptions, searchResults.totalResults, seoOverride)}
        {GetMetaDescription(searchOptions, seoOverride)}
        {GetOgDescription(searchOptions, seoOverride)}
        {GetOgImage(searchResults)}
      </Head>
      <SRPTopBar isSRP />
      <SRPSubTopBar />
      <Container>
        <SrpContext.Provider
          value={{
            askForContactConsent: (classifiedId: number) => {
              setShowContactConsent({ show: true, classifedId: classifiedId });
            },
          }}>
          <ContactConsentModal
            open={showContactConsent.show}
            onClose={() =>
              setShowContactConsent({ show: false, classifedId: 0 })
            }
            classifiedId={showContactConsent.classifedId}
          />
          <Grid
            marginTop={{ xs: '0px', lg: '54px' }}
            container
            columnSpacing={3}>
            <Grid item xs={12} md={3}>
              <SearchContent
                mode="sidebar"
                showFilters={showFiltersMobile}
                setShowFilters={setShowFiltersMobile}
              />
            </Grid>
            {!showFiltersMobile && (
              <Grid item xs={12} md={9}>
                <Box>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box
                        borderRadius={2.5}
                        bgcolor={'#fff'}
                        paddingX={{ xs: '11px', lg: 3 }}
                        paddingTop={{ xs: '20px', lg: 2 }}
                        paddingBottom={{ xs: '11px', lg: 2 }}>
                        <DidFindResults loadingState={loadingState} />
                      </Box>
                    </Grid>
                    <Grid item xs={12} paddingTop={2}>
                      {!isMobile && (
                        <SelectTopBarSRP
                          viewMode={viewMode}
                          setViewMode={setViewMode}
                        />
                      )}
                    </Grid>

                    {view}

                    <Grid item xs={12}>
                      <PaginationSRP />
                    </Grid>
                    {cmsInfo && cmsInfo.makeId !== 0 && (
                      <Grid item xs={12} paddingTop={2}>
                        <SrpBottomCard info={cmsInfo} />
                      </Grid>
                    )}
                    <Grid item xs={12} paddingTop={2}>
                      <ResursSrpCard />
                    </Grid>
                    <Grid item xs={12} paddingTop={2}>
                      <SamlinoSrpCard />
                    </Grid>
                    <Grid item xs={12} paddingTop={2}>
                      <FdmSrpCard />
                    </Grid>
                  </Grid>
                </Box>
                <BottomLinksSrp />
              </Grid>
            )}
          </Grid>
        </SrpContext.Provider>
      </Container>
    </>
  );
};

export const getServerSidePropsForSRP = async (store, props) => {
  // PATH example: /brugte-biler/audi/a4/biltype-sedan/side-2?soegning=hejsa
  props.res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59',
  );
  const searchApi = new SearchApi(
    new Configuration({
      basePath: process.env.INTERNAL_SITE_API,
    }),
  );

  try {
    var cmsContentApi = new ContentApi(
      new CMSConfig({
        basePath: process.env.NEXT_PUBLIC_API_CMS_BASE_PATH,
      }),
    );
    const url = props.resolvedUrl.replace('/s', '').split('?')[0];
    let customEndpoint = await cmsContentApi.getContentItemByPath({
      path: url,
      apiKey: 'asgjopok142190vami3nc',
    });
    if (customEndpoint) {
      customEndpoint =
        customEndpoint.contentType == 'sRP' ? customEndpoint : undefined;
    }
    if (customEndpoint) {
      let searchagentId = customEndpoint.properties.searchagent.split('|')[0];
      let searchInitResponse =
        await searchApi.apiSearchInitcustomfromserverPost({
          searchInitRequest: { url: url },
          searchagentId: searchagentId,
        });
      const result = await handleSrp(searchInitResponse, props, store);
      const seoData: SeoUrl = {
        ...searchInitResponse.seoData,
        title: customEndpoint.properties.title,
        metaDescription: customEndpoint.properties.metaDescription,
      };
      store.dispatch(SetSeoOverrides(seoData));
      store.dispatch(SetSRPHeadline(customEndpoint.properties.header));
      store.dispatch(SetSRPText(customEndpoint.properties.text));
      return result;
    }
  } catch {}
  try {
    const searchInitResponse = await searchApi.apiSearchInitfromserverPost({
      searchInitRequest: {
        url: props.resolvedUrl,
      },
    });
    return handleSrp(searchInitResponse, props, store);
  } catch (err) {
    return { notFound: true };
  }
};

export function isPathCorrect(
  srpcategory: string,
  initialFields: SearchPageFields,
) {
  let acceptableCategoryPaths = ['alle-biler'].concat(
    initialFields.categorySection[0].attributes.map(p => p.value),
  );
  return acceptableCategoryPaths.includes(srpcategory);
}

export const NotFound = { notFound: true };

export default SRP;

const handleSrp = async (
  searchInitResponse: SearchInitResponse,
  props: any,
  store: any,
) => {
  let { fields, searchPageOptions, searchResult, seoData, emptySearchResult } =
    searchInitResponse;
  if (!isPathCorrect(props.query.srpcategory as string, fields)) {
    return { notFound: true };
  }
  store.dispatch(SetSrpSearchOptions(searchPageOptions));
  store.dispatch(SetSrpSearchData(searchResult));
  store.dispatch(SetSeoOverrides(seoData));
  if (searchResult && searchResult.items.length === 0) {
    store.dispatch(SetSrpNoResultsSearchData(emptySearchResult));
  }
  store.dispatch(SetSrpSearchFields(fields));
  var modelAttributes = fields.defaultSection
    .find(p => p.formName === 'makeModelVariantOptions')
    .groupedFields.find(p => p.formName === 'model').attributes;
  store.dispatch(SetModelAttributes(modelAttributes));
  return;
};
