import { SearchPageOptions } from '@/shared/lib-api';
import React from 'react';

export const GetPreviousPageLink = (
  options: SearchPageOptions,
  path: string,
) => {
  if (options.pageNumber === undefined || options.pageNumber === 1) {
    return <></>;
  }
  if (options.pageNumber === 2) {
    return <link rel="prev" href={path.split('?')[0].replace('/side-2', '')} />;
  }
  return (
    <link
      rel="prev"
      href={`${path.split('?')[0].split('/side-')[0]}/side-${
        options.pageNumber - 1
      }`}
    />
  );
};

export const GetNextPageLink = (
  options: SearchPageOptions,
  path: string,
  totalResults: number,
) => {
  if (options.pageNumber === undefined) {
    return <link rel="next" href={`${path.split('?')[0]}side-2`} />;
  }
  if (
    totalResults === 0 ||
    options.pageNumber === Math.ceil(totalResults / options.pageSize)
  ) {
    return <></>;
  }
  return (
    <link
      rel="next"
      href={`${path.split('?')[0].split('/side-')[0]}/side-${
        options.pageNumber + 1
      }`}
    />
  );
};

export const GetCanonicalPageLink = (
  options: SearchPageOptions,
  path: string,
  host: string,
) => {
  return (
    <link
      rel="canonical"
      href={`${host}${path.split('?')[0].replace('/s/', '/')}`}
    />
  );
};
