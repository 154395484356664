import {
  ClassifiedItem,
  ClassifiedItemAttachment,
  UserProfileType,
} from '@/shared/lib-api';
import { Colors } from '@/shared/util/colors';
import {
  BatteryCapacityDisplay,
  FooterLocationDisplay,
  HorsePowerOrCapacityDisplay,
  HorsePowerOrCapacityDisplay2,
  HorsepowerDisplay,
  MileageDisplay,
  PropellantDisplay,
  RegDateDisplay,
} from '@/shared/util/helpers/data-display-helper';
import { cursorPointer } from '@/shared/util/helpers/general-helper';
import { Box, Button, Divider, Grid, styled, Typography } from '@mui/material';
import Link from 'next/link';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NewVehicleTag } from '../../tags/new-vehicle-tag';
import { GalleryBoxGalleryCard } from '../../default-components/gallery/gallery-box-srp';
import { DefaultTag, SeoTag } from '../../tags/tag';
import { useBasicCarNoAuth } from '@/shared/hooks/basic-car-hook';
import { LoweredPrice, LoweredPriceTag } from '../../tags/lowered-price-tag';
import { thousandNumberSeperator } from '@/shared/util/general-helpers';
import { HasGuaranteeTag } from '../../tags/has-guarantee-tag';
import { VipLink } from '../../default-components/vip-link';
import { GetSmallImage } from '@/shared/util/helpers/image-helper';
import { EngrosTag } from '../../tags/engros-tag';

interface ImageGalleryCardProps {
  item?: ClassifiedItemAttachment;
  carrousel?: boolean;
}

export const ImageGalleryCard = (props: ImageGalleryCardProps) => {
  const image = useMemo(() => {
    return GetSmallImage(props.item.folderId, props.item.id);
  }, [props.item]);
  return (
    <Grid item xs={12} sm={6} md={4} sx={cursorPointer}>
      <Box position="relative">
        <GalleryBoxGalleryCard
          borderRadiusImage="all"
          minHeight={0}
          items={[image]}
          top-border={true}
          seoSrp
          hideBullets={true}
          alt={props.item?.id + ''}
        />
      </Box>
    </Grid>
  );
};
