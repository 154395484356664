/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiBlockGridItemModel,
    ApiBlockGridItemModelFromJSON,
    ApiBlockGridItemModelFromJSONTyped,
    ApiBlockGridItemModelToJSON,
} from './ApiBlockGridItemModel';

/**
 * 
 * @export
 * @interface ApiBlockGridAreaModel
 */
export interface ApiBlockGridAreaModel {
    /**
     * 
     * @type {string}
     * @memberof ApiBlockGridAreaModel
     */
    alias?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiBlockGridAreaModel
     */
    rowSpan?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiBlockGridAreaModel
     */
    columnSpan?: number;
    /**
     * 
     * @type {Array<ApiBlockGridItemModel>}
     * @memberof ApiBlockGridAreaModel
     */
    items?: Array<ApiBlockGridItemModel>;
}

export function ApiBlockGridAreaModelFromJSON(json: any): ApiBlockGridAreaModel {
    return ApiBlockGridAreaModelFromJSONTyped(json, false);
}

export function ApiBlockGridAreaModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiBlockGridAreaModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'rowSpan': !exists(json, 'rowSpan') ? undefined : json['rowSpan'],
        'columnSpan': !exists(json, 'columnSpan') ? undefined : json['columnSpan'],
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(ApiBlockGridItemModelFromJSON)),
    };
}

export function ApiBlockGridAreaModelToJSON(value?: ApiBlockGridAreaModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alias': value.alias,
        'rowSpan': value.rowSpan,
        'columnSpan': value.columnSpan,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(ApiBlockGridItemModelToJSON)),
    };
}

