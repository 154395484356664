/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IApiElementModel,
    IApiElementModelFromJSON,
    IApiElementModelFromJSONTyped,
    IApiElementModelToJSON,
} from './IApiElementModel';

/**
 * 
 * @export
 * @interface ApiBlockItemModel
 */
export interface ApiBlockItemModel {
    /**
     * 
     * @type {IApiElementModel}
     * @memberof ApiBlockItemModel
     */
    content?: IApiElementModel;
    /**
     * 
     * @type {IApiElementModel}
     * @memberof ApiBlockItemModel
     */
    settings?: IApiElementModel;
}

export function ApiBlockItemModelFromJSON(json: any): ApiBlockItemModel {
    return ApiBlockItemModelFromJSONTyped(json, false);
}

export function ApiBlockItemModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiBlockItemModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': !exists(json, 'content') ? undefined : IApiElementModelFromJSON(json['content']),
        'settings': !exists(json, 'settings') ? undefined : IApiElementModelFromJSON(json['settings']),
    };
}

export function ApiBlockItemModelToJSON(value?: ApiBlockItemModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': IApiElementModelToJSON(value.content),
        'settings': IApiElementModelToJSON(value.settings),
    };
}

