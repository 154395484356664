import {
  ClassifiedItem,
  ClassifiedStatus,
  UserProfileType,
} from '@/shared/lib-api';
import { Colors } from '@/shared/util/colors';
import {
  CreatedDateDisplay,
  HorsePowerOrCapacityDisplay,
  KmLOrRangeDisplay,
  LeasingDownPaymentValueDisplay,
  LeasingDurationDisplay,
  LeasingMileagePerYearValueDisplay,
  LeasingResidualValueDisplay,
  LocationDisplay,
  MileageDisplay,
  PropellantDisplay,
  RegDateDisplay,
  SellerDisplay,
  YellowPlatesDisplay,
} from '@/shared/util/helpers/data-display-helper';
import { cursorPointer } from '@/shared/util/helpers/general-helper';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import Link from 'next/link';
import React, { useCallback, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { WriteDealerFormModal } from '../../default-components/write-dealer-form-modal/write-dealer-form-modal';
import { NewVehicleTag } from '../../tags/new-vehicle-tag';
import { BasicCarListHeart } from './basic-car-list-heart';
import { BasicCarCardType, useBasicCar } from '@/shared/hooks/basic-car-hook';
import { GalleryBoxListCard } from '../../default-components/gallery/gallery-box-srp';
import { DeleteIcon } from '../../default-components/input/input-icons/deleteIcon';
import { CallIcon } from '../../default-components/input/input-icons/callNowIcon';
import { EmailIcon } from '../../default-components/input/input-icons/emailIcon';
import LazyCallNow from './lazy-call-now';
import {
  BuildInTrackEvents,
  TrackClassifiedItemEvent,
} from '@/shared/util/gtm';
import { ConditionalWrapper } from '../../containers/conditional-wrapper';
import { useScreenSizeContext } from '@/pages/_app';
import { useRouter } from 'next/router';
import { BasicCarFavorite } from './basic-car-favorite';
import { LoweredPriceTag } from '../../tags/lowered-price-tag';
import {
  LoadingState,
  thousandNumberSeperator,
} from '@/shared/util/general-helpers';
import { HasGuaranteeTag } from '../../tags/has-guarantee-tag';
import { TrackEvent } from '@/shared/util/gtm';
import { VipLink } from '../../default-components/vip-link';
import {
  GetSmallImage,
  GetTinyImage,
} from '@/shared/util/helpers/image-helper';
import { EngrosTag } from '../../tags/engros-tag';
import { VideoCard } from '../../SRP/srp-video-mode';

interface BasicCarListCardProps {
  item?: ClassifiedItem;
  eager?: boolean;
  origin: BasicCarCardType;
}
const BasicCarListCard = (props: BasicCarListCardProps) => {
  const {
    userCoordinates,
    removeLike,
    isNewVehicle,
    carRoute,
    isLoweredInPrice,
    hasGuarantee,
    loading,
    isEngros,
  } = useBasicCar({
    item: props.item,
  });
  const { isMobile } = useScreenSizeContext();
  const router = useRouter();

  const active = props.item?.systemData.isActive;
  const opacity = active ? 1 : 0.5;

  const [openWriteModal, setOpenWriteModal] = useState(false);
  const flipWriteModalOpen = useCallback(
    (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
      e.stopPropagation();
      setOpenWriteModal(openWriteModal ? false : true);
    },
    [openWriteModal],
  );

  const dataList = useMemo(() => {
    let result: string[] = [];
    if (props.item === undefined) {
      return result;
    }
    if (props.item.leasingDuration) {
      result.push(LeasingDurationDisplay(props.item.leasingDuration));
    }
    if (
      props.item.leasingResidualValueFormatted &&
      props.item.leasingResidualValueFormatted !== '0 kr'
    ) {
      result.push(
        LeasingResidualValueDisplay(props.item.leasingResidualValueFormatted),
      );
    }
    if (props.item.leasingDownPaymentFormatted) {
      result.push(
        LeasingDownPaymentValueDisplay(props.item.leasingDownPaymentFormatted),
      );
    }
    if (
      props.item.leasingMileagePerYear &&
      props.item.leasingMileagePerYear !== '0 km' &&
      props.item.leasingMileagePerYear !== '-1 km'
    ) {
      result.push(
        LeasingMileagePerYearValueDisplay(props.item.leasingMileagePerYear),
      );
    }
    result.push(RegDateDisplay(props.item));
    if (props.item.mileage) {
      result.push(MileageDisplay(props.item.mileage));
    }
    result.push(PropellantDisplay(props.item.fueltype));

    if (KmLOrRangeDisplay(props.item)) {
      result.push(KmLOrRangeDisplay(props.item));
    }
    if (HorsePowerOrCapacityDisplay(props.item)) {
      result.push(HorsePowerOrCapacityDisplay(props.item));
    }
    if (YellowPlatesDisplay(props.item)) {
      result.push(YellowPlatesDisplay(props.item));
    }
    result.push(SellerDisplay(props.item.owner.profileType));
    result.push(LocationDisplay(props.item, userCoordinates));

    if (props.item.owner.profileType === UserProfileType.Private) {
      result.push(CreatedDateDisplay(props.item.systemData.createdDate));
    }
    return result.filter(text => text?.length > 0);
  }, [props.item, userCoordinates]);

  const trackCallClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    TrackClassifiedItemEvent(
      BuildInTrackEvents.OnDealerListCallClicked,
      props.item,
    );
  };

  const trackWriteClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    TrackClassifiedItemEvent(
      BuildInTrackEvents.OnDealerListWriteClicked,
      props.item,
    );
  };

  const itemPerLine = isMobile ? 2 : 3;
  const isFavoriteOrCompany =
    props.origin === BasicCarCardType.FavouritesList ||
    props.item?.owner.profileType === UserProfileType.Company;

  const showCallMe =
    (props.item?.owner.profileType == UserProfileType.Company &&
      props.item?.owner.isOpenNow === false) ||
    props.item?.owner?.id === 168267; // toyota danmark;

  const trackClick = useCallback(() => {
    if (props.origin == BasicCarCardType.AlternativesModal) {
      TrackEvent('modal-alternative-clicked', { id: props.item.id });
    }
  }, [props]);

  const hasVideo = props.item?.videos.length > 0;

  return (
    <>
      <Box
        display="contents"
        onClick={() => {
          if (props.item?.systemData.status !== ClassifiedStatus.Deleted) {
            trackClick();
            router.push(carRoute);
          }
        }}>
        <Grid
          data-id={props.item?.id}
          marginBottom={{
            xs: props.origin == BasicCarCardType.AlternativesModal ? '12px' : 0,
            md: props.origin == BasicCarCardType.AlternativesModal ? 2 : 0,
          }}
          item
          xs={12}
          zIndex={1}
          paddingTop="13px"
          paddingBottom={{ xs: '0px', md: '16px' }}
          paddingX={{ xs: '14px', md: '16px' }}
          bgcolor={Colors.Background.TableHead}
          sx={
            isFavoriteOrCompany
              ? {
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                  borderBottomLeftRadius:
                    props.origin == BasicCarCardType.AlternativesModal
                      ? '10px'
                      : '0px',
                  borderBottomRightRadius:
                    props.origin == BasicCarCardType.AlternativesModal
                      ? '10px'
                      : '0px',
                }
              : {
                  borderRadius: '10px',
                  marginBottom: {
                    xs: '12px',
                    md: props.item !== undefined ? 2 : 0,
                  },
                  borderBottomLeftRadius:
                    props.origin == BasicCarCardType.AlternativesModal ||
                    props.item?.owner?.profileType === UserProfileType.Private
                      ? '10px'
                      : '0px',
                  borderBottomRightRadius:
                    props.origin == BasicCarCardType.AlternativesModal ||
                    props.item?.owner?.profileType === UserProfileType.Private
                      ? '10px'
                      : '0px',
                }
          }>
          <Grid container>
            <Grid item xs={12} sx={{ opacity: opacity }}>
              <Box display={'flex'}>
                <Box
                  flexGrow={1}
                  alignItems={'center'}
                  display={'flex'}
                  flexWrap={'wrap'}>
                  <Box display={'inline'} marginRight={1} lineHeight={1}>
                    <ConditionalWrapper
                      condition={
                        props.item?.systemData.status !==
                        ClassifiedStatus.Deleted
                      }
                      wrapper={innerChildren => (
                        <VipLink item={props.item}>{innerChildren}</VipLink>
                      )}>
                      <>
                        <Typography
                          component={'span'}
                          variant="h6"
                          lineHeight={1}
                          alignSelf={'center'}>
                          {props.item !== undefined ? (
                            `${props.item?.make} ${props.item?.model} `
                          ) : (
                            <Skeleton width={150} />
                          )}

                          <Typography
                            component={'span'}
                            variant="h6"
                            sx={{ display: { xs: 'none', md: 'inline' } }}
                            fontWeight={400}>
                            {props.item?.alternativeVariant}
                          </Typography>
                        </Typography>
                      </>
                    </ConditionalWrapper>
                  </Box>

                  {props.item !== undefined &&
                    isNewVehicle(
                      props.item?.systemData.createdDate.toString(),
                    ) && (
                      <Box display="flex" marginRight={1}>
                        <NewVehicleTag />
                      </Box>
                    )}

                  {props.item !== undefined && isLoweredInPrice() && (
                    <Box display="flex" marginRight={1}>
                      <LoweredPriceTag />
                    </Box>
                  )}

                  {props.item !== undefined && hasGuarantee() && (
                    <HasGuaranteeTag />
                  )}

                  {props.item !== undefined && isEngros() && (
                    <Box display={'flex'} justifyContent="end">
                      <EngrosTag />
                    </Box>
                  )}

                  <Typography
                    flexBasis={'100%'}
                    component={'div'}
                    sx={{ display: { xs: 'block', md: 'none' } }}>
                    {props.item?.alternativeVariant}
                  </Typography>
                </Box>
                <Box alignSelf={'center'} marginTop={1}>
                  {props.item !== undefined && !isMobile && (
                    <BasicCarListHeart
                      origin={props.origin}
                      item={props.item}
                    />
                  )}
                </Box>
              </Box>
              <ConditionalWrapper
                condition={
                  props.item?.systemData.status !== ClassifiedStatus.Deleted
                }
                wrapper={innerChildren => (
                  <VipLink item={props.item}>{innerChildren}</VipLink>
                )}>
                <Grid container paddingTop={1.5} paddingBottom={1.5}>
                  <Grid item xs={12} sm={'auto'}>


                    { hasVideo && <VideoCard item={props.item} /> }
                    { !hasVideo && 
                      <GalleryBoxListCard
                        eager={props.eager}
                        minHeight={204}
                        items={props.item?.images.map(p =>
                          isMobile
                            ? GetSmallImage(p.folderId, p.id)
                            : GetTinyImage(p.folderId, p.id),
                        )}
                        alt={props.item?.headLine}
                      />
                    }
                    
                  </Grid>
                  <Grid
                    paddingLeft={{ xs: 0, md: '30px' }}
                    marginTop={{ xs: '12px', md: 0 }}
                    item
                    xs={12}
                    sm={true}>
                    <Grid container height="100%" maxWidth={456}>
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', marginBottom: 1 }}>
                          <Box
                            flexGrow={1}
                            display="flex"
                            flexDirection={
                              isMobile ? 'column-reverse' : 'column'
                            }>
                            <Typography variant="h5">
                              {props.item !== undefined ? (
                                props.item.priceFormatted + ' '
                              ) : (
                                <Skeleton width={100} />
                              )}
                            </Typography>
                            {props.item !== undefined ? (
                              isLoweredInPrice() ? (
                                <Typography color="#1066B6" fontSize={15}>
                                  {`Nedsat ${thousandNumberSeperator(
                                    props.item.changeInMaxPrice * -1,
                                  )} kr (før ${thousandNumberSeperator(
                                    props.item.previousMaxPrice,
                                  )} kr)`}
                                </Typography>
                              ) : (
                                <Typography
                                  lineHeight={'24px'}
                                  fontSize={'13px'}
                                  color="#9d9d9d"
                                  variant="captionSmall">
                                  {props.item.priceLabel}
                                </Typography>
                              )
                            ) : (
                              <Skeleton width={60} />
                            )}
                          </Box>
                          <Box>
                            {props.item && isMobile && (
                              <BasicCarFavorite item={props.item} />
                            )}
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid
                          container
                          spacing="12px"
                          columnSpacing={{ xs: '22px', md: '12px' }}>
                          {dataList.map((text, textIndex) => {
                            return (
                              <Grid key={text} item xs={6} md={4}>
                                <Typography
                                  width={'100%'}
                                  whiteSpace={'nowrap'}
                                  textOverflow={'ellipsis'}
                                  overflow={'hidden'}
                                  borderBottom={
                                    textIndex >=
                                    Math.floor(
                                      (dataList.length - 1) / itemPerLine,
                                    ) *
                                      itemPerLine
                                      ? '0px solid #D5DDDF'
                                      : '1px solid #D5DDDF'
                                  }>
                                  {text}
                                </Typography>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ConditionalWrapper>
            </Grid>
          </Grid>
        </Grid>

        {props.item == undefined && (
          <Grid
            item
            paddingTop={{ xs: 1, md: 0 }}
            paddingBottom={1}
            width={'100%'}
            paddingX={{ xs: '14px', md: '16px' }}
            marginBottom={{ xs: '12px', md: 2 }}
            bgcolor={Colors.Background.TableHead}
            sx={{
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: '10px',
            }}
            borderTop={{
              xs: '1px solid #D5DDDF',
              md: 'none',
            }}>
            <Skeleton height={69}></Skeleton>
          </Grid>
        )}

        {props.item !== undefined &&
          isFavoriteOrCompany &&
          props.origin !== BasicCarCardType.AlternativesModal && (
            <Grid
              item
              xs={12}
              zIndex={1}
              paddingTop={{ xs: 1, md: 0 }}
              paddingBottom={1}
              paddingX={{ xs: '14px', md: '16px' }}
              marginBottom={{ xs: '12px', md: 2 }}
              bgcolor={Colors.Background.TableHead}
              sx={{
                borderBottomLeftRadius: '10px',
                borderBottomRightRadius: '10px',
              }}
              borderTop={{
                xs: isFavoriteOrCompany ? '1px solid #D5DDDF' : '',
                md: 'none',
              }}>
              <Box
                borderTop={{
                  xs: '',
                  md: isFavoriteOrCompany ? '1px solid #D5DDDF' : '',
                }}
                paddingTop={{ xs: 0, md: 1 }}>
                <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexGrow={1}
                    paddingY={1}>
                    {/* Logo */}
                    {props.item?.owner?.imageUrl && (
                      // eslint-disable-next-line @next/next/no-img-element
                      <img
                        alt={props.item.owner.name}
                        loading="lazy"
                        style={{
                          objectFit: 'scale-down',
                          objectPosition: 'left center',
                        }}
                        height={34}
                        width={128}
                        src={
                          props.item?.owner?.imageUrl
                            ? props.item?.owner?.imageUrl
                            : undefined
                        }
                      />
                    )}
                  </Box>
                  {/* Actions */}

                  {props.origin === BasicCarCardType.FavouritesList && (
                    <>
                      {!active && (
                        <Box marginLeft={1}>
                          <Typography>
                            <Trans i18nKey={'Labels.NoLongerForSale'} />
                          </Typography>
                        </Box>
                      )}
                      <Box display={'flex'} flexWrap={'wrap'} rowGap={1}>
                        <Box marginLeft={1}>
                          <DeleteIcon
                            onDelete={e => {
                              if (loading === LoadingState.Loading) {
                                return;
                              }
                              e.stopPropagation();
                              removeLike();
                            }}
                            loading={loading === LoadingState.Loading}
                          />
                        </Box>
                        {active && (
                          <>
                            <Box marginLeft={1} onClick={flipWriteModalOpen}>
                              <EmailIcon
                                onClick={trackWriteClick}
                                loading={false}
                              />
                            </Box>
                            {props.item.owner.phone && (
                              <Box marginLeft={1}>
                                <Link href={`tel:${props.item.owner.phone}`}>
                                  <CallIcon
                                    onClick={trackCallClick}
                                    loading={false}
                                  />
                                </Link>
                              </Box>
                            )}
                          </>
                        )}
                      </Box>
                    </>
                  )}

                  {props.origin !== BasicCarCardType.FavouritesList &&
                    props.item.owner.profileType ===
                      UserProfileType.Company && (
                      <Box display={'flex'}>
                        <Box marginLeft={1} onClick={flipWriteModalOpen}>
                          <EmailIcon
                            onClick={trackWriteClick}
                            loading={false}
                          />
                        </Box>

                        {!showCallMe && (
                          <Box marginLeft={1} sx={cursorPointer}>
                            <LazyCallNow item={props.item} />
                          </Box>
                        )}
                      </Box>
                    )}
                </Box>
              </Box>
            </Grid>
          )}
      </Box>

      {props.item && props.origin !== BasicCarCardType.AlternativesModal && (
        <WriteDealerFormModal
          classified-item={props.item}
          close={flipWriteModalOpen}
          open={openWriteModal}
        />
      )}
    </>
  );
};

export default BasicCarListCard;
