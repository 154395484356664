/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RichTextModel,
    RichTextModelFromJSON,
    RichTextModelFromJSONTyped,
    RichTextModelToJSON,
} from './RichTextModel';

/**
 * 
 * @export
 * @interface SektionPropertiesModel
 */
export interface SektionPropertiesModel {
    /**
     * 
     * @type {RichTextModel}
     * @memberof SektionPropertiesModel
     */
    tekst?: RichTextModel;
}

export function SektionPropertiesModelFromJSON(json: any): SektionPropertiesModel {
    return SektionPropertiesModelFromJSONTyped(json, false);
}

export function SektionPropertiesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SektionPropertiesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tekst': !exists(json, 'tekst') ? undefined : RichTextModelFromJSON(json['tekst']),
    };
}

export function SektionPropertiesModelToJSON(value?: SektionPropertiesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tekst': RichTextModelToJSON(value.tekst),
    };
}

