import React, { useCallback, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PadBox } from '../containers/pad-box';
import { VipHeadline } from './headline';
import { WriteDealerFormModal } from '../default-components/write-dealer-form-modal/write-dealer-form-modal';
import { useVipContext } from '@/pages/[headline]/[[...id]]';

export const VipSecurityCard = () => {
  const { t } = useTranslation();
  const { item } = useVipContext();
  const [openModal, setOpenModal] = useState(false);
  const showModal = useCallback(() => {
    setOpenModal(prevState => !prevState);
  }, []);

  return (
    <>
      {item.hasGuarantee && (
        <>
          <PadBox>
            <VipHeadline headline={t('Labels.Security')} />
            <Typography
              fontSize="15px"
              lineHeight="26px"
              marginTop={2}
              whiteSpace="pre-line"
              sx={{ wordBreak: 'break-word' }}>
              {t('Vip.SecurityBody')}
            </Typography>
            <Box marginTop={2}>
              <Button
                onClick={showModal}
                id="gtm-extended-guarantee"
                color="secondary">
                {t('Vip.SecurityButtonText')}
              </Button>
            </Box>
          </PadBox>
          <WriteDealerFormModal
            close={showModal}
            open={openModal}
            classified-item={item}
          />
        </>
      )}
    </>
  );
};
