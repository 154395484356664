import React, { useCallback, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PadBox } from '../containers/pad-box';
import { VipHeadline } from './headline';
import { ClassifiedItem, UserProfileType } from '@/shared/lib-api';
import reactStringReplace from 'react-string-replace';
import { WriteDealerFormModal } from '../default-components/write-dealer-form-modal/write-dealer-form-modal';
import { useVipContext } from '@/pages/[headline]/[[...id]]';

const formatPhoneNumber = (phoneNumber: string): string => {
  if (phoneNumber.length !== 8) {
    return phoneNumber;
  }

  return phoneNumber.replace(/.{2}/g, m => `${m} `).trim();
};

const replacePhoneAndEmail = (
  item: ClassifiedItem,
  onEmailClick: () => void,
  jyskauktion?: boolean,
): React.ReactNode => {
  const email_regex = /([\S]+@[\S]+.[\S]+)/gm;

  const phonenumber_regex = new RegExp(
    `([\d]{8}|[𝟎|𝟏|𝟐|𝟑|𝟒|𝟓|𝟔|𝟕|𝟖|𝟗]{16}|${item.owner.phone})`,
    'gm',
  );
  const jyskauktion_regex = new RegExp(
    `(https://www.jyskauktion.dk/product/.{5})`,
    'gm',
  );

  let description = item.description;
  let replacedElement;

  replacedElement = reactStringReplace(
    description,
    phonenumber_regex,
    (match, i) => (
      <a
        key={match + i}
        href={`tel:${item.owner.phone}`}
        className={'dealer-call-description'}>
        {formatPhoneNumber(item.owner.phone)}
      </a>
    ),
  );
  replacedElement = reactStringReplace(
    replacedElement,
    email_regex,
    (match, i) => (
      <span
        style={{ cursor: 'pointer', textDecoration: 'underline' }}
        key={match + i}
        onClick={onEmailClick}
        className={'dealer-email-description'}>
        Send email - klik her
      </span>
    ),
  );

  if (jyskauktion) {
    replacedElement = reactStringReplace(
      replacedElement,
      jyskauktion_regex,
      (match, i) => (
        <span style={{ display: 'block' }}>
          Bilen sælges på auktion.{' '}
          <a
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            key={match + i}
            target="_blank"
            rel="noreferrer"
            href={
              match +
              '?utm_source=bilhandel&utm_medium=klik_paa_bil&utm_campaign=bilhandel'
            }
            className={'dealer-jysk-auktion-headline'}>
            Se detaljer og højeste bud her
          </a>
        </span>
      ),
    );
  }

  return <>{replacedElement}</>;
};

export const VipDescriptionCard = () => {
  const { t } = useTranslation();
  const { item } = useVipContext();
  const [openModal, setOpenModal] = useState(false);
  const showModal = useCallback(() => {
    setOpenModal(prevState => !prevState);
  }, []);

  return (
    <>
      {item?.description && (
        <>
          <PadBox>
            <VipHeadline headline={t('Labels.Description')} />
            <Typography
              fontSize="15px"
              lineHeight="26px"
              marginTop={2}
              whiteSpace="pre-line"
              sx={{ wordBreak: 'break-word' }}>
              {item.owner.profileType === UserProfileType.Company
                ? replacePhoneAndEmail(item, showModal, item.owner.id === 68957)
                : item.description}
            </Typography>
          </PadBox>
          <WriteDealerFormModal
            close={showModal}
            open={openModal}
            classified-item={item}
          />
        </>
      )}
    </>
  );
};
