/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImageCropCoordinatesModel
 */
export interface ImageCropCoordinatesModel {
    /**
     * 
     * @type {number}
     * @memberof ImageCropCoordinatesModel
     */
    x1?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageCropCoordinatesModel
     */
    y1?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageCropCoordinatesModel
     */
    x2?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageCropCoordinatesModel
     */
    y2?: number;
}

export function ImageCropCoordinatesModelFromJSON(json: any): ImageCropCoordinatesModel {
    return ImageCropCoordinatesModelFromJSONTyped(json, false);
}

export function ImageCropCoordinatesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageCropCoordinatesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'x1': !exists(json, 'x1') ? undefined : json['x1'],
        'y1': !exists(json, 'y1') ? undefined : json['y1'],
        'x2': !exists(json, 'x2') ? undefined : json['x2'],
        'y2': !exists(json, 'y2') ? undefined : json['y2'],
    };
}

export function ImageCropCoordinatesModelToJSON(value?: ImageCropCoordinatesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'x1': value.x1,
        'y1': value.y1,
        'x2': value.x2,
        'y2': value.y2,
    };
}

