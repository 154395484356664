import { useScreenSizeContext, useSiteSettingContext } from '@/pages/_app';
import { BasicCarCardType } from '@/shared/hooks/basic-car-hook';
import {
  ClassifiedItem,
  SearchbotModelDisplayItem,
  SearchPageOptions,
} from '@/shared/lib-api';
import { useApi } from '@/shared/util/api';
import { Colors } from '@/shared/util/colors';
import { thousandNumberSeperator } from '@/shared/util/general-helpers';
import { TrackEvent } from '@/shared/util/gtm';
import { updateSRPRoute } from '@/web/util/helpers/srp-url-helpers';
import { slugifyOptions } from '@/web/util/helpers/url-helpers';
import babyCarrage from '@/web/views/components/home/searchbot/BabyCarriage.svg';
import gasPump from '@/web/views/components/home/searchbot/GasPump.svg';
import lightning from '@/web/views/components/home/searchbot/Lightning.svg';
import piggybank from '@/web/views/components/home/searchbot/PiggyBank.svg';
import PaperPlane from '@/web/views/components/vip/chatbot/paperplane.svg';
import {
  Box,
  Grid,
  IconButton,
  Link,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { useRouter } from 'next/router';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import slugify from 'slugify';
import { BasicCarGalleryCard } from '../../cards/basic-car-card/basic-car-gallery-card';
import { SearchBotModelGalleryCard } from '../../cards/basic-car-card/searchbot-model-gallery-card';

interface Message {
  message: string;
  items?: ClassifiedItem[];
  totalCount?: number;
  models?: SearchbotModelDisplayItem[];
  searchOptions?: SearchPageOptions;
  own: boolean;
  date: number;
}

const initialQuestions = [
  { text: 'Jeg søger en pendlerbil', icon: gasPump },
  { text: 'Vis mig biler med 7 sæder', icon: babyCarrage },
  { text: 'Jeg leder efter en billig bil', icon: piggybank },
  { text: 'Hvilken elbil skal jeg købe?', icon: lightning },
];

export const Searchbot = () => {
  const { isMobile } = useScreenSizeContext();
  const initMessage = {
    message: `Fortæl hvad du leder efter, så hjælper vores digitale assistent dig med at finde den rigtige bil.`,
    own: false,
    date: new Date().getTime(),
  };

  const [ownMessages, setOwnMessages] = React.useState<Message[]>([]);
  const [botMessages, setBotMessages] = React.useState<Message[]>([
    initMessage,
  ]);
  const [threadId, setThreadId] = React.useState(undefined);

  const { searchbotApi } = useApi();

  const [loading, setLoading] = React.useState(false);
  const [loadingLong, setLoadingLong] = React.useState(false);
  const [block, setBlock] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const router = useRouter();
  const siteSettings = useSiteSettingContext();
  const lastMessageRef = useRef<HTMLDivElement>(null);
  const lastOptionsRef = useRef<HTMLDivElement>(null);
  const chatRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [inputInFocus, setInputInFocus] = useState(false);
  const [botMessageCount, setBotMessageCount] = useState(0);

  //load from localstorage
  useEffect(() => {
    var chattime = localStorage.getItem('searchchattime');
    var expired = new Date().getTime() - parseInt(chattime) > 3600000; //idle for 1 time

    if (expired) {
      localStorage.removeItem('searchbotMessages1');
      localStorage.removeItem('searchownMessages1');
      localStorage.removeItem('searchthreadId');
      localStorage.removeItem('searchchattime');
      localStorage.removeItem('searchreminderCount');
    }
    if (expired) {
      return;
    }

    var threadId = localStorage.getItem('searchthreadId');
    if (threadId) {
      setThreadId(threadId);
    }
    var botMessages = localStorage.getItem('searchbotMessages1');
    if (botMessages) {
      setBotMessages(JSON.parse(botMessages));
    }
    var ownMessages = localStorage.getItem('searchownMessages1');
    if (ownMessages) {
      setOwnMessages(JSON.parse(ownMessages));
    }
  }, []);

  useEffect(() => {
    if (botMessageCount !== 0) {
      var audio = new Audio('/alert.mp3');
      audio.volume = 0.4;
      audio.play();
    }
  }, [botMessageCount]);

  const resetChat = useCallback(() => {
    localStorage.removeItem('searchbotMessages1');
    localStorage.removeItem('searchownMessages1');
    localStorage.removeItem('searchthreadId');
    localStorage.removeItem('searchchattime');
    localStorage.removeItem('searchreminderCount');
    localStorage.removeItem('searchunreadCount');
    setOwnMessages([]);
    setBotMessages([initMessage]);
    setThreadId(undefined);
    setBlock(false);
    setMessage('');
    setLoading(false);
    setLoadingLong(false);
    trackClick({ type: 'reset_chat' });
  }, []);

  const trackClick = useCallback(label => {
    TrackEvent('on_searchbot_clicked', label);
  }, []);

  const allMessages = useMemo(() => {
    var result: Message[] = [];
    for (var i = 0; i < botMessages.length; i++) {
      var botRes = botMessages[i];
      var botResMessage = botRes.message;
      //replace headline start
      var regex = /###(.*)/g;
      var match = regex.exec(botResMessage);
      while (match != null && match.length > 1) {
        var text = match[0].replace(match[2], '').replace('###', '');
        botResMessage = botResMessage.replace(
          match[0],
          `<div style="font-size: 15px;"><b>${text}</b></div>`,
        );
        match = regex.exec(botResMessage);
      }
      //replace headline end
      //replace bold start
      var regex = /\*\*(.*)\*\*/g;
      var match = regex.exec(botResMessage);
      while (match != null && match.length > 1) {
        var text = match[0]
          .replace(match[2], '')
          .replace('**', '')
          .replace('**', '');
        botResMessage = botResMessage.replace(match[0], `<b>${text}</b>`);
        match = regex.exec(botResMessage);
      }
      //replace bold end
      //replace links start
      var regex = /(\[.*\]\((.*)\))/g;
      var match = regex.exec(botResMessage);
      while (match != null && match.length > 1) {
        var text = match[0]
          .replace(match[2], '')
          .replace('[', '')
          .replace(']', '')
          .replace('(', '')
          .replace(')', '');
        botResMessage = botResMessage.replace(
          match[0],
          `<a style="text-decoration: underline" target="new" href="${match[2]}?referer=chat">${text}</a>`,
        );
        match = regex.exec(botResMessage);
      }
      //replace links end
      result.push({ ...botRes, message: botResMessage });
    }
    result = result.concat(ownMessages);
    result = result.sort((a, b) => a.date - b.date);
    return result;
  }, [botMessages, ownMessages]);

  function scroll() {
    window.scrollTo({
      top:
        inputRef.current.offsetTop -
        (lastMessageRef.current.clientHeight +
          (lastOptionsRef?.current?.clientHeight ?? 0) >
        window.innerHeight
          ? lastMessageRef.current.clientHeight +
            lastOptionsRef.current.clientHeight +
            32
          : Math.min(
              window.innerHeight - 56,
              chatRef.current.clientHeight + 100,
            )),
      behavior: 'smooth',
    });
  }

  const sendMessage = useCallback(
    async (overrideMessage?: string) => {
      trackClick({ type: 'send_message' });
      if ((overrideMessage || (message && message != '')) && !block) {
        setOwnMessages([
          ...ownMessages,
          {
            message: overrideMessage ?? message,
            own: true,
            date: new Date().getTime(),
          },
        ]);
        localStorage.setItem('searchchattime', new Date().getTime().toString());
        localStorage.setItem(
          'searchownMessages1',
          JSON.stringify([
            ...ownMessages,
            {
              message: overrideMessage ?? message,
              own: true,
              date: new Date().getTime(),
            },
          ]),
        );
        setTimeout(() => {
          scroll();
        }, 200);
        setMessage('');
        var id = setTimeout(() => {
          setLoading(true);
          setTimeout(() => {
            scroll();
          }, 200);
        }, 2500);
        setBlock(true);
        const response = await searchbotApi.apiSearchbotSendMessagePost({
          searchbotMessageRequest: {
            message: overrideMessage ?? message,
            threadId: threadId,
            url:
              (siteSettings.name == 'Localhost'
                ? 'https://bilhandel.dk'
                : siteSettings.hostname) + router.asPath,
          },
        });
        setThreadId(response.threadId);
        localStorage.setItem('searchthreadId', response.threadId);
        clearTimeout(id);
        setLoading(false);
        setLoadingLong(false);

        setBlock(false);

        if (
          response.message ||
          response.searchbotModelDisplayItems.length > 0 ||
          response.classifiedItems.length > 0
        ) {
          setBotMessages([
            ...botMessages,
            {
              message: response.message,
              items: response.classifiedItems,
              models: response.searchbotModelDisplayItems,
              totalCount: response.totalCount,
              searchOptions: response.searchPageOptions,
              own: false,
              date: new Date().getTime(),
            },
          ]);
          setBotMessageCount(botMessages.length + 1);
          localStorage.setItem(
            'searchbotMessages1',
            JSON.stringify([
              ...botMessages,
              {
                message: response.message,
                items: response.classifiedItems,
                models: response.searchbotModelDisplayItems,
                totalCount: response.totalCount,
                searchOptions: response.searchPageOptions,
                own: false,
                date: new Date().getTime(),
              },
            ]),
          );
        }

        setTimeout(() => {
          scroll();
          if (!isMobile) {
            inputRef?.current?.focus({ preventScroll: true });
          }
        }, 200);
      }
    },
    [
      botMessages,
      searchbotApi,
      message,
      ownMessages,
      isMobile,
      router.asPath,
      threadId,
      block,
      siteSettings,
    ],
  );

  const [loadingTimer, setLoadingTimer] = React.useState(0);
  //animations
  useEffect(() => {
    var id = setInterval(() => {
      setLoadingTimer(t => (t === 3 ? 0 : t + 1));
    }, 500);

    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    var id: NodeJS.Timeout = null;
    if (loading) {
      id = setTimeout(() => {
        setLoadingLong(true);
      }, 5000);
    } else {
      setLoadingLong(false);
    }

    return () => {
      clearTimeout(id);
    };
  }, [loading]);

  const [mobileLandscape, setMobileLandscape] = useState(false);

  useEffect(() => {
    setMobileLandscape(
      isMobile &&
        (screen.orientation.angle === 90 || screen.orientation.angle === 270),
    );
  }, []);

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Typography marginBottom={2} variant={'h2'}>
        Få hjælp til at finde din næste bil
      </Typography>
      <Box
        marginBottom={isMobile && mobileLandscape && inputInFocus ? 0 : 1}
        width={'100%'}
        bgcolor={Colors.White}
        borderRadius={'22px'}
        display={'flex'}
        flexDirection={'column'}>
        <Box
          zIndex={1}
          borderRadius={'19px 19px 0px 0px'}
          bgcolor={Colors.DarkerBlue}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          padding={2}>
          <Typography color={Colors.White} fontSize={16} fontWeight={900}>
            Prøv vores digitale assistent
          </Typography>

          <Box display={'flex'} alignItems={'center'}>
            {ownMessages.length > 0 && (
              <Typography
                color={Colors.White}
                fontSize={16}
                variant="clickAble"
                lineHeight={'22px'}
                onClick={resetChat}>
                Ryd samtale
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          ref={chatRef}
          minHeight={isMobile ? 'unset' : '245px'}
          overflow={'auto'}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'baseline'}
          flexGrow={1}
          borderBottom={'1px solid ' + Colors.Muted}
          paddingTop={2}
          paddingBottom={1}
          paddingX={1}>
          {allMessages.map((m, i, a) => (
            <React.Fragment key={m.date}>
              <Box
                width={m.models || m.items ? '100%' : 'unset'}
                ref={i == a.length - 1 ? lastMessageRef : null}
                flexDirection={m.own ? 'row-reverse' : 'row'}
                alignSelf={m.own ? 'flex-end' : 'flex-start'}
                display="flex"
                alignItems={'flex-end'}
                marginBottom={1}>
                <Box
                  height={10}
                  width={10}
                  bgcolor={m.own ? '#3C81F5' : '#F1F1F2'}>
                  <Box
                    height={10}
                    width={10}
                    borderRadius={m.own ? '0px 0px 0px 70%' : '0px 0px 70% 0px'}
                    bgcolor={Colors.White}
                  />
                </Box>
                <Box
                  width={m.models || m.items ? '100%' : 'unset'}
                  paddingX={2}
                  paddingY={m.items || m.models ? 2 : 1}
                  borderRadius={
                    m.own ? '12px 12px 0px 12px' : '12px 12px 12px 0px'
                  }
                  bgcolor={m.own ? '#3C81F5' : '#F1F1F2'}>
                  <Typography
                    whiteSpace={'break-spaces'}
                    color={m.own ? Colors.White : Colors.DefaultFontColor}>
                    <span dangerouslySetInnerHTML={{ __html: m.message }} />
                  </Typography>
                  {m.models && m.models.length != 0 && (
                    <Grid container marginTop={1} spacing={2}>
                      {m.models.map((item, i) => (
                        <SearchBotModelGalleryCard
                          onClick={() => {
                            sendMessage(
                              `Vis mig nogle ${item.make} ${item.model}`,
                            );

                            trackClick({
                              type: 'model_click',
                              make: item.make,
                              model: item.model,
                            });
                          }}
                          key={item.url}
                          item={item}
                        />
                      ))}
                    </Grid>
                  )}
                  {m.items && m.items.length != 0 && (
                    <Grid container marginTop={1} spacing={2}>
                      {m.items.map((item, i) => (
                        <BasicCarGalleryCard
                          onTrackClick={() => {
                            trackClick({
                              type: 'vehicle_click',
                              make: item.make,
                              model: item.model,
                              url: item.url,
                            });
                          }}
                          targetBlank
                          key={item.id}
                          origin={BasicCarCardType.AlternativesModal}
                          item={item}
                        />
                      ))}
                    </Grid>
                  )}
                </Box>
              </Box>
              {i === 0 && ownMessages.length == 0 && (
                <Box width={'100%'} marginBottom={1}>
                  <Typography textAlign={'center'} color="#3F3F3F">
                    Du kan f.eks. spørge om
                  </Typography>
                  <Grid marginTop={2} columnSpacing={2} container>
                    {initialQuestions.map((q, i) => (
                      <Grid key={q.text} item xs={6} sm={3}>
                        <Box
                          onClick={() => {
                            sendMessage(q.text);
                            trackClick({ type: 'init_click', text: q.text });
                          }}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          bgcolor={Colors.White}
                          sx={{
                            cursor: 'pointer',
                            ':hover': {
                              backgroundColor: Colors.Muted,
                            },
                          }}
                          borderRadius={'40px'}
                          border={'1px solid ' + Colors.Secondary}
                          padding={2}
                          marginBottom={2}>
                          <img src={q.icon.src} alt={q.text} />
                          <Typography
                            lineHeight={'normal'}
                            marginLeft={1}
                            fontSize={16}
                            textAlign={'center'}
                            color={Colors.Secondary}>
                            {q.text}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}

              {m.models && m.models.length != 0 && (
                <Box
                  ref={i == a.length - 1 ? lastOptionsRef : null}
                  paddingX={1}>
                  <Grid container columnSpacing={2}>
                    {m.models.map((item, i) => (
                      <Grid item key={item.url}>
                        <Box
                          display={'flex'}
                          bgcolor={Colors.White}
                          sx={{
                            cursor: 'pointer',
                            ':hover': {
                              backgroundColor: Colors.Muted,
                            },
                          }}
                          borderRadius={'40px'}
                          border={'1px solid ' + Colors.Secondary}
                          padding={2}
                          marginBottom={2}>
                          <Link
                            target="_blank"
                            href={`/alle-biler/${slugify(
                              item.make,
                              slugifyOptions,
                            )}/${slugify(item.model, slugifyOptions)}`}
                            underline="none">
                            <Typography
                              lineHeight={'normal'}
                              fontSize={16}
                              onClick={() => {
                                trackClick({
                                  type: 'see_all_models',
                                  make: item.make,
                                  model: item.model,
                                });
                              }}
                              fontWeight={'500'}
                              color={Colors.Secondary}>
                              Se alle {item.count} {item.make} {item.model} til
                              salg
                            </Typography>
                          </Link>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
              {m.searchOptions && m.items && m.items.length != 0 && (
                <Box
                  ref={i == a.length - 1 ? lastOptionsRef : null}
                  paddingX={1}>
                  <Grid container columnSpacing={2}>
                    <Grid item>
                      <Box
                        display={'flex'}
                        bgcolor={Colors.White}
                        sx={{
                          cursor: 'pointer',
                          ':hover': {
                            backgroundColor: Colors.Muted,
                          },
                        }}
                        borderRadius={'40px'}
                        border={'1px solid ' + Colors.Secondary}
                        padding={2}
                        marginBottom={2}>
                        <Link
                          target="_blank"
                          onClick={() => {
                            trackClick({
                              type: 'see_all_vehicles',
                              href: updateSRPRoute(m.searchOptions, false).href,
                            });
                          }}
                          href={updateSRPRoute(m.searchOptions, false).href}
                          underline="none">
                          <Typography
                            lineHeight={'normal'}
                            fontSize={16}
                            fontWeight={'500'}
                            color={Colors.Secondary}>
                            Se alle {thousandNumberSeperator(m.totalCount)}{' '}
                            biler der matcher søgningen
                          </Typography>
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </React.Fragment>
          ))}
          {loading && (
            <Box display="flex" alignItems={'flex-end'} marginBottom={1}>
              <Box height={10} width={10} bgcolor={'#F1F1F2'}>
                <Box
                  height={10}
                  width={10}
                  borderRadius={'0px 0px 70% 0px'}
                  bgcolor={Colors.White}
                />
              </Box>
              <Box
                paddingX={2}
                paddingY={1}
                borderRadius={'12px 12px 12px 0px'}
                bgcolor={'#F1F1F2'}>
                <Typography color={Colors.DefaultFontColor}>
                  {loadingLong &&
                    'Søger efter biler. Det tager ca. 10 sekunder'}
                  {[1, 2, 3].map(p => (p === loadingTimer ? ' · ' : ' . '))}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          paddingY={1}
          paddingLeft={'18px'}
          paddingRight={1}>
          <Box display={'flex'} alignItems={'center'} flexGrow={1}>
            <TextareaAutosize
              maxRows={3}
              onFocus={() => setInputInFocus(true)}
              onBlur={() => setInputInFocus(false)}
              ref={inputRef}
              style={{
                fontFamily: 'inherit',
                border: 'none',
                resize: 'none',
                width: '100%',
                outline: 'none',
                fontSize: 16,
              }}
              value={message}
              onKeyDown={e => {
                e.stopPropagation();
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  sendMessage();
                }
              }}
              onChange={e => setMessage(e.target.value)}
              placeholder="Jeg leder efter..."
            />
          </Box>
          <Box
            component={IconButton}
            onTouchEnd={() => sendMessage()}
            onClick={() => sendMessage()}
            borderRadius={'50%'}
            bgcolor={Colors.Secondary}
            style={{ cursor: 'pointer' }}
            alignItems={'center'}
            justifyContent={'center'}
            display={'flex'}
            height={40}
            width={40}>
            <img
              alt="Send besked"
              src={PaperPlane.src}
              width={24}
              height={24}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
