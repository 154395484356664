import { SearchPageOptions, SearchResult, SeoUrl } from '@/shared/lib-api';
import { GetFullscreenImage } from '@/shared/util/helpers/image-helper';
import React from 'react';

export const GetOgImage = (result: SearchResult) => {
  const image = GetFullscreenImage(
    result.items[0]?.images[0]?.folderId,
    result.items[0]?.images[0]?.id,
  );
  return (
    <>
      <meta key="og:image" property="og:image" content={image} />
      <meta
        key="og:image:secure_url"
        property="og:image:secure_url"
        content={image}
      />
      <meta key="og:image:width" property="og:image:width" content={'1440'} />
      <meta key="og:image:height" property="og:image:height" content={'1080'} />
    </>
  );
};
