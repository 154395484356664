import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

export const Fader = ({ maxHeight, mobileMaxHeight, children }) => {
  return (
    <>
      <Box
        sx={{ maxHeight: { md: mobileMaxHeight, xs: mobileMaxHeight } }}
        overflow={'hidden'}
        position={'relative'}>
        <Box
          bgcolor={'black'}
          style={{
            background:
              'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(236,244,246,1) 100%)',
          }}
          position={'absolute'}
          bottom={0}
          height={'150px'}
          width={'100%'}></Box>
        {children}
      </Box>
    </>
  );
};
