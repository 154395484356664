import PrimaryClock from '@/shared/icons/clock-yellow.svg';
import { Colors } from '@/shared/util/colors';
import { Grid } from '@mui/material';
import Image from '@/web/views/components/image/image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchBarSRP } from './search-bar-srp';

export const ReceiveNewAdsInYourMail = () => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      borderRadius={2.5}
      bgcolor={Colors.LightBlue}
      paddingX={3}
      paddingY={2}>
      <Grid
        item
        md={4}
        xs={12}
        sx={{ display: { md: 'flex', xs: 'none' } }}
        alignItems={'center'}
        justifyContent={'center'}>
        <Image
          height={110}
          width={108}
          alt="Notifikation"
          loading={'lazy'}
          src={PrimaryClock.src}
        />
      </Grid>
      <Grid item md={8} xs={12}>
        <SearchBarSRP
          theme="yellow"
          header={t('SRP.DidNotFindResultsHeader')}
          subHeader={t('SRP.DidNotFindResultsSubHeader')}
        />
      </Grid>
    </Grid>
  );
};
