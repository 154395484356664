/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiBlockListModel,
    ApiBlockListModelFromJSON,
    ApiBlockListModelFromJSONTyped,
    ApiBlockListModelToJSON,
} from './ApiBlockListModel';
import {
    HeadlinePropertiesModel,
    HeadlinePropertiesModelFromJSON,
    HeadlinePropertiesModelFromJSONTyped,
    HeadlinePropertiesModelToJSON,
} from './HeadlinePropertiesModel';
import {
    IApiMediaWithCropsModel,
    IApiMediaWithCropsModelFromJSON,
    IApiMediaWithCropsModelFromJSONTyped,
    IApiMediaWithCropsModelToJSON,
} from './IApiMediaWithCropsModel';
import {
    MakeOverviewPropertiesModel,
    MakeOverviewPropertiesModelFromJSON,
    MakeOverviewPropertiesModelFromJSONTyped,
    MakeOverviewPropertiesModelToJSON,
} from './MakeOverviewPropertiesModel';
import {
    SEOPropertiesModel,
    SEOPropertiesModelFromJSON,
    SEOPropertiesModelFromJSONTyped,
    SEOPropertiesModelToJSON,
} from './SEOPropertiesModel';
import {
    SektionListPropertiesModel,
    SektionListPropertiesModelFromJSON,
    SektionListPropertiesModelFromJSONTyped,
    SektionListPropertiesModelToJSON,
} from './SektionListPropertiesModel';

/**
 * 
 * @export
 * @interface AIseoPropertiesModel
 */
export interface AIseoPropertiesModel {
    /**
     * 
     * @type {boolean}
     * @memberof AIseoPropertiesModel
     */
    visMaerker?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AIseoPropertiesModel
     */
    header?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AIseoPropertiesModel
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AIseoPropertiesModel
     */
    metaDescription?: string | null;
    /**
     * 
     * @type {Array<IApiMediaWithCropsModel>}
     * @memberof AIseoPropertiesModel
     */
    ogimage?: Array<IApiMediaWithCropsModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AIseoPropertiesModel
     */
    noindex?: boolean | null;
    /**
     * 
     * @type {ApiBlockListModel}
     * @memberof AIseoPropertiesModel
     */
    sektioner?: ApiBlockListModel;
}

export function AIseoPropertiesModelFromJSON(json: any): AIseoPropertiesModel {
    return AIseoPropertiesModelFromJSONTyped(json, false);
}

export function AIseoPropertiesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIseoPropertiesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'visMaerker': !exists(json, 'visMaerker') ? undefined : json['visMaerker'],
        'header': !exists(json, 'header') ? undefined : json['header'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'metaDescription': !exists(json, 'metaDescription') ? undefined : json['metaDescription'],
        'ogimage': !exists(json, 'ogimage') ? undefined : (json['ogimage'] === null ? null : (json['ogimage'] as Array<any>).map(IApiMediaWithCropsModelFromJSON)),
        'noindex': !exists(json, 'noindex') ? undefined : json['noindex'],
        'sektioner': !exists(json, 'sektioner') ? undefined : ApiBlockListModelFromJSON(json['sektioner']),
    };
}

export function AIseoPropertiesModelToJSON(value?: AIseoPropertiesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'visMaerker': value.visMaerker,
        'header': value.header,
        'title': value.title,
        'metaDescription': value.metaDescription,
        'ogimage': value.ogimage === undefined ? undefined : (value.ogimage === null ? null : (value.ogimage as Array<any>).map(IApiMediaWithCropsModelToJSON)),
        'noindex': value.noindex,
        'sektioner': ApiBlockListModelToJSON(value.sektioner),
    };
}

