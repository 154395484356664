/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IApiElementModel,
    IApiElementModelFromJSON,
    IApiElementModelFromJSONTyped,
    IApiElementModelToJSON,
} from './IApiElementModel';
import {
    SEOPropertiesModel,
    SEOPropertiesModelFromJSON,
    SEOPropertiesModelFromJSONTyped,
    SEOPropertiesModelToJSON,
} from './SEOPropertiesModel';

/**
 * 
 * @export
 * @interface SEOElementModel
 */
export interface SEOElementModel extends IApiElementModel {
    /**
     * 
     * @type {SEOPropertiesModel}
     * @memberof SEOElementModel
     */
    properties?: SEOPropertiesModel;
}

export function SEOElementModelFromJSON(json: any): SEOElementModel {
    return SEOElementModelFromJSONTyped(json, false);
}

export function SEOElementModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SEOElementModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...IApiElementModelFromJSONTyped(json, ignoreDiscriminator),
        'properties': !exists(json, 'properties') ? undefined : SEOPropertiesModelFromJSON(json['properties']),
    };
}

export function SEOElementModelToJSON(value?: SEOElementModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...IApiElementModelToJSON(value),
        'properties': SEOPropertiesModelToJSON(value.properties),
    };
}

