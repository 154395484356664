import React from 'react';
import { Box } from '@mui/material';
import closeIcon from '@/shared/icons/open-close-icon.svg';
import closeWhtieIcon from '@/shared/icons/open-close-white-icon.svg';
import Image from '@/web/views/components/image/image';
interface PropsFromParent {
  onClose: React.MouseEventHandler<HTMLImageElement>;
}

const cursorPointer = { cursor: 'pointer' };

export const DialogClose = (props: PropsFromParent) => {
  return (
    <Box sx={cursorPointer}>
      <Image
        alt="Luk vindue"
        height={12}
        width={12}
        src={closeIcon.src}
        onClick={props.onClose}
      />
    </Box>
  );
};

export const DialogCloseCenter = (props: PropsFromParent) => {
  return (
    <Box
      sx={cursorPointer}
      display="flex"
      alignItems="center"
      justifyContent={'center'}>
      <Image
        alt="Luk vindue"
        height={14}
        width={14}
        src={closeWhtieIcon.src}
        onClick={props.onClose}
      />
    </Box>
  );
};

export const DialogMessageCloseWhite = (props: PropsFromParent) => {
  return (
    <Box
      sx={cursorPointer}
      display="flex"
      alignItems="center"
      marginLeft={1}
      justifyContent={'center'}>
      <Image
        alt="Luk vindue"
        height={14}
        width={14}
        src={closeWhtieIcon.src}
        onClick={props.onClose}
      />
    </Box>
  );
};

export const DialogCloseWhite = (props: PropsFromParent) => {
  return (
    <Box sx={cursorPointer}>
      <Image
        alt="Luk vindue"
        height={12}
        width={12}
        src={closeWhtieIcon.src}
        onClick={props.onClose}
      />
    </Box>
  );
};
