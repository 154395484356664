import { useApi } from '@/shared/util/api';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { ClassifiedItem } from '@/shared/lib-api';
import Cookies from 'js-cookie';
import BasicCarListCard from '../cards/basic-car-card/basic-car-list-card';
import { BasicCarCardType } from '@/shared/hooks/basic-car-hook';
import { VipHeadline } from './headline';
import { BasicCarGalleryCard } from '../cards/basic-car-card/basic-car-gallery-card';
import { useRequestHelper } from '@/shared/hooks/request-helper';
import { LoadingState } from '@/shared/util/general-helpers';

export const Recommendations = props => {
  const { classifiedApi } = useApi();
  const { classifiedId } = props;
  const [items, setItems] = useState<ClassifiedItem[]>([]);
  const { handleRequest, requestState } = useRequestHelper();

  const load = useCallback(
    async (load: boolean) => {
      if (!load && items.length !== 0) return;
      const cookie = Cookies.get('_tty');

      if (!cookie) {
        return;
      }
      const req = classifiedApi.apiClassifiedRecommendationsPost({
        recommendationsRequest: {
          tTyUserId: cookie,
          classifiedId: classifiedId,
          excludeIds: items.map(item => item.id),
        },
      });

      const [result] = await handleRequest(req);
      setItems([...items, ...result]);
    },
    [classifiedId, items, classifiedApi, handleRequest],
  );

  useEffect(() => {
    load(false);
  }, [load]);

  if (items.length === 0) return <></>;

  return (
    <Box marginTop={2}>
      <VipHeadline headline="Udvalgte biler" />
      <Grid marginTop={1} container spacing={2}>
        {items.map(item => (
          <BasicCarGalleryCard
            key={item.id}
            origin={BasicCarCardType.SRPGallery}
            item={item}
          />
        ))}
        <Grid item xs={12}>
          <Box display="flex" justifyContent={'center'}>
            <Button
              style={{
                paddingRight: '36px',
                paddingLeft: '36px',
              }}
              id="itemwise-show-more"
              disabled={requestState === LoadingState.Loading}
              onClick={() => load(true)}>
              {requestState === LoadingState.Loading
                ? 'Henter flere biler...'
                : 'Vis flere biler'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
