import React from 'react';
import { useAppSelector } from '@/shared/store/hooks';
import { SearchBarSRP } from './search-bar-srp';
import { Box, Link, Skeleton, Typography } from '@mui/material';
import {
  GetH1WithArea,
  GetH1Amount,
} from '@/web/util/helpers/seo-srp-helpers/title-meta-helpers';
import { LoadingState } from '@/shared/util/general-helpers';
import { useScreenSizeContext } from '@/pages/_app';
import { VipCollapse } from '../vip/collapse';
import { useTranslation } from 'react-i18next';
export const DidFindResults = (props: { loadingState: LoadingState }) => {
  const searchOptions = useAppSelector(state => state.srpReducer.searchOptions);
  const srptext = useAppSelector(state => state.srpReducer.srpText);
  const srpHeadline = useAppSelector(state => state.srpReducer.srpHeadline);
  const searchResult = useAppSelector(state => state.srpReducer.searchData);
  const { isMobile } = useScreenSizeContext();
  const [collapsed, setCollapsed] = React.useState(true);
  const { t } = useTranslation();

  return (
    <SearchBarSRP header={''}>
      {srpHeadline == '' ? (
        <>
          <Typography display="inline" fontSize={18}>
            {isMobile ? '' : 'Vi har fundet '}
          </Typography>
          <Typography component={'h1'} display={'inline'} fontSize={18}>
            {props.loadingState === LoadingState.Loading ? (
              <Skeleton
                sx={{ display: 'inline-flex', marginRight: '4px' }}
                width={55}
              />
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: GetH1Amount(searchResult),
                }}></span>
            )}
            <span
              dangerouslySetInnerHTML={{
                __html: GetH1WithArea(searchResult, searchOptions),
              }}></span>
          </Typography>
        </>
      ) : (
        <>
          <Typography display="inline" fontSize={18}>
            <span
              dangerouslySetInnerHTML={{
                __html: srpHeadline.replace(
                  '[[amount]]',
                  GetH1Amount(searchResult),
                ),
              }}
            />
          </Typography>
          <Box marginTop={1}>
            <VipCollapse backgroundColor="#F6FAFB" collapsed={collapsed}>
              {srptext && (
                <Typography whiteSpace={'break-spaces'}>{srptext}</Typography>
              )}
            </VipCollapse>
            {srptext && srptext.length > 400 && (
              <Typography sx={{ cursor: 'pointer' }}>
                <Link
                  onClick={() => setCollapsed(!collapsed)}
                  underline="none"
                  fontSize={'15px'}
                  id={
                    collapsed ? 'lnk-seotext-showmore' : 'lnk-seotext-showless'
                  }>
                  {collapsed ? t('SRP.ShowMore') : t('SRP.ShowLess')}
                </Link>
              </Typography>
            )}
          </Box>
        </>
      )}
    </SearchBarSRP>
  );
};
