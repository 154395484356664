import React from 'react';
import { Box, Typography } from '@mui/material';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import { Trans, useTranslation } from 'react-i18next';
import { cursorPointer } from '@/shared/util/helpers/general-helper';

export const CallIcon = (props: {
  loading: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  label?: string;
}) => {
  const { t } = useTranslation();
  const label = props.label ? props.label : t('Labels.CallNow');

  return (
    <Box
      display={'flex'}
      alignItems="center"
      onClick={props.onClick}
      flexWrap={'nowrap'}>
      <PhoneInTalkIcon
        fontSize="small"
        color={'secondary'}
        sx={cursorPointer}
      />
      <Typography
        paddingLeft={0.2}
        variant={props.loading ? 'body1' : 'clickAble'}
        whiteSpace={'nowrap'}
        textOverflow={'ellipsis'}
        overflow={'hidden'}>
        {label}
      </Typography>
    </Box>
  );
};
