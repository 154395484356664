/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiBlockListModel,
    ApiBlockListModelFromJSON,
    ApiBlockListModelFromJSONTyped,
    ApiBlockListModelToJSON,
} from './ApiBlockListModel';
import {
    AuthorPropertiesModel,
    AuthorPropertiesModelFromJSON,
    AuthorPropertiesModelFromJSONTyped,
    AuthorPropertiesModelToJSON,
} from './AuthorPropertiesModel';
import {
    HeadlinePropertiesModel,
    HeadlinePropertiesModelFromJSON,
    HeadlinePropertiesModelFromJSONTyped,
    HeadlinePropertiesModelToJSON,
} from './HeadlinePropertiesModel';
import {
    IApiMediaWithCropsModel,
    IApiMediaWithCropsModelFromJSON,
    IApiMediaWithCropsModelFromJSONTyped,
    IApiMediaWithCropsModelToJSON,
} from './IApiMediaWithCropsModel';
import {
    MakeModelPickerPropertiesModel,
    MakeModelPickerPropertiesModelFromJSON,
    MakeModelPickerPropertiesModelFromJSONTyped,
    MakeModelPickerPropertiesModelToJSON,
} from './MakeModelPickerPropertiesModel';
import {
    RelatedArticle,
    RelatedArticleFromJSON,
    RelatedArticleFromJSONTyped,
    RelatedArticleToJSON,
} from './RelatedArticle';
import {
    RelatedArticlesPropertiesModel,
    RelatedArticlesPropertiesModelFromJSON,
    RelatedArticlesPropertiesModelFromJSONTyped,
    RelatedArticlesPropertiesModelToJSON,
} from './RelatedArticlesPropertiesModel';
import {
    SEOPropertiesModel,
    SEOPropertiesModelFromJSON,
    SEOPropertiesModelFromJSONTyped,
    SEOPropertiesModelToJSON,
} from './SEOPropertiesModel';
import {
    SektionListPropertiesModel,
    SektionListPropertiesModelFromJSON,
    SektionListPropertiesModelFromJSONTyped,
    SektionListPropertiesModelToJSON,
} from './SektionListPropertiesModel';

/**
 * 
 * @export
 * @interface BilvidenPagePropertiesModel
 */
export interface BilvidenPagePropertiesModel {
    /**
     * 
     * @type {Array<IApiMediaWithCropsModel>}
     * @memberof BilvidenPagePropertiesModel
     */
    nyhedsbillede?: Array<IApiMediaWithCropsModel> | null;
    /**
     * 
     * @type {string}
     * @memberof BilvidenPagePropertiesModel
     */
    header?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BilvidenPagePropertiesModel
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BilvidenPagePropertiesModel
     */
    metaDescription?: string | null;
    /**
     * 
     * @type {Array<IApiMediaWithCropsModel>}
     * @memberof BilvidenPagePropertiesModel
     */
    ogimage?: Array<IApiMediaWithCropsModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof BilvidenPagePropertiesModel
     */
    noindex?: boolean | null;
    /**
     * 
     * @type {ApiBlockListModel}
     * @memberof BilvidenPagePropertiesModel
     */
    sektioner?: ApiBlockListModel;
    /**
     * 
     * @type {string}
     * @memberof BilvidenPagePropertiesModel
     */
    makeModel?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BilvidenPagePropertiesModel
     */
    disableArticles?: boolean | null;
    /**
     * 
     * @type {Array<RelatedArticle>}
     * @memberof BilvidenPagePropertiesModel
     */
    articles?: Array<RelatedArticle> | null;
    /**
     * 
     * @type {string}
     * @memberof BilvidenPagePropertiesModel
     */
    authorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BilvidenPagePropertiesModel
     */
    authorPerson?: string | null;
}

export function BilvidenPagePropertiesModelFromJSON(json: any): BilvidenPagePropertiesModel {
    return BilvidenPagePropertiesModelFromJSONTyped(json, false);
}

export function BilvidenPagePropertiesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BilvidenPagePropertiesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nyhedsbillede': !exists(json, 'nyhedsbillede') ? undefined : (json['nyhedsbillede'] === null ? null : (json['nyhedsbillede'] as Array<any>).map(IApiMediaWithCropsModelFromJSON)),
        'header': !exists(json, 'header') ? undefined : json['header'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'metaDescription': !exists(json, 'metaDescription') ? undefined : json['metaDescription'],
        'ogimage': !exists(json, 'ogimage') ? undefined : (json['ogimage'] === null ? null : (json['ogimage'] as Array<any>).map(IApiMediaWithCropsModelFromJSON)),
        'noindex': !exists(json, 'noindex') ? undefined : json['noindex'],
        'sektioner': !exists(json, 'sektioner') ? undefined : ApiBlockListModelFromJSON(json['sektioner']),
        'makeModel': !exists(json, 'makeModel') ? undefined : json['makeModel'],
        'disableArticles': !exists(json, 'disableArticles') ? undefined : json['disableArticles'],
        'articles': !exists(json, 'articles') ? undefined : (json['articles'] === null ? null : (json['articles'] as Array<any>).map(RelatedArticleFromJSON)),
        'authorName': !exists(json, 'authorName') ? undefined : json['authorName'],
        'authorPerson': !exists(json, 'authorPerson') ? undefined : json['authorPerson'],
    };
}

export function BilvidenPagePropertiesModelToJSON(value?: BilvidenPagePropertiesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nyhedsbillede': value.nyhedsbillede === undefined ? undefined : (value.nyhedsbillede === null ? null : (value.nyhedsbillede as Array<any>).map(IApiMediaWithCropsModelToJSON)),
        'header': value.header,
        'title': value.title,
        'metaDescription': value.metaDescription,
        'ogimage': value.ogimage === undefined ? undefined : (value.ogimage === null ? null : (value.ogimage as Array<any>).map(IApiMediaWithCropsModelToJSON)),
        'noindex': value.noindex,
        'sektioner': ApiBlockListModelToJSON(value.sektioner),
        'makeModel': value.makeModel,
        'disableArticles': value.disableArticles,
        'articles': value.articles === undefined ? undefined : (value.articles === null ? null : (value.articles as Array<any>).map(RelatedArticleToJSON)),
        'authorName': value.authorName,
        'authorPerson': value.authorPerson,
    };
}

