import { Grid } from '@mui/material';
import { SearchBarSRP } from './search-bar-srp';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '@/shared/util/colors';
import PrimaryClock from '@/shared/icons/clock-primary.svg';
import Image from '@/web/views/components/image/image';

export const DidNotFindResults = () => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      borderRadius={2.5}
      bgcolor={Colors.Background.TableHead}
      paddingX={3}
      paddingY={2}
      rowGap={3}>
      <Grid
        item
        md={4}
        xs={12}
        sx={{ display: { md: 'flex', xs: 'none' } }}
        alignItems={'center'}
        justifyContent={'center'}
        paddingRight={3}>
        <Image
          alt="Notifikation"
          width={108}
          height={110}
          src={PrimaryClock.src}
        />
      </Grid>
      <Grid item md={8} xs={12}>
        <SearchBarSRP
          theme="lightBlue"
          header={t('SRP.DidNotFindResultsHeader')}
          subHeader={t('SRP.DidNotFindResultsSubHeader')}
        />
      </Grid>
    </Grid>
  );
};
