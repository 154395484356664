import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import { Colors } from '@/shared/util/colors';
import { useTranslation } from 'react-i18next';
import TextWithIcon from '../text-with-icon/text-with-icon';
import { cursorPointer } from '@/shared/util/helpers/general-helper';
import shareIcon from '@/shared/icons/share-icon.svg';
import Link from 'next/link';
import { useVipContext } from '@/pages/[headline]/[[...id]]';
import {
  BuildInTrackEvents,
  TrackClassifiedItemEvent,
} from '@/shared/util/gtm';

export const ClassifiedShare = () => {
  const { t } = useTranslation();
  const { item } = useVipContext();

  const trackShare = useCallback(() => {
    TrackClassifiedItemEvent(BuildInTrackEvents.OnClassifiedShared, item);
  }, [item]);

  return (
    <Box sx={cursorPointer}>
      <Link
        onClick={trackShare}
        target={'_blank'}
        href={`http://www.facebook.com/share.php?u=${item.url}`}>
        <TextWithIcon
          iconHeight={16}
          iconWidth={16}
          icon={shareIcon.src}
          textcolor={Colors.Black}
          text={t('Labels.ShareAd')}
        />
      </Link>
    </Box>
  );
};
