import {
  ClassifiedDisplayItem,
  ClassifiedItem,
  SearchbotModelDisplayItem,
} from '@/shared/lib-api';
import { Colors } from '@/shared/util/colors';
import { cursorPointer } from '@/shared/util/helpers/general-helper';
import { Box, Grid, styled, Typography } from '@mui/material';
import Link from 'next/link';
import React, { useCallback, useMemo } from 'react';
import { GalleryBoxGalleryCard } from '../../default-components/gallery/gallery-box-srp';
import { useRouter } from 'next/router';
import slugify from 'slugify';
import { slugifyOptions } from '@/web/util/helpers/url-helpers';
import TagManager from 'react-gtm-module';
import { createRoute } from '../../SRP/bottom-link-srp';
import {
  GetMediumImage,
  GetSmallImage,
} from '@/shared/util/helpers/image-helper';

interface SeoModelGalleryCardProps {
  item?: SearchbotModelDisplayItem;
  onClick?: () => void;
  big?: boolean;
}

const MakeModel = styled(Typography)({
  fontSize: '17px',
  fontWeight: '700',
  lineHeight: '30px',
});

export const SearchBotModelGalleryCard = (props: SeoModelGalleryCardProps) => {
  const trackClick = useCallback(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'DL_CLICK_RELATED_MODEL_SEO_SRP',
        dlv_seo_srp_model: props.item.model,
      },
    });
  }, [props.item.model]);

  return (
    <Grid
      id="searchbot-model-gallery-card"
      item
      xs={12}
      sm={6}
      md={props.big ? 6 : 4}
      style={{ filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15))' }}
      sx={cursorPointer}>
      <Box
        onClick={() => {
          trackClick();
          props.onClick();
        }}>
        <Box position="relative">
          <GalleryBoxGalleryCard
            minHeight={0}
            items={
              props.big
                ? [
                    GetMediumImage(
                      props.item.image?.folderId,
                      props.item.image?.id,
                    ),
                  ]
                : [
                    GetSmallImage(
                      props.item.image?.folderId,
                      props.item.image?.id,
                    ),
                  ]
            }
            top-border={true}
            seoSrp
            alt={props.item?.make + ' ' + props.item?.model}
          />
          <Box
            height={'100%'}
            width={'100%'}
            borderRadius={'10px 10px 0px 0px'}
            top={0}
            position="absolute"
            style={{
              background: `linear-gradient(${Colors.LightBlue}, ${Colors.Primary})`,
              opacity: 0.3,
            }}
          />
        </Box>

        <Box
          sx={{
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            padding: {
              xs: '11px 15px 10px 15px',
              md: '19px 14px 14px 19px',
            },
            //minHeight: 195,
          }}
          bgcolor={Colors.White}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyItems={'center'}
            justifyContent={'space-between'}>
            <Box>
              <Grid container width={'100%'} justifyContent="space-between">
                <Grid item xs={12}>
                  <MakeModel>
                    {props.item?.make} {props.item?.model}
                  </MakeModel>
                </Grid>
                <Grid item xs={12}>
                  {props.item.bulletpoints.map(p => (
                    <Box key={p} display={'flex'}>
                      <Typography marginRight={0.3}>-</Typography>
                      <Typography key={p}>{p}</Typography>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};
