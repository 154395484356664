import { useVipContext } from '@/pages/[headline]/[[...id]]';
import { Config } from '@/shared/util/config';
import { thousandNumberSeperator } from '@/shared/util/general-helpers';
import { GetFullscreenImage } from '@/shared/util/helpers/image-helper';
import {
  GetStructuredVipAggregateOfferData,
  GetStructuredVipCarData,
} from '@/web/util/helpers/seo-srp-helpers/structureddata-helpers';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

const VipHead = () => {
  const router = useRouter();
  const { item } = useVipContext();

  const descriptionText = useMemo(() => {
    if (
      item &&
      item.horsepower &&
      item.mileage &&
      item.systemData.price &&
      item.yearOfManufacture &&
      item.maxSpeed
    ) {
      return `${item.headLine} for ${item.priceFormatted} kr. Bilen er fra ${
        item.yearOfManufacture
      } med ${thousandNumberSeperator(
        item.horsepower,
      )} hestekræfter og har kørt ${thousandNumberSeperator(
        item.mileage,
      )} kilometer. Den har en topfart på ${item.maxSpeed} km/t.`;
    } else if (item && item.mileage) {
      return `Køb din næste ${item.make} ${
        item.model
      } på Bilhandel. Bilen er fra ${
        item.yearOfManufacture
      } og har kørt ${thousandNumberSeperator(
        item.mileage,
      )} kilometer. Du kan hurtigt og nemt kontakte sælger og give et bud!`;
    }
    return null;
  }, [item]);

  const titleText = useMemo(() => {
    return item ? `${item.headLine} - ${item.priceFormatted}` : null;
  }, [item]);

  const imageUrl = useMemo(() => {
    return item?.images.length > 0
      ? GetFullscreenImage(item.images[0].folderId, item.images[0].id)
      : null;
  }, [item]);

  const videoUrl = useMemo(() => {
    return item?.videos.length > 0 ? item.videos[0].videoUrl : null;
  }, [item]);

  const noIndex = true;

  const url = useMemo(() => {
    return 'https://bilhandel.dk' + router.asPath.split('?')[0];
  }, [router.asPath]);

  const modifiedTime = useMemo(() => {
    if (!item?.systemData) {
      return null;
    }

    return item.systemData.lastEditedDate
      ? new Date(item.systemData.lastEditedDate).toISOString()
      : new Date(item.systemData.createdDate).toISOString();
  }, [item]);

  if (!item) {
    return null;
  }

  return (
    <Head>
      {titleText && (
        <>
          <title key="title">{titleText}</title>
          <meta property="og:title" content={titleText} key="og-title" />
        </>
      )}
      {descriptionText && (
        <>
          <meta
            name="description"
            content={descriptionText}
            key="meta-description"
          />
          <meta
            property="og:description"
            content={descriptionText}
            key="og-description"
          />
        </>
      )}
      {url && (
        <>
          <link rel="canonical" href={url} key="link-url" />
          <meta property="og:url" content={url} key="og-url" />
        </>
      )}
      {imageUrl && (
        <>
          <meta property="og:image" content={imageUrl} key="og-image" />
          <meta
            property="og:image:secure_url"
            content={imageUrl}
            key="og-image-secure_url"
          />
          <meta property="og:image:width" content="1440" key="og-image-width" />
          <meta
            property="og:image:height"
            content="1080"
            key="og-image-height"
          />
        </>
      )}
      {videoUrl && (
        <meta property="og:video" content={videoUrl} key="og-video" />
      )}
      {modifiedTime && (
        <meta name="article:modified_time" content={modifiedTime} />
      )}
      <meta property="og:type" content="product" key="og-type" />

      {noIndex && <meta name="robots" content="noindex" />}

      {GetStructuredVipAggregateOfferData(item)}

      {GetStructuredVipCarData(item)}
    </Head>
  );
};

export default VipHead;
