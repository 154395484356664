import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Colors } from '@/shared/util/colors';
import { useTranslation } from 'react-i18next';
import TextWithIcon from '../text-with-icon/text-with-icon';
import { cursorPointer } from '@/shared/util/helpers/general-helper';
import notFavoriteIcon from '@/shared/icons/favorite/not_favorite.svg';
import isFavoriteIcon from '@/shared/icons/favorite/is_favorite.svg';
import hoverFavorite from '@/shared/icons/favorite/hover_favorite.svg';
import { ClassifiedItem } from '@/shared/lib-api';
import { useAuth } from '@/shared/hooks/auth-hook';
import { useBasicCar } from '@/shared/hooks/basic-car-hook';
import { useScreenSizeContext } from '@/pages/_app';
import { LoadingState } from '@/shared/util/general-helpers';

export const ClassifiedFavorite = (props: { item: ClassifiedItem }) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuth();
  const { isMobile } = useScreenSizeContext();

  const [isHovering, setIsHovered] = useState(false);
  const onMouseEnter = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);

  const {
    loginAndLike,
    addLike,
    removeLike,
    isCurrentlyLiked,
    loading,
  } = useBasicCar({
    item: props.item,
  });

  const [initialLoggedInState, setInitialLoggedInState] =
    useState<boolean>(false);

  useEffect(() => {
    setInitialLoggedInState(isLoggedIn);
  }, [isLoggedIn]);

  const handleFavorite = () => {
    // if loggedin => toggle favorite
    // if not loggedin => always add
    if (initialLoggedInState) {
      if (isCurrentlyLiked) {
        removeLike();
      } else {
        addLike();
      }
    } else {
      loginAndLike();
    }
    setIsHovered(false);
  };

  const saveText = isCurrentlyLiked
    ? isMobile
      ? t('Vip.SavedAsFavoriteShort')
      : t('Vip.SavedAsFavorite')
    : isMobile
    ? t('Vip.SaveAsFavoriteShort')
    : t('Vip.SaveAsFavorite');

  return (
    <Box
      onClick={loading === LoadingState.Loading ? () => {} : handleFavorite}
      sx={cursorPointer}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <TextWithIcon
        iconHeight={40}
        iconWidth={40}
        icon={
          isCurrentlyLiked
            ? isFavoriteIcon.src
            : isHovering
            ? hoverFavorite.src
            : notFavoriteIcon.src
        }
        textcolor={Colors.Black}
        text={saveText}
      />
    </Box>
  );
};
