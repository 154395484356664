/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IApiContentModel,
    IApiContentModelFromJSON,
    IApiContentModelFromJSONTyped,
    IApiContentModelToJSON,
} from './IApiContentModel';
import {
    IApiContentRouteModel,
    IApiContentRouteModelFromJSON,
    IApiContentRouteModelFromJSONTyped,
    IApiContentRouteModelToJSON,
} from './IApiContentRouteModel';

import {
     AiseopageContentResponseModelFromJSONTyped,
     LandingPageContentResponseModelFromJSONTyped,
     AIseoContentResponseModelFromJSONTyped,
     BilvidenContentResponseModelFromJSONTyped,
     BilvidenKategoriContentResponseModelFromJSONTyped,
     BilvidenPageContentResponseModelFromJSONTyped,
     DefaultContentResponseModelFromJSONTyped,
     NyhederContentResponseModelFromJSONTyped,
     RootContentResponseModelFromJSONTyped
} from './';

/**
 * 
 * @export
 * @interface IApiContentResponseModel
 */
export interface IApiContentResponseModel extends IApiContentModel {
    /**
     * 
     * @type {{ [key: string]: IApiContentRouteModel; }}
     * @memberof IApiContentResponseModel
     */
    readonly cultures?: { [key: string]: IApiContentRouteModel; };
    /**
     * 
     * @type {string}
     * @memberof IApiContentResponseModel
     */
    readonly name?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof IApiContentResponseModel
     */
    readonly createDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IApiContentResponseModel
     */
    readonly updateDate?: Date;
    /**
     * 
     * @type {IApiContentRouteModel}
     * @memberof IApiContentResponseModel
     */
    route?: IApiContentRouteModel;
    /**
     * 
     * @type {string}
     * @memberof IApiContentResponseModel
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof IApiContentResponseModel
     */
    readonly contentType?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof IApiContentResponseModel
     */
    readonly properties?: { [key: string]: any; };
}

export function IApiContentResponseModelFromJSON(json: any): IApiContentResponseModel {
    return IApiContentResponseModelFromJSONTyped(json, false);
}

export function IApiContentResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IApiContentResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['contentType'] === 'AISEOPage') {
            return AiseopageContentResponseModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'LandingPage') {
            return LandingPageContentResponseModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'aISEO') {
            return AIseoContentResponseModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'bilviden') {
            return BilvidenContentResponseModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'bilvidenKategori') {
            return BilvidenKategoriContentResponseModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'bilvidenPage') {
            return BilvidenPageContentResponseModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'default') {
            return DefaultContentResponseModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'nyheder') {
            return NyhederContentResponseModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'root') {
            return RootContentResponseModelFromJSONTyped(json, true);
        }
    }
    return {
        ...IApiContentModelFromJSONTyped(json, ignoreDiscriminator),
        'cultures': !exists(json, 'cultures') ? undefined : (mapValues(json['cultures'], IApiContentRouteModelFromJSON)),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'createDate': !exists(json, 'createDate') ? undefined : (new Date(json['createDate'])),
        'updateDate': !exists(json, 'updateDate') ? undefined : (new Date(json['updateDate'])),
        'route': !exists(json, 'route') ? undefined : IApiContentRouteModelFromJSON(json['route']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'contentType': !exists(json, 'contentType') ? undefined : json['contentType'],
        'properties': !exists(json, 'properties') ? undefined : json['properties'],
    };
}

export function IApiContentResponseModelToJSON(value?: IApiContentResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...IApiContentModelToJSON(value),
        'route': IApiContentRouteModelToJSON(value.route),
    };
}

