/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FaqPropertiesModel
 */
export interface FaqPropertiesModel {
    /**
     * 
     * @type {string}
     * @memberof FaqPropertiesModel
     */
    question?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FaqPropertiesModel
     */
    answer?: string | null;
}

export function FaqPropertiesModelFromJSON(json: any): FaqPropertiesModel {
    return FaqPropertiesModelFromJSONTyped(json, false);
}

export function FaqPropertiesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FaqPropertiesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'question': !exists(json, 'question') ? undefined : json['question'],
        'answer': !exists(json, 'answer') ? undefined : json['answer'],
    };
}

export function FaqPropertiesModelToJSON(value?: FaqPropertiesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'question': value.question,
        'answer': value.answer,
    };
}

