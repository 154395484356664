import React, { ReactNode } from 'react';
import { ConditionalWrapper } from './conditional-wrapper';
import { Fader } from './fader';

export const ConditionalFader = ({
  condition,
  maxHeight,
  mobileMaxHeight,
  children,
}) => {
  return (
    <ConditionalWrapper
      condition={condition}
      wrapper={innerChildren => (
        <Fader maxHeight={maxHeight} mobileMaxHeight={mobileMaxHeight}>
          {innerChildren}
        </Fader>
      )}>
      {children}
    </ConditionalWrapper>
  );
};
