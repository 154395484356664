import { Colors } from '@/shared/util/colors';
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropDownStyle } from '../default-components/input/input-icons/dropdownIcon';
import { DropDownArrow } from '../default-components/input/input-icons/dropdownSortIcon';
import { SearchResult } from '@/shared/lib-api';
import {
  initialSortAttribute,
  sortValues,
} from '@/shared/store/reducers/SRPSlice';

interface DealerSearchSortProps {
  sort: string;
  onSortChange: React.Dispatch<React.SetStateAction<string>>;
  searchResult?: SearchResult;
}

const selectStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};

const selectInputStyle: React.CSSProperties = {
  fontSize: 15,
  color: Colors.Secondary,
  lineHeight: '22px',
};

const DealerSearchSort = (props: DealerSearchSortProps) => {
  const [sort, setSort] = useState<string>(initialSortAttribute.value);

  const { t } = useTranslation();

  const items = React.useMemo(() => {
    return sortValues
      ?.filter((p, i) => i < sortValues.length - 4)
      ?.map((opt, index) => {
        return (
          <MenuItem key={index} value={opt.value}>
            {t(opt.label)}
          </MenuItem>
        );
      });
  }, [t]);

  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onValueChanged = React.useCallback(
    (event: SelectChangeEvent<string>) => {
      var item = event.target.value;
      setSort(item);

      if (props.onSortChange) {
        props.onSortChange(item);
      }
    },
    [props],
  );

  const iconComponent = useMemo(() => {
    return (
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <IconButton style={DropDownStyle(open)} onClick={handleOpen}>
          {DropDownArrow}
        </IconButton>
      </Box>
    );
  }, [handleOpen, open]);

  if (!props.searchResult || props.searchResult.totalResults <= 1) {
    return null;
  }

  return (
    <Box
      marginTop={3}
      width={'100%'}
      display={'flex'}
      alignItems="flex-end"
      flexDirection={'column'}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontWeight={500} color={Colors.Primary}>
          {t('SRP.SortAfter')}
        </Typography>
        <Box>
          <Select
            color="secondary"
            style={selectStyle}
            SelectDisplayProps={{ style: selectInputStyle }}
            displayEmpty
            fullWidth
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            IconComponent={() => {
              return iconComponent;
            }}
            renderValue={selectedValue => {
              return sortValues.find(p => p.value === selectedValue)?.label;
            }}
            value={sort}
            onChange={onValueChanged}>
            {items}
          </Select>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(DealerSearchSort);
