import { BasicCarCardType } from '@/shared/hooks/basic-car-hook';
import { SearchResult } from '@/shared/lib-api';
import { Box, Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BasicCarGalleryCard } from '../cards/basic-car-card/basic-car-gallery-card';

import { PadBox } from '../containers/pad-box';

interface DealerSearchResultProps {
  searchResult?: SearchResult;
}

export const DealerSearchResult = (props: DealerSearchResultProps) => {
  const { t } = useTranslation();

  const Items = useMemo(() => {
    return props.searchResult.items;
  }, [props.searchResult.items]);

  return (
    <Box
      sx={{
        marginTop: { xs: '0.6rem', md: '1.5rem' },
      }}>
      <Grid container spacing={3}>
        {Items.map(item => {
          return (
            <BasicCarGalleryCard
              origin={BasicCarCardType.SRPGallery}
              key={item.id}
              item={item}
              exclude-owner={true}
            />
          );
        })}
      </Grid>

      {Items.length === 0 && (
        <Box marginTop={2}>
          <Trans i18nKey={'Dealer.NoResults'} />
        </Box>
      )}
    </Box>
  );
};
