import { ClassifiedItem } from '@/shared/lib-api';
import { Box, Button, Dialog, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogClose } from '../dialog/dialog-close';

interface CallDealerFormModalProps {
  'classified-item'?: ClassifiedItem;
  phone: string;
  open: boolean;
  close: React.MouseEventHandler<HTMLImageElement>;
}

export const CallDealerFormModal = (props: CallDealerFormModalProps) => {
  const item = props['classified-item'];
  const { t } = useTranslation();

  const callDealer = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      document.location.href = `tel:${props.phone}`;
    },
    [props.phone],
  );

  const phoneDisplay = useMemo(() => {
    const part1 = props.phone.substring(0, 2);
    const part2 = props.phone.substring(2, 4);
    const part3 = props.phone.substring(4, 6);
    const part4 = props.phone.substring(6, 8);

    return `${part1} ${part2} ${part3} ${part4}`;
  }, [props.phone]);

  if (!item) {
    return <></>;
  }

  if (item.disableWriteTo) {
    return <></>;
  }

  return (
    <Dialog open={props.open} onClose={props.close}>
      <Box padding={3}>
        <Box
          display={'flex'}
          width={'100%'}
          flexDirection={'row'}
          justifyContent={'space-between'}>
          <Typography marginRight={3} paddingBottom={1} variant={'h6'}>
            {t('Vip.CallDealer.Title')}
          </Typography>
          {props.close && <DialogClose onClose={props.close} />}
        </Box>
        <Typography variant="body1" fontWeight={'bold'}>
          {item.owner.name}
        </Typography>
        <Typography variant="body1">{item.owner.address}</Typography>
        <Typography marginBottom={2} variant="body1">
          {item.owner.zipCode} {item.owner.city}
        </Typography>
        <Button
          data-id={item.id}
          onClick={callDealer}
          fullWidth
          color="secondary">
          {t('Vip.CallDealer.ButtonText')} {phoneDisplay}
        </Button>
      </Box>
    </Dialog>
  );
};
