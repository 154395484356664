import {
  Box,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultInputWrapper } from '../default-input-wrapper';
import { DropDownArrow } from '../../input-icons/dropdownIcon';

export interface SimpleInputSelectItem {
  label: string;
  value: string;
}

export interface SimpleInputSelectProps {
  selectedValue?: SimpleInputSelectItem;
  values: SimpleInputSelectItem[];
  name?: string;
  placeholder?: string;
  onChanged?: (value: string) => void;
}

function SimpleInputSelect(props: SimpleInputSelectProps) {
  const { t } = useTranslation();
  const selectItems = React.useMemo(() => {
    return props?.values?.map((opt, index) => {
      return (
        <MenuItem key={index} value={opt.value}>
          {t(opt.label)}
        </MenuItem>
      );
    });
  }, [props.values, t]);

  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onValueChanged = React.useCallback(
    (event: SelectChangeEvent<string>) => {
      if (props.onChanged) {
        var item = event.target.value;
        props.onChanged(item);
      }
    },
    [props],
  );

  const iconComponent = useMemo(() => {
    return (
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <IconButton
          style={{
            padding: '2px',
            right: 8,
            position: 'absolute',
            transform: open ? 'scaleY(-1)' : 'none',
          }}
          onClick={handleOpen}>
          {DropDownArrow}
        </IconButton>
      </Box>
    );
  }, [handleOpen, open]);

  return (
    <DefaultInputWrapper>
      <Select
        displayEmpty
        fullWidth
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        IconComponent={() => {
          return iconComponent;
        }}
        renderValue={selectedValue => {
          if (!selectedValue || selectedValue === '') {
            return props.values[0]?.label;
          }

          return props.values.find(p => p.value === selectedValue)?.label;
        }}
        value={props.selectedValue.value}
        onChange={onValueChanged}>
        {selectItems}
      </Select>
    </DefaultInputWrapper>
  );
}

export default React.memo(SimpleInputSelect);
