import { TodaysOpeningHoursResponse } from '@/shared/lib-api';
import { useApi } from '@/shared/util/api';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Typography } from '@mui/material';
import dealerClocknIcon from '@/shared/icons/dealer-clock-icon.svg';
import { Colors } from '@/shared/util/colors';
import { useRequestHelper } from '@/shared/hooks/request-helper';
import { LoadingState } from '@/shared/util/general-helpers';
import Image from '@/web/views/components/image/image';
import { useVipContext } from '@/pages/[headline]/[[...id]]';

export const VipTodaysOpeningHours = () => {
  const { handleRequest } = useRequestHelper();
  const { dealerApi } = useApi();
  const { item } = useVipContext();
  const [isLoading, setIsLoading] = useState(false);

  const [openingHours, setOpeningHours] =
    useState<TodaysOpeningHoursResponse | null>();

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (item?.owner?.id) {
        setIsLoading(true);
        let request = dealerApi.apiDealerIdOpeningHoursGet({
          id: item.owner.id,
        });
        let [result, errorAndState] = await handleRequest(request);

        if (errorAndState.state === LoadingState.Success) {
          setOpeningHours(result);
        }
        setIsLoading(false);
      }
    })();
  }, [handleRequest, item?.owner?.id]);

  if (isLoading && !openingHours) {
    return <Skeleton />;
  }

  if (!openingHours) {
    return <></>;
  }

  return (
    <>
      <Box paddingTop={2} display={'flex'} flexDirection={'row'}>
        <Image
          alt="Åbningstid"
          width={20}
          height={22}
          src={dealerClocknIcon.src}
        />
        <Box paddingLeft={1}>
          {openingHours.openWithAppointment && (
            <>
              <Typography>
                <span>Åben efter aftale</span>{' '}
              </Typography>
            </>
          )}
          {openingHours.isOpen && !openingHours.openWithAppointment && (
            <>
              <Typography>
                <span style={{ color: '#2CA948' }}>
                  {t('Vip.OpeningHours.Open')}
                </span>{' '}
                (
                {t('Vip.OpeningHours.ClosesAt', {
                  closesAt: openingHours.closes,
                })}
                )
              </Typography>
            </>
          )}

          {!openingHours.isOpen && !openingHours.openWithAppointment && (
            <>
              <Typography>
                <span style={{ color: Colors.Error }}>
                  {t('Vip.OpeningHours.Closed')}
                </span>{' '}
                ({t('Vip.OpeningHours.Open')} {openingHours.opens}-
                {openingHours.closes})
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
