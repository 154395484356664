import { Box, Pagination, PaginationItem, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NextLink from 'next/link';
import { Link as MUILink } from '@mui/material';
import { Colors } from '@/shared/util/colors';

interface DealerDealerSearchPaginationProps {
  pageNumber: number;
  totalResults?: number;
  getPageUrl: (pageNumber: number) => string;
  onSelectPage: React.Dispatch<React.SetStateAction<number>>;
}

const paginationItemStyle: React.CSSProperties = {
  paddingTop: '2px',
  fontSize: 14,
  margin: '0 4px',
};

const paginationItemSelectedBorderStyle = `1px solid ${Colors.DarkerBlue}`;
const paginationItemNextPrevBackgroundStyle = Colors.DarkerBlue;
const paginationItemNextPrevColorStyle = Colors.White;

export const DealerSearchPagination = (
  props: DealerDealerSearchPaginationProps,
) => {
  const { t } = useTranslation();
  const [clientSidePaging, setClientSidePaging] = useState(false);
  const showPaging: boolean =
    props.totalResults !== null && props.totalResults > 9;

  useEffect(() => {
    setClientSidePaging(true);
  }, []);

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      props.onSelectPage(value);
    },
    [props],
  );
  return (
    <Box marginTop={2}>
      {showPaging && (
        <Box
          display={'flex'}
          height={40}
          alignItems={'center'}
          justifyContent={'center'}>
          <Pagination
            count={Math.ceil(props.totalResults / 9)}
            page={props.pageNumber}
            onChange={handlePageChange}
            renderItem={item =>
              clientSidePaging ? (
                <PaginationItem
                  style={{
                    ...paginationItemStyle,
                    border: item.selected && paginationItemSelectedBorderStyle,
                    backgroundColor:
                      (item.type === 'previous' || item.type === 'next') &&
                      paginationItemNextPrevBackgroundStyle,
                    color:
                      (item.type === 'previous' || item.type === 'next') &&
                      paginationItemNextPrevColorStyle,
                  }}
                  sx={{ fontSize: '14px' }}
                  {...item}
                />
              ) : (
                <NextLink href={props.getPageUrl(item.page)}>
                  <span>{item.page}</span>
                </NextLink>
              )
            }
          />
        </Box>
      )}
    </Box>
  );
};
