/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DefaultPropertiesModel,
    DefaultPropertiesModelFromJSON,
    DefaultPropertiesModelFromJSONTyped,
    DefaultPropertiesModelToJSON,
} from './DefaultPropertiesModel';
import {
    IApiContentModel,
    IApiContentModelFromJSON,
    IApiContentModelFromJSONTyped,
    IApiContentModelToJSON,
} from './IApiContentModel';

/**
 * 
 * @export
 * @interface DefaultContentModel
 */
export interface DefaultContentModel extends IApiContentModel {
    /**
     * 
     * @type {DefaultPropertiesModel}
     * @memberof DefaultContentModel
     */
    properties?: DefaultPropertiesModel;
}

export function DefaultContentModelFromJSON(json: any): DefaultContentModel {
    return DefaultContentModelFromJSONTyped(json, false);
}

export function DefaultContentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DefaultContentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...IApiContentModelFromJSONTyped(json, ignoreDiscriminator),
        'properties': !exists(json, 'properties') ? undefined : DefaultPropertiesModelFromJSON(json['properties']),
    };
}

export function DefaultContentModelToJSON(value?: DefaultContentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...IApiContentModelToJSON(value),
        'properties': DefaultPropertiesModelToJSON(value.properties),
    };
}

