/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IApiContentRouteModel,
    IApiContentRouteModelFromJSON,
    IApiContentRouteModelFromJSONTyped,
    IApiContentRouteModelToJSON,
} from './IApiContentRouteModel';
import {
    IApiElementModel,
    IApiElementModelFromJSON,
    IApiElementModelFromJSONTyped,
    IApiElementModelToJSON,
} from './IApiElementModel';

import {
     AiseopageContentModelFromJSONTyped,
     LandingPageContentModelFromJSONTyped,
     AIseoContentModelFromJSONTyped,
     BilvidenContentModelFromJSONTyped,
     BilvidenKategoriContentModelFromJSONTyped,
     BilvidenPageContentModelFromJSONTyped,
     DefaultContentModelFromJSONTyped,
     NyhederContentModelFromJSONTyped,
     RootContentModelFromJSONTyped
} from './';

/**
 * 
 * @export
 * @interface IApiContentModel
 */
export interface IApiContentModel extends IApiElementModel {
    /**
     * 
     * @type {string}
     * @memberof IApiContentModel
     */
    readonly name?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof IApiContentModel
     */
    readonly createDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IApiContentModel
     */
    readonly updateDate?: Date;
    /**
     * 
     * @type {IApiContentRouteModel}
     * @memberof IApiContentModel
     */
    route?: IApiContentRouteModel;
    /**
     * 
     * @type {string}
     * @memberof IApiContentModel
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof IApiContentModel
     */
    readonly contentType?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof IApiContentModel
     */
    readonly properties?: { [key: string]: any; };
}

export function IApiContentModelFromJSON(json: any): IApiContentModel {
    return IApiContentModelFromJSONTyped(json, false);
}

export function IApiContentModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IApiContentModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['contentType'] === 'AISEOPage') {
            return AiseopageContentModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'LandingPage') {
            return LandingPageContentModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'aISEO') {
            return AIseoContentModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'bilviden') {
            return BilvidenContentModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'bilvidenKategori') {
            return BilvidenKategoriContentModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'bilvidenPage') {
            return BilvidenPageContentModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'default') {
            return DefaultContentModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'nyheder') {
            return NyhederContentModelFromJSONTyped(json, true);
        }
        if (json['contentType'] === 'root') {
            return RootContentModelFromJSONTyped(json, true);
        }
    }
    return {
        ...IApiElementModelFromJSONTyped(json, ignoreDiscriminator),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'createDate': !exists(json, 'createDate') ? undefined : (new Date(json['createDate'])),
        'updateDate': !exists(json, 'updateDate') ? undefined : (new Date(json['updateDate'])),
        'route': !exists(json, 'route') ? undefined : IApiContentRouteModelFromJSON(json['route']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'contentType': !exists(json, 'contentType') ? undefined : json['contentType'],
        'properties': !exists(json, 'properties') ? undefined : json['properties'],
    };
}

export function IApiContentModelToJSON(value?: IApiContentModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...IApiElementModelToJSON(value),
        'route': IApiContentRouteModelToJSON(value.route),
    };
}

