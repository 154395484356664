import React, { FC, useEffect, useState } from 'react';
import calIcon from './cal-icon.svg';
import { Box, Button, Link, Typography } from '@mui/material';
import { OpenExternalLink } from '@/web/util/helpers/open-external-link';
import Image from '@/web/views/components/image/image';
import { TrackEvent } from '@/shared/util/gtm';

const TjekLaanVipCard: FC = () => {
  const styles = {
    '&.MuiButton-root': {
      backgroundColor: '#1273cd',
      color: '#fff',
      fontWeight: 700,
    },
  };

  const handleOnClick = () => {
    TrackEvent('tjeklaan_link_klik', { klik_element: 'boks' });
    OpenExternalLink(
      'https://tjek-laan.dk/billaan-bilhandel?utm_source=Bilhandel&utm_medium=Oekonomi&dasite=TjeklaanBilhandel&assite=95666',
    );
  };

  return (
    <Box
      bgcolor={'#fff'}
      textAlign={'center'}
      borderRadius={'10px'}
      padding={'28px'}
      height={'100%'}>
      <Box>
        <Image alt="Finansiering" src={calIcon.src} height={42} width={40} />
      </Box>

      <Typography fontWeight={700} fontSize={18}>
        Finansiering
      </Typography>
      <Typography fontWeight={700} fontSize={15} marginTop={'10px'}>
        med eller uden udbetaling
      </Typography>

      <Box marginTop={'19px'}>
        <Button sx={styles} onClick={handleOnClick}>
          Beregn din månedlige ydelse her
        </Button>
      </Box>

      <Typography
        color={'#747474'}
        marginTop={'31px'}
        fontWeight={400}
        fontSize={11}
        lineHeight={'15px'}>
        Spar tid og sammenlig lån fra flere banker samtidig.
        <br />
        <Link onClick={handleOnClick} sx={{ cursor: 'pointer' }}>
          TjekLaan.dk
        </Link>{' '}
        Danmarks største sammenligningsside for forbrugslån
      </Typography>
    </Box>
  );
};

export default TjekLaanVipCard;
