import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { cursorPointer } from '@/shared/util/helpers/general-helper';

export const EditIcon = (props: { loading: boolean; onEdit: () => void }) => {
  const { t } = useTranslation();
  const style: React.CSSProperties = useMemo(() => {
    return {
      cursor: props.loading ? 'auto' : 'pointer',
    };
  }, [props.loading]);
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      onClick={props.onEdit}
      flexWrap={'nowrap'}
      className={'list-card-classified-edit'}>
      <ModeEditOutlineOutlinedIcon
        fontSize="small"
        color={'secondary'}
        sx={cursorPointer}
      />
      <Typography
        paddingLeft={0.3}
        variant={props.loading ? 'body1' : 'clickAble'}>
        <Trans i18nKey={'Labels.Edit'} />
      </Typography>
    </Grid>
  );
};
