import { useScreenSizeContext } from '@/pages/_app';
import { useAuth } from '@/shared/hooks/auth-hook';
import { useBasicCar } from '@/shared/hooks/basic-car-hook';
import { ClassifiedStatus, UserProfileType } from '@/shared/lib-api';
import { Colors } from '@/shared/util/colors';
import {
  Box,
  Button,
  ButtonProps,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ClassifiedFavorite } from '../default-components/classified/classified-favorite';
import { WriteDealerFormModal } from '../default-components/write-dealer-form-modal/write-dealer-form-modal';
import { LoadingState } from '@/shared/util/general-helpers';
import { useVipContext } from '@/pages/[headline]/[[...id]]';
import { theme } from '@/web/util/theme';

const BarContainer = (props: ButtonProps) => (
  <Box
    sx={{
      width: '100%',
      position: 'fixed',
      bottom: 0,
      zIndex: 1001,
      backgroundColor: Colors.White,
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    }}>
    {props.children}
  </Box>
);

const Headline = props => (
  <Typography
    fontSize="20px"
    fontWeight={400}
    overflow={'hidden'}
    textOverflow={'ellipsis'}
    whiteSpace={'nowrap'}
    sx={{ maxWidth: { xs: '250px', lg: '450px' } }}>
    {props.children}
  </Typography>
);
const Price = props => (
  <Typography
    sx={{
      display: {
        sm: 'none',
        md: 'flex',
      },
      whiteSpace: 'nowrap',
      minWidth: 'auto',
    }}
    fontSize="20px"
    fontWeight={700}>
    {props.children}
  </Typography>
);
const CallButton = (props: ButtonProps) => (
  <Button
    {...props}
    href={props.href}
    sx={{
      fontSize: '15px',
      fontWeight: 700,
      height: '44px',
      whiteSpace: 'nowrap',
    }}
    color="yellow">
    {props.children}
  </Button>
);
const WriteButton = (props: ButtonProps) => (
  <Button
    className="dealer-write"
    sx={{
      fontSize: '15px',
      fontWeight: 700,
      height: '44px',
      whiteSpace: 'nowrap',
    }}
    onClick={props.onClick}
    {...props}>
    {props.children}
  </Button>
);

const TestDriveButton = (props: ButtonProps) => (
  <Button
    className="dealer-testdrive"
    sx={{
      fontSize: '15px',
      fontWeight: 700,
      height: '44px',
      whiteSpace: 'nowrap',
    }}
    onClick={props.onClick}
    {...props}>
    {props.children}
  </Button>
);

export const VipStickyContactBar = () => {
  const { item } = useVipContext();
  const { t } = useTranslation();
  const prevScrollY = useRef(0);
  const { isLoggedIn } = useAuth();
  const { isCurrentlyLiked, addLike, loading } = useBasicCar({
    item: item,
  });
  const [showBar, setShowBar] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [callOptionInitialChecked, setCallOptionInitialChecked] =
    useState<boolean>(false);
  const [testDriveOptionInitialChecked, setTestDriveOptionInitialChecked] =
    useState<boolean>(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleCallClick = () => {
    if (isLoggedIn && !isCurrentlyLiked && loading !== LoadingState.Loading) {
      addLike();
    }
  };

  const handleWriteClick = useCallback(() => {
    setCallOptionInitialChecked(false);
    setTestDriveOptionInitialChecked(false);
    setOpenModal(prevState => !prevState);
  }, []);

  const handleCallMeClick = useCallback(() => {
    setCallOptionInitialChecked(true);
    setTestDriveOptionInitialChecked(false);
    setOpenModal(prevState => !prevState);
  }, []);

  const handleTestDriveClick = () => {
    setCallOptionInitialChecked(false);
    setTestDriveOptionInitialChecked(true);
    setOpenModal(prevState => !prevState);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const showNow = currentScrollY > 600;
      if (showNow !== showBar) {
        setShowBar(showNow);
      }
      prevScrollY.current = currentScrollY;
    };
    document.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  });

  const phoneDisplay = useMemo(() => {
    const part1 = item.owner.phone?.substring(0, 2);
    const part2 = item.owner.phone?.substring(2, 4);
    const part3 = item.owner.phone?.substring(4, 6);
    const part4 = item.owner.phone?.substring(6, 8);

    return (
      <span style={{ marginLeft: '2px' }}>
        {`${part1} ${part2} ${part3} ${part4}`}
      </span>
    );
  }, [item.owner.phone]);

  if (
    !item ||
    item.systemData.isSold ||
    item.systemData.status === ClassifiedStatus.Inactive ||
    !showBar ||
    !item.owner.writeEnabled
  ) {
    return null;
  }

  const showCallMe =
    item.owner.profileType == UserProfileType.Company &&
    item.owner.isOpenNow === false;

  return (
    <>
      <BarContainer>
        <Container>
          <Box
            display={'flex'}
            width={1}
            justifyContent={'space-between'}
            alignItems={'center'}
            my={2}
            gap={1}>
            <Box
              sx={{ display: { xs: 'none', md: 'flex' } }}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={1}>
              <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}>
                <Headline>{item.headLine}</Headline>
              </Box>
              <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                <Price>{item.priceFormatted}</Price>
              </Box>
            </Box>
            <Box
              display={'flex'}
              sx={{ width: { xs: '100%', md: 'initial' } }}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={2}>
              <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                <ClassifiedFavorite item={item} />
              </Box>
              {item.owner.testDriveAsPrimaryCTA && (
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                  <TestDriveButton
                    color="secondary"
                    onClick={handleTestDriveClick}>
                    Book prøvetur
                  </TestDriveButton>
                </Box>
              )}
              <Box>
                {!item.disableWriteTo && (
                  <WriteButton
                    onClick={handleWriteClick}
                    color={
                      item.owner.testDriveAsPrimaryCTA && !isMobile
                        ? 'tertiary'
                        : 'secondary'
                    }>
                    {t('Labels.WriteToSeller')}
                  </WriteButton>
                )}
              </Box>
              <Box>
                {showCallMe && (
                  <CallButton
                    className="dealer-callme"
                    onClick={handleCallMeClick}>
                    Bliv ringet op
                  </CallButton>
                )}
                {!showCallMe && item.owner.phone && (
                  <CallButton
                    className="dealer-call"
                    onClick={handleCallClick}
                    href={`tel:${item.owner.phone}`}>
                    {t('Vip.Call') + ' '}
                    {phoneDisplay}
                  </CallButton>
                )}
              </Box>
            </Box>
          </Box>
        </Container>
      </BarContainer>
      <WriteDealerFormModal
        close={handleWriteClick}
        open={openModal}
        classified-item={item}
        callOptionInitialChecked={callOptionInitialChecked}
        testDriveOptionInitialChecked={testDriveOptionInitialChecked}
      />
    </>
  );
};
