/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiBlockItemModel,
    ApiBlockItemModelFromJSON,
    ApiBlockItemModelFromJSONTyped,
    ApiBlockItemModelToJSON,
} from './ApiBlockItemModel';

/**
 * 
 * @export
 * @interface RichTextModel
 */
export interface RichTextModel {
    /**
     * 
     * @type {string}
     * @memberof RichTextModel
     */
    markup?: string;
    /**
     * 
     * @type {Array<ApiBlockItemModel>}
     * @memberof RichTextModel
     */
    blocks?: Array<ApiBlockItemModel>;
}

export function RichTextModelFromJSON(json: any): RichTextModel {
    return RichTextModelFromJSONTyped(json, false);
}

export function RichTextModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RichTextModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'markup': !exists(json, 'markup') ? undefined : json['markup'],
        'blocks': !exists(json, 'blocks') ? undefined : ((json['blocks'] as Array<any>).map(ApiBlockItemModelFromJSON)),
    };
}

export function RichTextModelToJSON(value?: RichTextModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'markup': value.markup,
        'blocks': value.blocks === undefined ? undefined : ((value.blocks as Array<any>).map(ApiBlockItemModelToJSON)),
    };
}

