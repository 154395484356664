/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuthorPropertiesModel,
    AuthorPropertiesModelFromJSON,
    AuthorPropertiesModelFromJSONTyped,
    AuthorPropertiesModelToJSON,
} from './AuthorPropertiesModel';
import {
    IApiElementModel,
    IApiElementModelFromJSON,
    IApiElementModelFromJSONTyped,
    IApiElementModelToJSON,
} from './IApiElementModel';

/**
 * 
 * @export
 * @interface AuthorElementModel
 */
export interface AuthorElementModel extends IApiElementModel {
    /**
     * 
     * @type {AuthorPropertiesModel}
     * @memberof AuthorElementModel
     */
    properties?: AuthorPropertiesModel;
}

export function AuthorElementModelFromJSON(json: any): AuthorElementModel {
    return AuthorElementModelFromJSONTyped(json, false);
}

export function AuthorElementModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorElementModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...IApiElementModelFromJSONTyped(json, ignoreDiscriminator),
        'properties': !exists(json, 'properties') ? undefined : AuthorPropertiesModelFromJSON(json['properties']),
    };
}

export function AuthorElementModelToJSON(value?: AuthorElementModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...IApiElementModelToJSON(value),
        'properties': AuthorPropertiesModelToJSON(value.properties),
    };
}

