/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IApiMediaWithCropsResponseModel,
    IApiMediaWithCropsResponseModelFromJSON,
    IApiMediaWithCropsResponseModelToJSON,
    PagedIApiMediaWithCropsResponseModel,
    PagedIApiMediaWithCropsResponseModelFromJSON,
    PagedIApiMediaWithCropsResponseModelToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface GetMediaRequest {
    fetch?: string;
    filter?: Array<string>;
    sort?: Array<string>;
    skip?: number;
    take?: number;
    expand?: string;
    apiKey?: string;
}

export interface GetMedia20Request {
    fetch?: string;
    filter?: Array<string>;
    sort?: Array<string>;
    skip?: number;
    take?: number;
    expand?: string;
    fields?: string;
    apiKey?: string;
}

export interface GetMediaItemRequest {
    id?: Set<string>;
    expand?: string;
    apiKey?: string;
}

export interface GetMediaItemByIdRequest {
    id: string;
    expand?: string;
    apiKey?: string;
}

export interface GetMediaItemById20Request {
    id: string;
    expand?: string;
    fields?: string;
    apiKey?: string;
}

export interface GetMediaItemByPathRequest {
    path: string;
    expand?: string;
    apiKey?: string;
}

export interface GetMediaItemByPath20Request {
    path: string;
    expand?: string;
    fields?: string;
    apiKey?: string;
}

export interface GetMediaItems20Request {
    id?: Set<string>;
    expand?: string;
    fields?: string;
    apiKey?: string;
}

/**
 * 
 */
export class MediaApi extends runtime.BaseAPI {

    /**
     */
    async getMediaRaw(requestParameters: GetMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PagedIApiMediaWithCropsResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.fetch !== undefined) {
            queryParameters['fetch'] = requestParameters.fetch;
        }

        if (requestParameters.filter) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.skip !== undefined) {
            queryParameters['skip'] = requestParameters.skip;
        }

        if (requestParameters.take !== undefined) {
            queryParameters['take'] = requestParameters.take;
        }

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.apiKey !== undefined && requestParameters.apiKey !== null) {
            headerParameters['Api-Key'] = String(requestParameters.apiKey);
        }

        const response = await this.request({
            path: `/umbraco/delivery/api/v1/media`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedIApiMediaWithCropsResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async getMedia(requestParameters: GetMediaRequest = {}, initOverrides?: RequestInit): Promise<PagedIApiMediaWithCropsResponseModel> {
        const response = await this.getMediaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMedia20Raw(requestParameters: GetMedia20Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PagedIApiMediaWithCropsResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.fetch !== undefined) {
            queryParameters['fetch'] = requestParameters.fetch;
        }

        if (requestParameters.filter) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.skip !== undefined) {
            queryParameters['skip'] = requestParameters.skip;
        }

        if (requestParameters.take !== undefined) {
            queryParameters['take'] = requestParameters.take;
        }

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        if (requestParameters.fields !== undefined) {
            queryParameters['fields'] = requestParameters.fields;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.apiKey !== undefined && requestParameters.apiKey !== null) {
            headerParameters['Api-Key'] = String(requestParameters.apiKey);
        }

        const response = await this.request({
            path: `/umbraco/delivery/api/v2/media`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedIApiMediaWithCropsResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async getMedia20(requestParameters: GetMedia20Request = {}, initOverrides?: RequestInit): Promise<PagedIApiMediaWithCropsResponseModel> {
        const response = await this.getMedia20Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMediaItemRaw(requestParameters: GetMediaItemRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<IApiMediaWithCropsResponseModel>>> {
        const queryParameters: any = {};

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.apiKey !== undefined && requestParameters.apiKey !== null) {
            headerParameters['Api-Key'] = String(requestParameters.apiKey);
        }

        const response = await this.request({
            path: `/umbraco/delivery/api/v1/media/item`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IApiMediaWithCropsResponseModelFromJSON));
    }

    /**
     */
    async getMediaItem(requestParameters: GetMediaItemRequest = {}, initOverrides?: RequestInit): Promise<Array<IApiMediaWithCropsResponseModel>> {
        const response = await this.getMediaItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMediaItemByIdRaw(requestParameters: GetMediaItemByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IApiMediaWithCropsResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMediaItemById.');
        }

        const queryParameters: any = {};

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.apiKey !== undefined && requestParameters.apiKey !== null) {
            headerParameters['Api-Key'] = String(requestParameters.apiKey);
        }

        const response = await this.request({
            path: `/umbraco/delivery/api/v1/media/item/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IApiMediaWithCropsResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async getMediaItemById(requestParameters: GetMediaItemByIdRequest, initOverrides?: RequestInit): Promise<IApiMediaWithCropsResponseModel> {
        const response = await this.getMediaItemByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMediaItemById20Raw(requestParameters: GetMediaItemById20Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IApiMediaWithCropsResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMediaItemById20.');
        }

        const queryParameters: any = {};

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        if (requestParameters.fields !== undefined) {
            queryParameters['fields'] = requestParameters.fields;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.apiKey !== undefined && requestParameters.apiKey !== null) {
            headerParameters['Api-Key'] = String(requestParameters.apiKey);
        }

        const response = await this.request({
            path: `/umbraco/delivery/api/v2/media/item/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IApiMediaWithCropsResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async getMediaItemById20(requestParameters: GetMediaItemById20Request, initOverrides?: RequestInit): Promise<IApiMediaWithCropsResponseModel> {
        const response = await this.getMediaItemById20Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMediaItemByPathRaw(requestParameters: GetMediaItemByPathRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IApiMediaWithCropsResponseModel>> {
        if (requestParameters.path === null || requestParameters.path === undefined) {
            throw new runtime.RequiredError('path','Required parameter requestParameters.path was null or undefined when calling getMediaItemByPath.');
        }

        const queryParameters: any = {};

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.apiKey !== undefined && requestParameters.apiKey !== null) {
            headerParameters['Api-Key'] = String(requestParameters.apiKey);
        }

        const response = await this.request({
            path: `/umbraco/delivery/api/v1/media/item/{path}`.replace(`{${"path"}}`, encodeURIComponent(String(requestParameters.path))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IApiMediaWithCropsResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async getMediaItemByPath(requestParameters: GetMediaItemByPathRequest, initOverrides?: RequestInit): Promise<IApiMediaWithCropsResponseModel> {
        const response = await this.getMediaItemByPathRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMediaItemByPath20Raw(requestParameters: GetMediaItemByPath20Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IApiMediaWithCropsResponseModel>> {
        if (requestParameters.path === null || requestParameters.path === undefined) {
            throw new runtime.RequiredError('path','Required parameter requestParameters.path was null or undefined when calling getMediaItemByPath20.');
        }

        const queryParameters: any = {};

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        if (requestParameters.fields !== undefined) {
            queryParameters['fields'] = requestParameters.fields;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.apiKey !== undefined && requestParameters.apiKey !== null) {
            headerParameters['Api-Key'] = String(requestParameters.apiKey);
        }

        const response = await this.request({
            path: `/umbraco/delivery/api/v2/media/item/{path}`.replace(`{${"path"}}`, encodeURIComponent(String(requestParameters.path))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IApiMediaWithCropsResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async getMediaItemByPath20(requestParameters: GetMediaItemByPath20Request, initOverrides?: RequestInit): Promise<IApiMediaWithCropsResponseModel> {
        const response = await this.getMediaItemByPath20Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMediaItems20Raw(requestParameters: GetMediaItems20Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<IApiMediaWithCropsResponseModel>>> {
        const queryParameters: any = {};

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        if (requestParameters.fields !== undefined) {
            queryParameters['fields'] = requestParameters.fields;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.apiKey !== undefined && requestParameters.apiKey !== null) {
            headerParameters['Api-Key'] = String(requestParameters.apiKey);
        }

        const response = await this.request({
            path: `/umbraco/delivery/api/v2/media/items`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IApiMediaWithCropsResponseModelFromJSON));
    }

    /**
     */
    async getMediaItems20(requestParameters: GetMediaItems20Request = {}, initOverrides?: RequestInit): Promise<Array<IApiMediaWithCropsResponseModel>> {
        const response = await this.getMediaItems20Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
