/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiBlockItemModel,
    ApiBlockItemModelFromJSON,
    ApiBlockItemModelFromJSONTyped,
    ApiBlockItemModelToJSON,
} from './ApiBlockItemModel';

/**
 * 
 * @export
 * @interface ApiBlockListModel
 */
export interface ApiBlockListModel {
    /**
     * 
     * @type {Array<ApiBlockItemModel>}
     * @memberof ApiBlockListModel
     */
    items?: Array<ApiBlockItemModel>;
}

export function ApiBlockListModelFromJSON(json: any): ApiBlockListModel {
    return ApiBlockListModelFromJSONTyped(json, false);
}

export function ApiBlockListModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiBlockListModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(ApiBlockItemModelFromJSON)),
    };
}

export function ApiBlockListModelToJSON(value?: ApiBlockListModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(ApiBlockItemModelToJSON)),
    };
}

