/* tslint:disable */
/* eslint-disable */
/**
 * Umbraco Delivery API
 * You can find out more about the Umbraco Delivery API in [the documentation](https://docs.umbraco.com/umbraco-cms/reference/content-delivery-api).
 *
 * The version of the OpenAPI document: Latest
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FaqSectionPropertiesModel,
    FaqSectionPropertiesModelFromJSON,
    FaqSectionPropertiesModelFromJSONTyped,
    FaqSectionPropertiesModelToJSON,
} from './FaqSectionPropertiesModel';
import {
    IApiElementModel,
    IApiElementModelFromJSON,
    IApiElementModelFromJSONTyped,
    IApiElementModelToJSON,
} from './IApiElementModel';

/**
 * 
 * @export
 * @interface FaqSectionElementModel
 */
export interface FaqSectionElementModel extends IApiElementModel {
    /**
     * 
     * @type {FaqSectionPropertiesModel}
     * @memberof FaqSectionElementModel
     */
    properties?: FaqSectionPropertiesModel;
}

export function FaqSectionElementModelFromJSON(json: any): FaqSectionElementModel {
    return FaqSectionElementModelFromJSONTyped(json, false);
}

export function FaqSectionElementModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FaqSectionElementModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...IApiElementModelFromJSONTyped(json, ignoreDiscriminator),
        'properties': !exists(json, 'properties') ? undefined : FaqSectionPropertiesModelFromJSON(json['properties']),
    };
}

export function FaqSectionElementModelToJSON(value?: FaqSectionElementModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...IApiElementModelToJSON(value),
        'properties': FaqSectionPropertiesModelToJSON(value.properties),
    };
}

