import React, { FC, useEffect, useState } from 'react';
import calIcon from './cal-icon.svg';
import { Box, Button, Typography } from '@mui/material';
import { OpenExternalLink } from '@/web/util/helpers/open-external-link';
import {
  LoadingState,
  thousandNumberSeperator,
} from '@/shared/util/general-helpers';
import { useRequestHelper } from '@/shared/hooks/request-helper';
import { useApi } from '@/shared/util/api';
import { Financing } from '@/shared/lib-api';
import Image from '@/web/views/components/image/image';

type PropsFromParent = {
  classifiedId: number;
  amount: number;
  onIsAvailable: (boolean) => void;
};

const VipCard: FC<PropsFromParent> = ({
  classifiedId,
  amount,
  onIsAvailable: onAvailable,
}) => {
  const { classifiedApi } = useApi();
  const { handleRequest } = useRequestHelper();
  const [financing, setFinancing] = useState<Financing>();

  const styles = {
    '&.MuiButton-root': {
      backgroundColor: '#1273cd',
      color: '#fff',
      fontWeight: 700,
    },
  };

  const handleOnClick = () => {
    const linkWithUtm = `${financing.url}&utm_medium=referral&utm_campaign=resurs%20bank%20l%C3%A5nebevis&utm_content=bilhandel_vip`;
    OpenExternalLink(linkWithUtm);
  };

  useEffect(() => {
    (async () => {
      if (amount && classifiedId) {
        const request = classifiedApi.financing({
          amount,
          classifiedId,
        });
        let [result, errorAndState] = await handleRequest(request);
        if (errorAndState.state === LoadingState.Success) {
          setFinancing(result);
          onAvailable(true);
          return;
        }
      }
      onAvailable(false);
    })();
  }, [amount, classifiedId, handleRequest, onAvailable]);

  return (
    <>
      {financing && (
        <>
          <Box
            id={`${financing.financingSource.toLowerCase()}-vip-card`}
            bgcolor={'#fff'}
            textAlign={'center'}
            borderRadius={'10px'}
            padding={'28px'}
            height={'100%'}>
            <Box>
              <Image
                alt="Finansiering"
                src={calIcon.src}
                height={42}
                width={40}
              />
            </Box>

            <Typography fontWeight={700} fontSize={18}>
              Finansiering
            </Typography>
            <Typography fontWeight={700} fontSize={15} marginTop={'10px'}>
              fra{' '}
              <span style={{ fontSize: '22px' }}>
                {thousandNumberSeperator(financing.pricePerMonth)}
              </span>{' '}
              kr. / md.
            </Typography>

            <Box marginTop={'19px'}>
              <Button sx={styles} onClick={handleOnClick}>
                Beregn lån
              </Button>
            </Box>

            <Typography
              color={'#747474'}
              marginTop={'31px'}
              fontWeight={400}
              fontSize={11}
              lineHeight={'15px'}>
              {financing.legalText}
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};

export default VipCard;
